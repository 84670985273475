import { NavLink, useNavigate } from "react-router-dom";
import "./Producthelix.css";
import axios from 'axios';
import Slider from 'react-slick';
import React, { useState, useEffect, useRef } from "react";
import FadeInSection from "../FadeInup/FadeInup";
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import moment from "moment";
import { Helmet } from 'react-helmet';


const HelixHead = () => (

    <Helmet>
        <title>Buy Colorbot Helix Noiseless Ceiling Fan Online in India</title>
        <meta name="description" content="Shop Colorbot Helix noiseless ceiling fan online at the best price with optimum airflow and silent operation, providing superior performance while saving energy." />
        <link rel="canonical" href="https://colorbot.in/colorbot-helix" />

        <meta property="og:title" content="Buy Colorbot Helix Noiseless Ceiling Fan Online in India" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/colorbot-helix" />
        <meta property="og:description" content="Shop Colorbot Helix noiseless ceiling fan online at the best price with optimum airflow and silent operation, providing superior performance while saving energy." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://backend.colorbot.in/product/Helix/FHBL1200/Helix-product1.png" />
    </Helmet>
);


SwiperCore.use([Navigation, Pagination, Autoplay]);
function Producthelix(props) {
    const [price, setprice] = useState(null)
    const [discountedprice, setdiscountedprice] = useState(null)
    const [fixedprice, setfixedprice] = useState(null)
    const [fixeddiscountedprice, setfixeddiscountedprice] = useState(null)
    const navigate = useNavigate();
    const [product, setproduct] = useState([])
    const [product_Name, setProduct_Name] = useState("Helix")
    const [product_Description, setProduct_Description] = useState("The Ultimate fun for home, featuring 5-star BLDC motor, inverter-friendly technology, higher air delivery, stylish design and remote. Get longer product protection with a 2+2 years warranty.")
    const [itemName, setItemName] = useState("")

    const [inventory, setInventory] = useState(0)

    // LOADER CODE
    const [showLoader, setShowLoader] = useState(true)

    const getproduct = async () => {
        try {
            await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },

                body: JSON.stringify({ category: "Helix", productfor: "Normal" }),
            }).then((res) => res.json()).then((response) => {


                if (response.status === 200) {
                    setproduct(response.data)
                    setprice(response.data[2].ProductPrice)
                    setdiscountedprice(response.data[2].ProductSelling)
                    setfixedprice(response.data[2].ProductPrice)
                    setfixeddiscountedprice(response.data[2].ProductSelling)

                    setProduct_Name(response.data[2].ProductName)
                    setProduct_Description(response.data[2].ProductDescription)
                    setItemName(response.data[2].ItemNo)
                    setInventory(response.data[2].ProductInventory)

                    var set = new Set();
                    var set1 = new Set();
                    for (let i = 0; i < response.data.length; i++) {


                        set.add(response.data[i].ProductSize)




                    }
                    var data = response.data.filter((item) => {
                        return item.ProductSize === response.data[2].ProductSize
                    })
                    for (let i = 0; i < data.length; i++) {
                        set1.add(data[i].ProductColorCode)
                    }
                    var arr = Array.from(set)
                    var arr1 = Array.from(set1)

                    for (let i = 0; i < arr1.length; i++) {
                        if (arr1[i] === "#000000") {
                            colorlist[0].available = true
                        }
                        if (arr1[i] === "#6d4d33") {
                            colorlist[1].available = true
                        }
                        if (arr1[i] === "#535353") {
                            colorlist[2].available = true
                        }
                        if (arr1[i] === "#d8d8d8") {
                            colorlist[3].available = true
                        }
                        if (arr1[i] === "#6d6d6d") {
                            colorlist[4].available = true
                        }
                        if (arr1[i] === "#F0F0F0") {
                            colorlist[5].available = true
                        }
                        if (arr1[i] === "#A47141") {
                            colorlist[6].available = true
                        }
                        if (arr1[i] === "#4A2413") {
                            colorlist[7].available = true
                        }
                        if (arr1[i] === "#F0F0F1") {
                            colorlist[8].available = true
                        }
                        if (arr1[i] === "#8E8E8E") {
                            colorlist[9].available = true
                        }
                    }

                    for (let i = 0; i < buttonlist.length; i++) {
                        buttonlist[i].available = false;  // Set all buttons to false initially
                    }

                    setcolorlist(colorlist)
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i] === "900mm") {
                            buttonlist[0].available = true
                        }
                        if (arr[i] === "1200mm") {
                            buttonlist[1].available = true
                        }
                        if (arr[i] === "1400mm") {
                            buttonlist[2].available = true
                        }
                    }
                    setbuttonlist(buttonlist)


                    setbuttonactive(response.data[2].ProductSize)
                    if (localStorage.getItem("activeType") === "Helix") {
                        setactivecolor(localStorage.getItem("activeColor"))
                        setTimeout(() => {
                            document.getElementById(`color-${localStorage.getItem("activeColor")}`).click()
                        }, 300)
                        setShowLoader(false)


                    } else {
                        setactivecolor(response.data[2].ProductColorCode)
                        var imagearr = []
                        imagearr.push(response.data[2].ProductImage)
                        imagearr.push(response.data[2].ProductImage1)
                        imagearr.push(response.data[2].ProductImage2)
                        imagearr.push(response.data[2].ProductImage3)
                        imagearr.push(response.data[2].ProductImage4)
                        imagearr.push(response.data[2].ProductImage5)
                        for (let i = 0; i < 6; i++) {
                            imagearr.push(imagearr[i])
                        }

                        setimage(imagearr)
                        setimageselected(response.data[2].ProductImage)
                        setShowLoader(false)
                    }



                } else {
                    setproduct([])
                    setShowLoader(false)


                }
            })
        } catch (error) {
            setShowLoader(false)
            console.log(error)
        }
    }

    const [buttonlist, setbuttonlist] = useState([
        {
            value: "900mm",
            available: false,

        },
        {
            value: "1200mm",
            available: false,

        }, {
            value: "1400mm",
            available: false,

        }
    ])
    const [imageselected, setimageselected] = useState("")
    const [image, setimage] = useState([])
    const [buttonactive, setbuttonactive] = useState("900mm")
    const [activecolor, setactivecolor] = useState("#000000")
    const [quantity, setquantity] = useState(1)
    const [colorlist, setcolorlist] = useState([
        {
            value: "Charcoal black",
            color: "#000000",
            secondary: "#000000",
            available: false
        }, {
            value: "Urban Oak",
            color: "#6d4d33",
            secondary: "#000000",
            available: false
        }, {
            value: "Midnight Grey",
            color: "#535353",
            secondary: "#000000",
            available: false
        }, {
            value: "Artic white",
            color: "#d8d8d8",
            secondary: "#d8d8d8",
            available: false
        }, {
            value: "Ash grey",
            color: "#6d6d6d",
            secondary: "#6d6d6d",
            available: false
        }, {
            value: "Noir white",
            color: "#F0F0F0",
            secondary: "#000000",
            available: false
        }, {
            value: "Caramel Brown",
            color: "#A47141",
            secondary: "#A47141",
            available: false
        }, {
            value: "Cocoa Brown",
            color: "#4A2413",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Blanca Brown",
            color: "#F0F0F1",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Luxe Grey",
            color: "#8E8E8E",
            secondary: "#F0F0F0",
            available: false
        }
    ])
    const [pincode, setPincode] = useState('');
    const [city, setCity] = useState('');

    const [state, setState] = useState('');
    const [error, setError] = useState('');


    const checkpincode = () => {
        setCity('');
        setState('');
        var value = pincode;

        if (value.length === 6) {
            setError('');

            axios
                .get(`https://api.postalpincode.in/pincode/${value}`)
                .then((res) => {
                    setState(res.data[0].PostOffice[0].State);
                    setCity(res.data[0].PostOffice[0].Block);


                })
                .then(() => {

                })
                .catch((err) => {

                    setError('Invalid PIN Code');
                });
        }

        if (value.length !== 6) {
            setCity('');

            setState('');
            setError('Pincode must be of 6 digits');
        }

    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        arrow: true,
        autoplaySpeed: 2000,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1002,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    };




    useEffect(() => {
        getproduct()

        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })


    }, [])

    useEffect(() => {
        document.title = "Colorbot Helix"; // Set the title when component mounts
        return () => {
            document.title = "Colorbot Helix"; // Reset the title when component unmounts
        };
    }, []);

    const sectionRef = useRef(null);
    const [isVisibleModule, setIsVisibleModule] = useState(false);



    const videoRef = useRef(null);

    useEffect(() => {


        const handleScroll = () => {
            const rect = sectionRef?.current?.getBoundingClientRect();

            const isVisible = window.innerWidth < 768 ? (rect?.top + 250) < window.innerHeight && rect?.bottom >= 0 : rect?.top < window.innerHeight && rect?.bottom >= 0;


            if (window.innerWidth < 768) {
                if (isVisible) {
                    setIsVisibleModule(true);

                    if (videoRef?.current && !videoRef?.current.played.length) {

                        videoRef?.current.play()

                    }
                }
            } else {
                if (isVisible) {
                    setIsVisibleModule(true);

                    if (videoRef?.current && !videoRef?.current.played.length) {

                        videoRef?.current.play()

                    }
                }
                // if (isVisible) {
                //     setIsVisibleModule(true);


                //     const speed = 1000;
                //     const scroll = (window.innerWidth < 768 ? window.pageYOffset - 2500 : window.pageYOffset - 1000) / speed;

                //     if (videoRef?.current) {
                //         videoRef.current.currentTime = scroll;
                //     }
                // } else {
                //     setIsVisibleModule(false);
                // }
            }


        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [showLoader]);




    const [remote, setremote] = useState(true)
    const buyproduct = () => {
        var productprice = Number(quantity * parseInt(price));
        var productdiscountedprice = Number(quantity * parseInt(discountedprice));
        var productname = product_Name;
        var productimage = image[0]
        var productcolor = getActiveColorName();
        var productquantity = quantity;
        var productsweepsize = buttonactive;
        var productid = itemName
        var productremote = remote === true ? "1" : "0";
        var newItem = {
            "productid": productid,
            "productname": productname,
            "productimage": productimage,
            "productprice": productprice,
            "productdiscountedprice": productdiscountedprice,
            "fixedproductprice": Number(fixedprice),
            "fixedproductdiscountedprice": Number(fixeddiscountedprice),
            "productpath": window.location.pathname,
            "productcolor": productcolor,
            "inventory": inventory,
            "extendedwarranty": true,
            "modal": "Helix",
            "productquantity": productquantity,
            "productsweepsize": productsweepsize,
            "productremote": productremote
        }
        addToCart(newItem);
        window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
        if (props.LoggedIn) {

            navigate("/product_buy_now_delivery")
        } else {
            navigate("/product_buy_now_login")
        }

    }
    const addtocart = () => {

        var productprice = Number(quantity * parseInt(price));
        var productdiscountedprice = Number(quantity * parseInt(discountedprice));
        var productname = product_Name;
        var productimage = image[0]
        var productcolor = getActiveColorName();
        var productquantity = quantity;
        var productsweepsize = buttonactive;
        var productid = itemName
        var productremote = remote === true ? "1" : "0";
        var newItem = {
            "productid": productid,
            "productname": productname,
            "productimage": productimage,
            "productprice": productprice,
            "productdiscountedprice": productdiscountedprice,
            "fixedproductprice": Number(fixedprice),
            "fixedproductdiscountedprice": Number(fixeddiscountedprice),
            "productpath": window.location.pathname,
            "productcolor": productcolor,
            "inventory": inventory,
            "modal": "Helix",
            "extendedwarranty": true,
            "productquantity": productquantity,
            "productsweepsize": productsweepsize,
            "productremote": productremote
        }

        addToCart(newItem);
        window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
        props.opensidepanel()
    }
    const addToCart = (newItem) => {


        props.setcartdata((prevData) => {
            const existingItemIndex = prevData.findIndex(
                (item) => item.productid === newItem.productid
            );

            if (existingItemIndex !== -1) {
                const updatedData = prevData.map((item, index) => {
                    if (index === existingItemIndex) {
                        // Update the quantity of the existing item
                        const updatedQuantity = parseInt(item.productquantity) + parseInt(newItem.productquantity);
                        const updatedPrice = parseInt(item.productprice) + parseInt(newItem.productprice);
                        const updatedDiscountedPrice = parseInt(item.productdiscountedprice) + parseInt(newItem.productdiscountedprice);
                        return { ...item, productquantity: updatedQuantity, productprice: updatedPrice, productdiscountedprice: updatedDiscountedPrice };
                    }
                    return item;
                });

                localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            }

            const updatedData = [...prevData, newItem];
            localStorage.setItem('cartdata', JSON.stringify(updatedData));
            window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
            window.localStorage.setItem("addcart", true)
            return updatedData;
        });
    };

    const videoRef1 = useRef(null);
    const aeroRef = useRef(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [currentwidth, setcurrentwidth] = useState(window.innerWidth)

    // Function to check if the element is in the viewport
    const isElementInViewport = (el) => {

        const rect = el?.getBoundingClientRect();
        return (
            rect?.top >= 0 &&
            rect?.left >= 0 &&
            rect?.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect?.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    useEffect(() => {
        // Function to handle the visibility check and video play/pause
        setcurrentwidth(window.innerWidth)
        const checkAndPlayVideo = () => {

            if (isElementInViewport(aeroRef?.current)) {
                // Element is visible, play the video
                if (!isVideoPlaying) {
                    videoRef1?.current.play();
                    setIsVideoPlaying(true);
                }
            } else {
                // Element is not visible, pause the video
                if (isVideoPlaying) {
                    // videoRef.current.pause();
                    // setIsVideoPlaying(false);
                }
            }
        };

        // Add event listener for scrolling to check the visibility of the element
        window.addEventListener('scroll', checkAndPlayVideo);

        // Call the function once on component mount to check the initial visibility
        checkAndPlayVideo();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', checkAndPlayVideo);
        };
    }, [isVideoPlaying, showLoader]);
    const handleChangeQuantity = (newQuantity) => {
        if (newQuantity === "" || newQuantity === 0) {
            // toast.error("Please enter a valid Quantity"); // Display an error toast notification
            setquantity(newQuantity);

        } else {
            setquantity(newQuantity);


        }
    };

    const getActiveColorName = () => {
        const activeColorObject = colorlist.find(color => color.color === activecolor);
        return activeColorObject ? activeColorObject.value : "";
    };

    useEffect(() => {
        getActiveColorName()
    }, [activecolor])

    const getpairedproduct = async (colorcode) => {

        var data = product.filter((item) => {
            return item.ProductColorCode === colorcode && item.ProductSize === buttonactive
        })
        if (data.length > 0) {
            var set = new Set();

            for (let i = 0; i < data.length; i++) {

                if (product[i].ProductColorCode == colorcode) {

                    set.add(product[i].ProductSize)
                }




            }
            var arr = Array.from(set)

            // for (let i = 0; i < buttonlist.length; i++) {
            //     buttonlist[i].available = false;  // Set all buttons to false initially
            // }


            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === "900mm") {
                    buttonlist[0].available = true
                }
                if (arr[i] === "1200mm") {
                    buttonlist[1].available = true
                }
                if (arr[i] === "1400mm") {
                    buttonlist[2].available = true
                }
            }
            setbuttonlist(buttonlist)

            setbuttonactive(data[0].ProductSize)

            setprice(data[0].ProductPrice)
            setdiscountedprice(data[0].ProductSelling)
            setfixedprice(data[0].ProductPrice)
            setfixeddiscountedprice(data[0].ProductSelling)
            setProduct_Name(data[0].ProductName)
            setProduct_Description(data[0].ProductDescription)
            setItemName(data[0].ItemNo)
            setInventory(data[0].ProductInventory)
            var imagearr = []
            imagearr.push(data[0].ProductImage)
            imagearr.push(data[0].ProductImage1)
            imagearr.push(data[0].ProductImage2)
            imagearr.push(data[0].ProductImage3)
            imagearr.push(data[0].ProductImage4)
            imagearr.push(data[0].ProductImage5)
            for (let i = 0; i < 6; i++) {
                imagearr.push(imagearr[i])
            }

            setimage(imagearr)
            setimageselected(data[0].ProductImage)
            setquantity(1)

        }
    }

    const getsizepairedproduct = async (size) => {
        setbuttonactive(size);
        var data = product.filter((item) => {
            return item.ProductSize === size
        })


        if (data.length > 0) {

            var set = new Set();
            var set1 = new Set();

            for (let i = 0; i < product.length; i++) {

                if (product[i].ProductColorCode == activecolor) {

                    set.add(product[i].ProductSize)
                }




            }

            // setactivecolor(data[0].ProductColorCode)
            for (let i = 0; i < data.length; i++) {




                set1.add(data[i].ProductColorCode)


            }
            for (let i = 0; i < colorlist.length; i++) {
                colorlist[i].available = false;  // Set all buttons to false initially
            }


            var arr1 = Array.from(set1)


            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] === "#000000") {
                    colorlist[0].available = true
                }
                if (arr1[i] === "#6d4d33") {
                    colorlist[1].available = true
                }
                if (arr1[i] === "#535353") {
                    colorlist[2].available = true
                }
                if (arr1[i] === "#d8d8d8") {
                    colorlist[3].available = true
                }
                if (arr1[i] === "#6d6d6d") {
                    colorlist[4].available = true
                }
                if (arr1[i] === "#F0F0F0") {
                    colorlist[5].available = true
                }
                if (arr1[i] === "#A47141") {
                    colorlist[6].available = true
                }
                if (arr1[i] === "#4A2413") {
                    colorlist[7].available = true
                }
                if (arr1[i] === "#F0F0F1") {
                    colorlist[8].available = true
                }
                if (arr1[i] === "#8E8E8E") {
                    colorlist[9].available = true
                }
            }


            setcolorlist(colorlist)

            var arr = Array.from(set)



            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === "900mm") {
                    buttonlist[0].available = true
                }
                if (arr[i] === "1200mm") {
                    buttonlist[1].available = true
                }
                if (arr[i] === "1400mm") {
                    buttonlist[2].available = true
                }
            }
            setbuttonlist(buttonlist)
            for (let i = 0; i < data.length; i++) {
                if (arr1.includes(activecolor) && data[i].ProductColorCode === activecolor && data[i].ProductSize === size) {

                    setactivecolor(activecolor)
                    setprice(data[i].ProductPrice)
                    setdiscountedprice(data[i].ProductSelling)
                    setfixedprice(data[i].ProductPrice)
                    setfixeddiscountedprice(data[i].ProductSelling)
                    setProduct_Name(data[i].ProductName)
                    setProduct_Description(data[i].ProductDescription)
                    setItemName(data[i].ItemNo)
                    setInventory(data[i].ProductInventory)
                    var imagearr = []
                    imagearr.push(data[i].ProductImage)
                    imagearr.push(data[i].ProductImage1)
                    imagearr.push(data[i].ProductImage2)
                    imagearr.push(data[i].ProductImage3)
                    imagearr.push(data[i].ProductImage4)
                    imagearr.push(data[i].ProductImage5)
                    for (let i = 0; i < 6; i++) {
                        imagearr.push(imagearr[i])
                    }

                    setimage(imagearr)
                    setimageselected(data[i].ProductImage)
                    setquantity(1)


                } else {
                    setactivecolor(arr1[0])
                    setprice(data[0].ProductPrice)
                    setdiscountedprice(data[0].ProductSelling)
                    setfixedprice(data[0].ProductPrice)
                    setfixeddiscountedprice(data[0].ProductSelling)
                    setProduct_Name(data[0].ProductName)
                    setProduct_Description(data[0].ProductDescription)
                    setItemName(data[0].ItemNo)
                    setInventory(data[0].ProductInventory)
                    var imagearr = []
                    imagearr.push(data[0].ProductImage)
                    imagearr.push(data[0].ProductImage1)
                    imagearr.push(data[0].ProductImage2)
                    imagearr.push(data[0].ProductImage3)
                    imagearr.push(data[0].ProductImage4)
                    imagearr.push(data[0].ProductImage5)
                    for (let i = 0; i < 6; i++) {
                        imagearr.push(imagearr[i])
                    }

                    setimage(imagearr)
                    setimageselected(data[0].ProductImage)
                    setquantity(1)
                }
            }

        }
    }

    const ShareInfo = () => {

        if (navigator.share) {
            navigator.share({
                title: "Color Bot",
                text: "ColorBot Helix Fan",
                url: window.location.href
            }).then(() => {
                console.log('Share successful');
            }).catch((error) => {
                console.error('Error sharing:', error);
            });
        } else {
            // Fallback for browsers that do not support Web Share API
            console.log('Web Share API not supported.');
            // Implement your own custom share functionality here
        }
    };




    var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const convertdate = (date) => {
        var d = date.split("-")[0];
        var m = date.split("-")[1];

        var time = " " + d + "th" + " " + month[parseInt(m) - 1];

        return time;
    }
    const convertdatewithoutMonth = (date) => {
        var d = date.split("-")[0];
        var m = date.split("-")[1];

        var time = d + "th" + " ";

        return time;
    }




    return (

        showLoader ? <>
            <section className="mt-5 pt-4 loader-align">
                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/loader-gif.gif"></img>
                <p>LOADING COLORBOT...</p>
            </section>
        </>
            :

            <section className="mt-5 pt-4">
                <HelixHead />
                <div className="shopnow">
                    FREE SHIPPING on all prepaid orders, <span><NavLink to="/bldc-ceiling-fan">SHOP NOW</NavLink></span>
                </div>
                <div className="container pt-4">
                    <p className="breadcrumb"> <a style={{ color: "#000000" }} href="/" > Home </a> &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <a style={{ color: "#000000" }} href="/bldc-ceiling-fan" > BLDC Ceiling Fans </a>  &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <span style={{ color: "#1428A1", fontSize: "13px" }}>Colorbot Helix</span></p>
                    {product.length > 0 ? <div className="row justify-content-center pt-2">

                        <div className="col-md-12 col-lg-6 pt-2 prod-img-pad">
                            <LazyLoadImage src={imageselected}
                                width={"100%"}
                                alt="helixproduct" className="img-fluid darkimage"
                                effect="blur"
                            />

                            <Slider {...settings} className="helix slick-sliderdark">
                                {image.map((item, index) => {
                                    return (
                                        <div className="helixmultiple" key={index}>
                                            <LazyLoadImage src={item} onClick={() => {

                                                setimageselected(item)
                                            }} className={`${imageselected}` === item ? "img-fluid heliximageactive" : "img-fluid"} alt="helixproduct"
                                                effect="blur" width={"100%"}
                                            />

                                        </div>
                                    )
                                })}

                            </Slider>
                        </div>
                        <div className="col-md-12 col-lg-6 pt-2">
                            <h1 className="helixproductname producttop">{product_Name}</h1>
                            <p className="helixsubproductname producttop">{product_Description}</p>

                            <span className="helixprice producttop">₹ {discountedprice} <span className="helixmainprice"> {price}</span> <span className="helixdiscount">-{(((price - discountedprice) / price) * 100).toFixed(0)}%</span></span>

                            <p className="line"></p>

                            <div className="helixremoteoption producttop">
                                <span className="remotename" style={{ marginBottom: "0px" }}>Remote:</span>
                                &nbsp;&nbsp;&nbsp;
                                <button className={"helixbuttonlist helixbuttonactive helix-remote remote-white"} onClick={() => {
                                    setremote(true)
                                }} >White</button>
                                <button className={"helixbuttonlist helixbuttonactive helix-remote remote-black diagonalCross2"} onClick={() => {
                                    setremote(true)
                                }} >Black</button>
                            </div>
                            <div className="helixbuttonoption producttop">
                                <span className="remotename">Sweep Size:</span>
                                &nbsp;&nbsp;&nbsp;
                                <div className="buttonlist">
                                    {buttonlist.map((item, index) => {

                                        return !item.available ? (
                                            <button className={item.value === buttonactive ? "helixbuttonlist helixbuttonactive" : "helixbuttonlist diagonalCross2"} disabled={!item.available} key={index} onClick={() => {

                                                getsizepairedproduct(item.value);



                                            }}>{item.value}</button>

                                        ) : (
                                            <button className={item.value === buttonactive ? "helixbuttonlist helixbuttonactive" : "helixbuttonlist "} disabled={!item.available} key={index} onClick={() => {

                                                getsizepairedproduct(item.value);



                                            }}>{item.value}</button>
                                        )
                                    })}

                                </div>

                            </div>
                            <div className="helixbuttonoption producttop">
                                <span className="remotename">Color:</span>
                                &nbsp;
                                <span className="helixcolor text-left">{getActiveColorName()}</span>
                                <br />
                                {/* <div className="helixcolorlist">
                                {colorlist.map((item, index) => {
                                    return (

                                        <div
                                            className={item.color === activecolor ? "helixcolorlistitem helixcoloractive" : "helixcolorlistitem"}
                                            key={index}
                                            onClick={() => {
                                                if (item.available) {
                                                    setactivecolor(item.color);
                                                    getpairedproduct(item.color);
                                                }
                                            }}
                                            style={{
                                                transform: "rotate(90deg)",
                                                background: `conic-gradient(${item.secondary} 0deg 180deg, ${item.color} 180deg 360deg)`,
                                                pointerEvents: !item.available ? "none" : "auto", // Disable interaction when item is available
                                                opacity: !item.available ? 1 : 1, // Optionally reduce opacity for visual indication of disabled state
                                            }}
                                        >
                                            <div className={!item.available ? "helixcolorlistitemdiagonal" : ""}>

                                            </div>
                                        </div>
                                    )
                                })}
                            </div> */}

                            </div>

                            <div className="helixcolorlist color-list ">
                                {colorlist.map((item, index) => {
                                    return (

                                        <div
                                            className={item.color === activecolor ? "helixcolorlistitem helixcoloractive" : "helixcolorlistitem"}
                                            key={index}
                                            id={`color-${item.color}`}
                                            onClick={() => {
                                                if (item.available) {
                                                    setactivecolor(item.color);
                                                    getpairedproduct(item.color);
                                                }
                                            }}
                                            style={{
                                                transform: "rotate(90deg)",
                                                background: `conic-gradient(${item.secondary} 0deg 180deg, ${item.color} 180deg 360deg)`,
                                                pointerEvents: !item.available ? "none" : "auto", // Disable interaction when item is available
                                                opacity: !item.available ? 1 : 1, // Optionally reduce opacity for visual indication of disabled state
                                            }}
                                        >
                                            <div className={!item.available ? "helixcolorlistitemdiagonal" : ""}>

                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="helixquantity producttop">
                                <span className="remotename">Quantity:</span>
                                &nbsp;&nbsp;&nbsp;

                                <input
                                    type="number"
                                    className="helixselect"
                                    value={quantity}
                                    onChange={(e) => {

                                        const inputValue = parseInt(e.target.value, 10);

                                        if (inputValue < 0 || inputValue > 99) {
                                            // If the input is invalid, you can choose to handle it here.
                                            // For example, you might want to show an error message or prevent further processing.
                                            return;
                                        }
                                        handleChangeQuantity(e.target.value)

                                    }}
                                />
                                <span className="helixshare" onClick={() => {
                                    ShareInfo()
                                }}>Share &nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/share.svg" alt="share" className="img-fluid" /> </span>

                            </div>

                            {inventory > 0 && parseInt(quantity) <= parseInt(inventory) ? <div className="helixbutton">
                                <button id="helix-fan-buynow" className={quantity > 0 ? "helixbuynow" : "helixbuynow disabled"} disabled={quantity > 0 ? false : true} onClick={() => {
                                    buyproduct()
                                }}>Buy Now</button>
                                <button id="helix-fan-addcart" className={quantity > 0 ? "helixaddtocart" : "helixaddtocart disabled"} disabled={quantity > 0 ? false : true} onClick={() => {
                                    addtocart()

                                }}>Add to Cart</button>
                            </div> : <p className="text-danger text-center">Out of stock!</p>}
                            <div className="helixwarranty">
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/2yearwarranty.svg"}
                                    width={"100%"}
                                    className=" img-fluid lazysupportimg" alt="2yearwarranty"
                                    effect="blur"
                                />
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/freeshipping.svg"}
                                    width={"100%"}
                                    className=" img-fluid lazysupportimg" alt="freeshipping"
                                    effect="blur"
                                />
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/easyinstallation.svg"}
                                    width={"100%"}
                                    className=" img-fluid lazysupportimg" alt="easyinstallation"
                                    effect="blur"
                                />
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/5star.svg"}
                                    width={"100%"}
                                    className=" img-fluid lazysupportimg" alt="5star"
                                    effect="blur"
                                />

                            </div>

                            <div className="amazon-flipkart producttop">
                                <p><b>Also Available on : &nbsp;</b>   <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Amazon.svg" className="img-fluid" alt="amazon" /> &nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Flipkart.svg" className="img-fluid" alt="flipkart" /></p>
                            </div>
                            <div className="pincodechecker">
                                <div>
                                    <input type="text" className="helixpincode form-control-sm" maxLength={6} placeholder="Pincode" value={pincode} onChange={(e) => {
                                        setPincode(e.target.value);
                                        setCity('');
                                        setState('');
                                        setError('')
                                    }} />
                                    <button className="helixcheck" onClick={() => {
                                        checkpincode();
                                    }}>Check</button>
                                </div>
                                <NavLink to="https://colorbots3.s3.ap-south-1.amazonaws.com/Buyers+Guid/Buyers-Guid.pdf" target="blank" >  <span className="buyinguide">Fan Buying Guide &nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/pdf.svg" className="img-fluid" alt="pdf" /></span> </NavLink>
                            </div>
                            <div className="Address">
                                {state !== "" ? <span className="subaddress" >{city} / {state}, {pincode} <br />Delivery by {convertdatewithoutMonth(moment().add(4, 'days').format("DD-MM-YYYY"))}-{convertdate(moment().add(7, 'days').format("DD-MM-YYYY"))}</span> : ""}
                                <span className="error">{error}</span>
                            </div>
                            <br />
                        </div>
                    </div> : <p className="text-center text-dark m-4">No Products Available</p>}

                </div>
                <div className="imagecollarge">
                    <div className="container-fluid pt-5 pb-5">
                        <div className="imagehorizontal subimagehorizontal">
                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/Helix-tiles-img1.png"}

                                className=" img-fluid helix100 helixpadd" alt="BLDC Fan chip"
                                effect="blur"
                            />

                            <div>
                                <div className="subimagehorizontal">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/Helix-tiles-img2.png"}

                                        className=" img-fluid helix100 helixpadd" alt="Colorbot BLDC fan energy efficiency"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/Helix-tiles-img3.png"}

                                        className=" img-fluid helix100 helixpadd" alt="higher speed and airflow"
                                        effect="blur"
                                    />


                                </div>
                                <div className="subimagehorizontal">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/Helix-tiles-img4.png"}

                                        className=" img-fluid helix100 helixpadd" alt="Colorbot Ceiling Fan Fan remote"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/Helix-tiles-img5.png"}

                                        className=" img-fluid helix100 helixpadd" alt="Colorbot’s sustainable packaging"
                                        effect="blur"
                                    />


                                </div>
                            </div>

                        </div>
                        <div className="subimagehorizontal">
                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/Helix-tiles-img6.png"}

                                className=" img-fluid helix100 helixpadd" alt="a spinning top symbolising Colorbot fan’s wobble-free motor"
                                effect="blur"
                            />
                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/Helix-tiles-img7.png"}

                                className=" img-fluid helix100 helixpadd" alt="Colorbot BLDC fan’s noiseless operation"
                                effect="blur"
                            />
                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/Helix-tiles-img8.png"}

                                className=" img-fluid helix100 helixpadd" alt="An image showing the wide range of colors in Colorbot fans"
                                effect="blur"
                            />


                        </div>
                    </div>
                </div>
                <div className={currentwidth > 820 ? "containvideo" : "containvideoMobile"} ref={sectionRef}>

                    <video ref={videoRef} id={isVisibleModule ? "videoplayerhtml" : "videoplayerhtml"} className="f-video-player" muted="muted" controls={false} playsInline>
                        <source src={currentwidth > 768 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/28_whatss.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/28_whatss-mobile.mp4"} type="video/mp4" alt="Colorbot BLDC fan takes only 28 watts, saving around 67% of energy as compared to normal fans" />

                    </video>
                </div>
                {/* <div className="helixprogress " id="helixtarget" ref={scrollTargetRef} >
                <video muted  id="helixwatt" ref={videoRef} className={`helixvideo ${videoEnded ? 'video-ended' : ''}`}>
                    
                    <source src={currentwidth > 768 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/28_whatss.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/28_whatss-mobile.mp4"} type="video/mp4" />
                </video>
            </div> */}
                {/* <div style={{ display: "relative" }}>
                <div ref={setHeightRef} style={{ display: "block" }}>
                    <video ref={videoRef} id="v0" >
                        <source src={currentwidth > 768 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/28_whatss.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/28_whatss-mobile.mp4"} type="video/mp4" />
                    </video>
                </div>
            </div> */}

                <div className="helixopp">

                    <video autoPlay muted id="helixmyVideo" controls={false} playsInline>
                        <source src={currentwidth > 768 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/helix-chip-PC.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/helix-chip-mobile.mp4"} type="video/mp4" alt="Video of BLDC chip" />
                    </video>
                    <FadeInSection>
                        <div className="row">
                            <div className="col-md-6 col-lg-4 helixcontent">
                                <h2>Smarter. Better.</h2>
                                <br />
                                <p>Fans of Colorbot are powered by BLDC motor and electronically programmed with the advanced Color-B algorithm for uninterrupted comfort, better performance and unmatched efficiency.
                                    <br />
                                    <br />
                                    <i>Now this is called a Smart Fan!</i> </p>

                            </div>
                        </div>
                    </FadeInSection>
                </div>
                <div className="helixopp">
                    <video autoPlay muted loop ref={videoRef1} id="helixmyVideo1" controls={false} playsInline>
                        <source src={currentwidth > 768 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/fan_animated.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/Helix-airflow-fan-mobile.mp4"} type="video/mp4" alt="a moving Helix fan, denoting its speed and performance" />

                    </video>
                    <FadeInSection>
                        <div className="row">
                            <div className="col-md-5 col-lg-8"></div>
                            <div className="col-md-7 col-lg-4 helixcontentright" ref={aeroRef}>

                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/helixmaximum.png" className="img-fluid" alt="Maximized Speed. Optimized Airflow." />

                                <br />
                                <p>
                                    With aerodynamically designed blades, airflow is optimized to reach in every corner of the space. Colorbot
                                    Helix gets your cooling comfort unleashed with its maximum speed of 370 RPM and airflow of 230 CMM.
                                </p>
                            </div>
                        </div>
                    </FadeInSection>
                </div>

                <div className="helixswiper  pt-5">
                    <div className="container ">
                        <FadeInSection>
                            <div className="row justify-content-center">
                                <div className="col-md-12 col-lg-9">
                                    <div className="helixswiperheading">
                                        <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/helixcapative.png" className="img-fluid" alt="Captivating Colors Range" />
                                    </div>
                                    <div className="helixswiperpara">
                                        It’s never too many when it’s about colors. So, take your time and choose from Colorbot Helix’s array of
                                        iconic colors that perfectly blend with your home theme. Enjoy this summer with the spins that look
                                        stunning in every shade.
                                    </div>

                                    <iframe src="https://colorbots3.s3.ap-south-1.amazonaws.com/dist/index.html" width={"100%"} style={{ overflow: "hidden" }} className="helixiframe" title="slider"></iframe>
                                </div>
                            </div>
                        </FadeInSection>
                    </div>
                </div>
                <FadeInSection>
                    <div className="helixbrezze pt-5 pb-5">

                        <div className="padd">
                            <h2>No Buzz, Just Breeze</h2>
                            <br />

                            <p className="padd-p">With BLDC motor and aerodynamic blades, the airflow is quite quieter. Your comfort is unaffected by any unwanted noise, even when the fan runs on inverter.</p>
                        </div>

                    </div>
                </FadeInSection>
                <div className="helixopp">

                    <video autoPlay muted loop id="helixmyVideo3" controls={false} playsInline>
                        <source src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/smoke_new.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/helix-lattu-mobile.mp4"} type="video/mp4" alt="A video of moving spinning top denoting the stability of a Helix fan" />

                    </video>
                    <FadeInSection>

                        <div className="padd">
                            <h2 className="helixsmokehead">Stunningly Stable</h2>
                            <br />
                            <p className="helixsmokepara padd-p"> Now enjoy wobble-free comfort at any input voltage with Colorbot BLDC fans.
                                The precisely engineered and balanced blades that run on the powerful BLDC motor ensure your fan’s steady and
                                sturdy oscillation.</p>

                        </div>

                    </FadeInSection>
                </div>
                <div className="helixcomfort pt-5">
                    <br />
                    <FadeInSection>
                        <div className="container ">
                            <div className="row">
                                <div className=" col-md-12 col-lg-6">
                                    <h2 className="helixcomforthead">Comfort under Control</h2>
                                    <br />
                                    <p className="helixcomfortpara">Colorbot offers one-of-a-kind remote that gives ease with style. Simplify all your fan tasks with its advanced features without getting out of your comfort zone.</p>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3 className="subhelixcomforthead">
                                                6 Speed Options
                                            </h3>
                                            <p className="subhelixcomfortpara">
                                                Create your own cooling atmosphere with 6 speed options.
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="subhelixcomforthead">
                                                Timer
                                            </h3>
                                            <p className="subhelixcomfortpara">
                                                Keep your naps uninterrupted by using the Timer and Sleep Mode options.
                                            </p>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3 className="subhelixcomforthead">
                                                Reverse Mode
                                            </h3>
                                            <p className="subhelixcomfortpara">
                                                Reverse mode lets your fan spin clock-wise to updraft the warm air during chilly winters,
                                                keeping you cool and cozy throughout the year.
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="subhelixcomforthead">
                                                Sleek and Smart
                                            </h3>
                                            <p className="subhelixcomfortpara">
                                                The remote is classy and lightweight, and ergonomically perfect for the right grip in your hands.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-12  col-lg-6 helixcomfortmiddle">
                                    <br />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/product-helix/helixcomfortremote.png"}

                                        className=" img-fluid " alt="Colorbot ceiling black color Remote"
                                        effect="blur"
                                    />

                                </div>
                            </div>
                        </div>
                    </FadeInSection>
                    <br />
                    <br />
                </div>
                <div className="helixinstallation pb-5 pt-5">
                    <br />
                    <div className="container">
                        <FadeInSection>
                            <div className="row">
                                <div className="col-md-12  col-lg-7 helixcomfortmiddle">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/helixinstallation.svg"}

                                        className=" img-fluid helix100" alt="Easy installation feature of Helix BLDC fans"
                                        effect="blur"
                                    />

                                </div>
                                <div className="col-md-12 col-lg-5 helixcomfortmiddle">
                                    <h2 className="helixinstallationhead">Easy Installation </h2>
                                    <br />
                                    <p className="helixinstallationpara">User manual that’s made easy to guide you throughout the unboxing and installing. Get the fan installed in a jiffy by following the Installation guide.</p>
                                </div>
                            </div>
                        </FadeInSection>
                    </div>
                    <br />
                </div>
                <div className={currentwidth > 758 ? "helixreusepc pb-5 pt-5" : "helixreuse pb-5 pt-5"}>
                    <br />

                    <FadeInSection>

                        <div className="padd ">
                            <h2 className="helixreusehead"><span style={{ color: "#00B207" }}>R</span>educed. <span style={{ color: "#00B207" }}>R</span>eused. <span style={{ color: "#00B207" }}>R</span>ecycled</h2>
                            <br />
                            <p className="helixreusepara padd-p"> Colorbot is one step ahead towards a greener world.
                                Our packaging materials are made up of reusable and recycled materials that are non-toxic and
                                non-hazardous, as Colorbot ensures sustainable approach in every step.</p>

                        </div>

                    </FadeInSection>
                    <br />
                </div>



            </section>
    );
}
export default Producthelix;