import "./Faq.css";
import React, { useRef, useEffect, useState } from 'react';
function Faq() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const h3RefFaq = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isH3Visible, setIsH3Visible] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const h3Position = h3RefFaq.current.offsetTop;
            const offset = window.innerHeight / 2;
        
            if (scrollPosition + offset >= h3Position) {
                setTimeout(() => {
                    setIsH3Visible(true);
                  }, 500);
            } else {
            //   setIsH3Visible(false);
            }
            setScrollPosition(scrollPosition);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        document.title = "FAQ's - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "FAQ's - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    useEffect(() => {
        // const title = h3RefFaq.current;

        // if (title) {
        //     const rect = title.getBoundingClientRect();
        //     const offset = window.innerHeight / 2;

        //     if (rect.top <= offset) {
        //         title.classList.add('active');
        //     } else {
        //         // title.classList.remove('active');
        //     }
        // }
    }, [scrollPosition]);

    const handleSearch = () => {
        if(search.includes('Why are Colorbot fans known as the Power-Savers?')){
            document.getElementById('flush-collapseOne').classList.add('show');
        }else if(search.includes('How are Colorbot BLDC fans different from normal ceiling fans?')){
            document.getElementById('flush-collapseTwo').classList.add('show');
        }else if(search.includes('Are all Colorbot fans energy efficient?')){
            document.getElementById('flush-collapseThree').classList.add('show');
        }else if(search.includes('Are all Colorbot fans sustainable?')){
            document.getElementById('flush-collapseFour').classList.add('show');
        }else if(search.includes('Why are Colorbot BLDC fans more stable?')){
            document.getElementById('flush-collapseFive').classList.add('show');
        }
    }
    return (
        <>
            <section className='mt-5 pt-4'>





                <div className="container-fluid mt-5">
                    <div className="row justify-content-center mt-4">
                        <div className="col-lg-8 mt-3 ">
                            <div className="Faqtitle">
                                <h1 className={`border-bottom-animation h3hoverbefore faq-head ${isH3Visible ? 'active' : ''}`} ref={h3RefFaq}>Frequently Asked Questions</h1>
                                <br />

                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-5">
                        <div className="col-lg-4">
                            <form className="example pecs"  >
                                <input type="text" placeholder="Search" name="search2" value={search} onChange={(e)=>{
                                    setSearch(e.target.value);
                                
                                }}/>
                                <button type="button" onClick={()=>{
                                    handleSearch();
                                }}><i className="fa fa-search"></i></button>
                            </form>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-1 mb-5">
                        <div className="col-lg-7 mt-5 ">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button faq collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Why are Colorbot fans known as the Power-Savers?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Colorbot fans are known as Power-Savers because they run on highly energy efficient BLDC motors. The fans only consume up to 28 watts of energy at maximum speed and in fact just 24 watts of energy at speed 5.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingTwo">
                                        <button className="accordion-button faq collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        How are Colorbot BLDC fans different from normal ceiling fans?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">All the Colorbot fans are based on BLDC motor technology and serve primarily to reduce power consumption whereas normal fans are made of AC motors and consume around 70-80 watts. Along with their highly energy efficient motor, these fans also offer a lot of smart features like, consistent air delivery even at low voltage input and noiseless operation even on inverters. Unlike normal fans, all Colorbot Fans come with remote control for easy operation and they are aerodynamically and aesthetically designed for an upgraded look and feel of comfort. etc</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingThree">
                                        <button className="accordion-button faq collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        Are all Colorbot fans energy efficient?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Yes, all the Colorbot fans use highly advanced BLDC motor technology and take only 28 watts of power which make them highly energy-efficient.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFour">
                                        <button className="accordion-button faq collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        Are all Colorbot fans sustainable?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Yes, all the colorbot fans are eco-friendly. These fans do not leave a carbon footprint and contribute to the environment by consuming less power</div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="flush-headingFive">
                                        <button className="accordion-button faq collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                        Why are Colorbot BLDC fans more stable?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Colorbot BLDC fans are more stable as they are operated by BLDC motors which are more stable due to their brushless high-torque motor. These fans have greater build quality with100% copper winding and double ball bearing for high speed unaffected speed and air delivery. The fans are meticulously engineered for perfect balancing and have internal shock absorbing elements. That's why they are sturdy and they do not wobble while spinning.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default Faq;