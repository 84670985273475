import "./Signup.css"
import { NavLink } from "react-router-dom";
function Signup() {
    return (
        <>
            <section className="signback mt-5 pt-5 pb-5">
                <div className="container">
                    <div className="row justify-content-center mt-4">

                        <div className=" col-md-6">
                            <div className=" boxxx">
                                <div className="heading-texxt text-center">
                                    <span>Sign Up</span>
                                </div>




                                <div className="form-signin">
                                    <input type="text" className="form-control form-alignment" id="exampleInputname1"
                                        aria-describedby="FullName" placeholder="Full Name" />
                                    <input type="email" className="form-control form-alignment" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" placeholder="Email ID" />

                                   
                                    <input type="password" className="form-control form-alignment" id="exampleInputPassword1"
                                        placeholder="Create Password" />

                                </div>



                                <div>

                                   <NavLink to="/sign_up_verify_otp"> <input type="submit" className="btn  button-signin" value="Sign Up" /></NavLink>
                                </div>




                                <div className=" paraa-1 mt-4">
                                    <p>Or</p>
                                </div>


                                <div className=" paraa-2 ">
                                    <p>Sign Up with</p>
                                </div>


                                <div className="icconny">
                                    <div className="iconny-1 ">
                                        <button className="btn socialbtn"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/facebook 1.svg" alt="" /></button>
                                    </div>

                                    <div className="iconny-2">
                                        <button className="btn socialbtn"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/google-symbol 1.svg" alt="" /></button>
                                    </div>
                                </div>

                                <div className="bottom-para">
                                    <span>Already have an account? </span> <NavLink to="/login" className="signup"> <u>Sign In</u></NavLink>
                                </div>


                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
export default Signup;