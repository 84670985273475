
import { NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css"
import { useEffect, useState } from "react";

import moment from "moment";
// import { useEffect } from "react";
function Navbar(props) {
    const navigate = useNavigate();
    const removeItem = (productId) => {
        props.setcartdata(prevData => {
            const updatedData = prevData.filter(item => item.productid !== productId);
            window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
            return updatedData;
        });
        if (props.cartdata.length === 1) {
            props.closesidepanel();
            localStorage.removeItem("activeaddress")
            removeproductonquantity0()
        }
    };
    const [navbarwidth, setnavbarwidth] = useState(window.innerWidth)

    const [checkquanity, setcheckquanity] = useState(false)
    const [checkinventory, setcheckinventory] = useState(false)
    function checkvalidquantity(quantity) {
        if (quantity === "" || parseInt(quantity) === 0) {

            setcheckquanity(true)
        } else {
            setcheckquanity(false)
        }


    }
    function checkValidInventory(quantity, inventory) {

        if (quantity === "" || parseInt(quantity) === 0) {
            setcheckinventory(false)

        } else {

            if (parseInt(quantity) > parseInt(inventory)) {

                setcheckinventory(true)
            } else {

                setcheckinventory(false)
            }
        }
    }
    const handleChangeQuantity = (productId, newQuantity) => {
        checkvalidquantity(newQuantity)
        checkValidInventory(newQuantity, props.cartdata.find(item => item.productid === productId).inventory)
        if (newQuantity === "" || parseInt(newQuantity) === 0) {

            props.setcartdata(prevData => {
                const updatedData = prevData.map(item => {

                    if (item.productid === productId) {
                        return { ...item, productquantity: newQuantity, productprice: item.fixedproductprice * newQuantity, productdiscountedprice: item.fixedproductdiscountedprice * newQuantity };
                    }
                    return item;
                });

                window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            });
        } else {
            props.setcartdata(prevData => {
                const updatedData = prevData.map(item => {
                    if (item.productid === productId) {
                        return { ...item, productquantity: newQuantity, productprice: item.fixedproductprice * newQuantity, productdiscountedprice: item.fixedproductdiscountedprice * newQuantity };
                    }
                    return item;
                });

                window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            });
        }

    };


    const [name, setname] = useState("")
    useEffect(() => {
        if (window.localStorage.getItem("FirstName") !== undefined) {
            setname(window.localStorage.getItem("FirstName"))
        } else {
            setname("New User")
        }
        setnavbarwidth(window.innerWidth)
    }, [navbarwidth])

    

    useEffect(() => {
        const intervalId = setInterval(async () => {
            const isAddCart = window.localStorage.getItem('addcart') === 'true';
            const isLoggedIn = window.localStorage.getItem('LoggedIn') === 'true';

            if (isAddCart && isLoggedIn) {
                const cartDate = moment(window.localStorage.getItem('cartdate'), 'DD-MM-YYYY HH:mm:ss');

                if (moment().diff(cartDate, 'hours') > 2 && localStorage.getItem('cartdata') !== null && JSON.parse(window.localStorage.getItem('cartdata')).length > 0) {
                    const cartData = JSON.parse(window.localStorage.getItem('cartdata'));
                    const mainData = cartData.length > 0 && cartData.map((item) => ({
                        UserId: window.localStorage.getItem('UserId'),
                        OrderItemId: item.productid,
                        OrderTotal: item.productdiscountedprice,
                        OrderSubTotal: item.productprice,
                        OrderQuantity: item.productquantity,
                        OrderColor: item.productcolor,
                        OrderSize: item.productsweepsize,
                        OrderImage: item.productimage,
                        OrderName: item.productname,

                    }));

                    try {
                        const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotUpdateAbandonedOrders`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                            },
                            body: JSON.stringify({
                                maindata: mainData,
                                name: localStorage.getItem("FirstName") + " " + localStorage.getItem("LastName"),
                                phone: localStorage.getItem("phoneNumber"),
                            }),
                        }).then((res) => res.json());

                        if (response.status === 200) {
                            window.localStorage.setItem('addcart', 'false');
                            window.localStorage.setItem('cartdate', '');
                            props.setcartdata([]);
                            clearInterval(intervalId);







                            // Clear the interval to stop further execution

                        } else {
                            console.log("failed");
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
        }, 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);





    const navigateToProduct = (path) => {
        navigate(path);
    }
    const removeproductonquantity0 = () => {
        props?.setcartdata(prevData => {
            const updatedData = prevData?.filter(item => item.productquantity !== "0");
            window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
            return updatedData;
        });
        setcheckinventory(false)
        setcheckquanity(false)
        if (JSON.parse(window.localStorage.getItem("cartdata"))?.length === 0) {
            // navigate("/bldc-ceiling-fan")
        }
    }




    return (
        <>


            <div className={props.sidepanel ? "sidebar-overlayactive sidebar-overlay" : "sidebar-overlay"}></div>
            <div
                id="sidePanel"
                onClick={(event) => {
                    event.stopPropagation(); // Stop event propagation
                    props.closesidebar("open");

                }}
                className={props.sidepanel ? 'open overlay' : ''}

            >
                <div className="sidepanel-content">
                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/carticon.svg" className="img-fluid" alt="cart" height="75" width="75" loading="lazy"/>
                    <span className="cartclose" onClick={() => { props.closesidepanel(); removeproductonquantity0(); }}> <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/closecart.svg" alt="close" className="img-fluid"  width="16" height="16" /></span>
                </div>
                <br />
                {props.cartdata.length === 0 ? <div className="sidepanel-contentcartempty">
                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/shopping_Cart.svg" className="img-fluid" alt="cart" height="95" width="95" loading="lazy" />
                    <p className="cartemptytext">Your Cart is empty!</p>
                    <NavLink to="/bldc-ceiling-fan"><button className="btn btn-primary cartemptybtn" onClick={() => { props.closesidepanel(); removeproductonquantity0() }}>Continue Shopping</button></NavLink>

                </div> : ""}
                {props.cartdata.length > 0 ? <div className="sidepanel-contentcart">
                    {props.cartdata.map((data, index) => {
                        return (
                            <div className="row justify-content-center cartbottom" key={index}>
                                <div className="col-md-6 col-6 cartproductalign1">
                                    <div className="cartproducttitle">{data.productname}</div>
                                    <div className="cartproductcolor">Color: {data.productcolor}</div>
                                    <div className="cartproductcolor">Sweep Size: {data.productsweepsize}</div>
                                    <div className="cartproductquantity">
                                        <div className="cartproductquantitytitle">Quantity:</div>
                                        &nbsp;&nbsp;&nbsp;
                                       
                                        <input id={`quantity${index}`}
                                            type="number"
                                            aria-label="quantity"
                                            className="helixselect cartproductquantityselect" style={{ padding: "0px", marginTop: "0px", lineHeight: "21px" }}
                                            value={data.productquantity}
                                            onChange={(e) => {
                                                const inputValue = parseInt(e.target.value, 10);

                                                if (inputValue < 0 || inputValue > 99) {
                                                    // If the input is invalid, you can choose to handle it here.
                                                    // For example, you might want to show an error message or prevent further processing.
                                                    return;
                                                }
                                                handleChangeQuantity(data.productid, e.target.value)

                                            }}
                                        />
                                       
                                        


                                    </div>
                                    <div className="cartproductremove" onClick={() => {
                                        removeItem(data.productid)
                                    }}>Remove</div>

                                    {parseInt(data.productquantity) > parseInt(data.inventory) ? <p className="text-danger" style={{ fontSize: "13px" }}>Out of stock</p> : ""}
                                </div>

                                <div className="col-md-5 col-5 cartproductalign">
                                    <img src={data.productimage} className="img-fluid cardproduct" alt={`productimage${index}`} style={{
                                        cursor: "pointer"
                                    }} onClick={() => {
                                        navigateToProduct(data.productpath)
                                    }} />

                                    <br />
                                    <div className="price" style={{ marginTop: "-14px" }}>
                                        <p className="cartproductprice"><i className="fa fa-inr" aria-hidden="true"></i>{data.productdiscountedprice} </p>
                                        <p className="dis cartproductpricedis"><del><i className="fa fa-inr" aria-hidden="true"></i>{data.productprice}</del> </p>
                                    </div>
                                </div>

                            </div>
                        );
                    })}
                    {props.LoggedIn ? <div className="cartbuttonalign" disabled={checkquanity || checkinventory}>  <button className={checkquanity || checkinventory ? "btn btn-primary cartbuynow disabled" : "btn btn-primary cartbuynow"} disabled={checkquanity || checkinventory} onClick={() => {
                        props.closesidepanel();
                        removeproductonquantity0()
                        navigate("/product_buy_now_delivery")
                    }}>Buy Now</button></div> : <div className="cartbuttonalign" disabled={checkquanity || checkinventory}>  <button className={checkquanity || checkinventory ? "btn btn-primary cartbuynow disabled" : "btn btn-primary cartbuynow"} disabled={checkquanity || checkinventory} onClick={() => {
                        props.closesidepanel();
                        removeproductonquantity0()
                        navigate("/product_buy_now_login")
                    }}>Buy Now</button></div>}

                </div> : ""}


            </div>
            <nav ref={props.navbarRef} className="navbar navbar-expand-lg nav-white" id="navremove">
                <div className="container-fluid">
                    <NavLink className="navbar-brand brand-logo" to="/">
                        <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Colorbot-logo-tm.webp" alt="colorbot-logo" />
                    </NavLink>
                    <div className="alignnavcar">
                        {navbarwidth < 820 ? (
                            <div className="carticon">
                                <div className="cartnumber" onClick={() => { props.closecollapse(true) }}>
                                    <span className="cartnumber1">{props.cartdata.length}</span>
                                    <NavLink to="#">
                                        <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/cart.svg" alt="cart" height="25" width="25" onClick={() => { props.opensidepanel() }} />
                                    </NavLink>
                                </div>
                            </div>
                        ) : ""}
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" id="collapse">
                            <span className="navbar-toggler-icon" alt="toggle-icon">Open Menu</span>
                        </button>
                    </div>

                    <div className="collapse navbar-collapse nav-align" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink to="/" className={({ isActive }) => isActive ? "nav-link navhover navactive" : 'nav-link navhover'} onClick={() => { props.closecollapse(true) }}>Home</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/bldc-ceiling-fan" className={({ isActive }) => isActive ? "nav-link navhover navactive" : 'nav-link navhover'} onClick={() => { props.closecollapse(true) }}>Products</NavLink>
                                <ul className="submenu">
                                    <li>
                                        <div className="row m-1">
                                            <div className="col-md-4 rightclass" onClick={() => {
                                                props.closecollapse(true)
                                            }}>

                                                <span style={{ overflow: "hidden" }}> <NavLink to="/colorbot-helix"  ><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-hover-helix.webp" alt="Product Helix"  height="190" width="190"  className="img-thumbnail imgzoom  img-fluid" loading="lazy" style={{ border: "none" }} /></NavLink></span>


                                                <br />
                                                <p className="submenu-title">Colorbot Helix</p>
                                            </div>
                                            <div className="col-md-4 text-center" onClick={() => {
                                                props.closecollapse(true)
                                            }}>

                                                <NavLink to="/colorbot-knoxx"  > <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-hover-knoxx.webp" alt="Product Knoxx"  height="190" width="190" className="img-thumbnail imgzoom  img-fluid" loading="lazy" style={{ border: "none" }} /></NavLink>


                                                <br />
                                                <p className="submenu-title">Colorbot Knoxx</p>
                                            </div>
                                            <div className="col-md-4 leftclass" onClick={() => {
                                                props.closecollapse(true)
                                            }}>
                                                <NavLink to="/colorbot-stella"  > <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Product-category/Product-hover-stella.webp" alt="Product Stella Quad"  height="190" width="190" className="img-thumbnail imgzoom" loading="lazy" style={{ border: "none" }} /></NavLink>
                                                <br />
                                                <p className="submenu-title">Colorbot Stella</p>
                                            </div>
                                        </div>

                                        <div className="row  ">
                                            <div className="col-md-12 text-center">
                                                <NavLink to="/bldc-ceiling-fan"  >

                                                    <button className="btn viewnav" onClick={() => {
                                                        props.closecollapse(true)
                                                    }}>View More</button>
                                                </NavLink>
                                            </div>
                                        </div></li>



                                </ul>
                            </li>
                            <li className="nav-item">
                                <div className="dark">
                                    <span className="darknav">New</span>
                                    <NavLink className={({ isActive }) => isActive ? "nav-link navhover navactive" : 'nav-link navhover'} to="/dark" onClick={() => { props.closecollapse(true) }}>Dark</NavLink>
                                </div>
                            </li>
                            <li className="nav-item techno-nav">
                                <NavLink className={({ isActive }) => isActive ? "nav-link navhover navactive" : 'nav-link navhover'} to="/technology" onClick={() => { props.closecollapse(true) }}>Technology</NavLink>
                            </li>
                            <li className="nav-item dropdown">
                                <NavLink className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => props.closecollapse("support")}>Support</NavLink>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><NavLink className={({ isActive }) => isActive ? "dropdown-item navactive" : 'dropdown-item'} to="/contact" onClick={() => { props.closecollapse(true) }}>Contact Us</NavLink></li>
                                    <li><NavLink className={({ isActive }) => isActive ? "dropdown-item navactive" : 'dropdown-item'} to="/warranty-registration" onClick={() => { props.closecollapse(true) }}>Register Warranty</NavLink></li>
                                    <li><NavLink className={({ isActive }) => isActive ? "dropdown-item navactive" : 'dropdown-item'} to="/track-order" onClick={() => { props.closecollapse(true) }}>Track your order</NavLink></li>
                                    <li><NavLink className={({ isActive }) => isActive ? "dropdown-item navactive" : 'dropdown-item'} to="/return-policy" onClick={() => { props.closecollapse(true) }}>Return Policy</NavLink></li>
                                    <li><NavLink className={({ isActive }) => isActive ? "dropdown-item navactive" : 'dropdown-item'} to="/services-policies" onClick={() => { props.closecollapse(true) }}>Services & Policies</NavLink></li>
                                </ul>
                            </li>
                            <li className="nav-item nav-icons profile-icon">
                                {props.LoggedIn ? (
                                    <NavLink to="/Order" style={{ textDecoration: "none", position: "relative" }} onClick={() => { props.closecollapse(true) }}>
                                        <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/profile.svg" alt="profile" height="25" width="25"  loading="lazy"/>
                                        <span className="nameprofile">{name}</span>
                                    </NavLink>
                                ) : (
                                    <NavLink to="/login" onClick={() => { props.closecollapse(true) }}>
                                        <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/profile.svg" alt="profile" height="25" width="25"  loading="lazy"/>
                                    </NavLink>
                                )}
                            </li>
                            {navbarwidth > 820 ? (
                                <li className="nav-item nav-icons carticon">
                                    <div className="cartnumber" onClick={() => { props.closecollapse(true) }}>
                                        <span className="cartnumber1">{props.cartdata.length}</span>
                                        <NavLink to="#">
                                            <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/cart.svg" alt="cart" height="25" width="25" loading="lazy" onClick={() => { props.opensidepanel() }} />
                                        </NavLink>
                                    </div>
                                </li>
                            ) : ""}
                        </ul>
                    </div>
                </div>
            </nav>

        </>
    )

}
export default Navbar;