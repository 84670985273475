import React from 'react';
import ReactPlayer from 'react-player'
import Slider from 'react-slick'; // Assuming you are using react-slick for the slider

const VideoSlider = ({ videoUrls }) => {
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true,
        dots: true,
        speed: 300,
        centerPadding: '0px',
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 820,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1292,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 1
                }
            },

        ]
    };

    return (
        <Slider {...settings}>
            {videoUrls.map((url, index) => (
                <div className="center-slider" key={index}>
                    <div id="outer">
                        <ReactPlayer
                            url={url}
                            width="587px"
                            height="350px"
                            controls={true}
                        />
                    </div>
                </div>
            ))}
        </Slider>
    );
};

export default VideoSlider;
