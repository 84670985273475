
import './App.css';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';
import Products from './Product/Product';
import { Navigate, BrowserRouter, Routes, Route } from 'react-router-dom';
import Accessories from './Accessories/Accessories';
import Privacy from './Privacy_Policy/Privacy_Policy';
import Terms from './Terms_Conditions/Terms_Conditions';
import Return from './Return_Policy/Return_Policy';
import Technology from './Technology/Technology';
import Blog from './Blog/Blog';
import About from './About/About';
import Sitemap from './Sitemap/Sitemap';
import ContactUs from './ContactUs/ContactUs';
import RegisterWarranty from './RegisterWarranty/RegisterWarranty';
import Trackorder from './Trackorder/Trackorder';
import Servicepolicies from './Servicepolicies/Servicepolicies';
import Faq from './Faq/Faq';
import Blogdesc from './Blogdesc/Blogdesc';
import Sustainability from './Sustainability/Sustainability';
import SignIn from './signin/signin';
import Signup from './Signup/Signup';
import Forget from './Forget/Forget';
import VerifyOtp from './verifyotp/verifyotp';
import ResetPassword from './resetpassword/resetpassword';
import PasswordSuccess from './passwordsuccess/passwordsuccess';
import SignUpSuccess from './signupsuccess/signupsuccess';
import SignUpVerifyOtp from './signupverifyotp/signupverifyotp';
import { useEffect, useRef, useState } from 'react';
import Profile from './Profile/profile';
import Order from './order/order';
import OrderDetail from './orderdetail/orderdetail';
import Home from './Home/Home';
import Producthelix from './Producthelix/Producthelix';
import Productprizma from './productprizma/productprizma';
import Productstella from "./productstella/productstella"
import Productstella_quad from "./productstella_quad/productstella_quad"
import Productknoxx_quad from "./productknoxx_quad/productknoxx_quad"
import Buynowlogin from './Buynowlogin/Buynowlogin';
import Buynowdelivery from './Buynowdelivery/Buynowdelivery';
import Buynoworder from './Buynoworder/Buynoworder';
import Buynowpayment from './Buynowpayment/Buynowpayment';
import Dark from './Dark/Dark';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Knoxx from './productknoxx/productknoxx';
import NotFound404 from './404';
import Thankyou from './Buynowpayment/Thankyou';



function App() {
  // const express = require('express')
  //   const compression = require('compression')

  //   App.use(compression())






  const [LoggedIn, setLoggedIn] = useState(window.localStorage.getItem('LoggedIn'))
  const [cartdata, setcartdata] = useState([])

  useEffect(() => {
    if (window.localStorage.getItem('LoggedIn')) {
      setLoggedIn(true)
      getColorBotUser()
    }

    
    const savedCartData = localStorage.getItem('cartdata');
    if (savedCartData) {
      setcartdata(JSON.parse(savedCartData));
    } else {
      setcartdata([])
    }
  }, [])

  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  const handleButtonClick = () => {

    setSidePanelOpen(true);
  };

  const handleCloseClick = () => {
    setSidePanelOpen(false);
  };


  const getColorBotUser = async () => {
    try {

      await fetch(`${process.env.REACT_APP_PROD_URL}/GetColorBotUserDetail`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${window.localStorage.getItem('token')}`
        }
      }).then((res) => res.json()).then((response) => {

        if (response.status === 200) {

          window.localStorage.setItem('token', response.data.token);
          window.localStorage.setItem('UserId', response.data.UserId);
          if (response.data.Email !== undefined) { window.localStorage.setItem("Email", response.data.Email) }
          if (response.data.FirstName !== undefined) { window.localStorage.setItem("FirstName", response.data.FirstName) }
          if (response.data.LastName !== undefined) { window.localStorage.setItem("LastName", response.data.LastName) }

          if (response.data.Address !== undefined) { window.localStorage.setItem("Address", JSON.stringify(response.data.Address)) } else {
            window.localStorage.setItem("Address", "")
          }
          window.localStorage.setItem("LoggedIn", "true")
          setLoggedIn(true)


        } else {
          setLoggedIn(false)
          window.localStorage.setItem("LoggedIn", "false")

          window.localStorage.clear();

        }
      })
    } catch (error) {
      console.error('API Error:', error);

    }
  }
  const [showButton, setShowButton] = useState(false);

  const amountScrolled = 200;

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > amountScrolled) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  const closesidebar = (status) => {
    if (status === "close") {
      if (sidePanelOpen) {
        setSidePanelOpen(false);
      }
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_TYPE === "prod") {
      window.console.log = () => { };
      window.console.error = () => { };
      window.console.warn = () => { };
    }
  }, [])

  const [showorderpop, setshoworderpop] = useState(false)
  function closecollapse(type) {


    if (type === true) {
      if (document.getElementById("collapsibleNavbar").classList.contains("show") && window.innerWidth < 992) {
        document.getElementById("collapse").click();
      }
    }
  }
  const navbarRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        // Clicked outside of the navbar
        closecollapse(true)
      }
    };

    const handleClickInside = () => {
      // Clicked inside of the navbar

    };

    window.addEventListener('click', handleClickOutside);
    navbarRef?.current?.addEventListener('click', handleClickInside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
      navbarRef?.current?.removeEventListener('click', handleClickInside);
    };
  }, []);



  return (
    <div className="App " style={{ overflowX: "hidden" }} onClick={() => {
      closesidebar("close");

    }}>

      <BrowserRouter>
        <button
          className={`back-to-top ${showButton ? 'show' : ''}`}
          onClick={handleClick}
        >
          top

        </button>

        <div className="whatsapp-us"><img src="../assets/whatsapp.svg" width={26} height={26} alt='whatsappicon' /><div className="chat-with-us"><p>Chat with us</p><a href="https://wa.me/919289305540" target='_blank' rel='noreferrer'>9289305540</a></div></div>
        <Navbar navbarRef={navbarRef} LoggedIn={LoggedIn} closecollapse={closecollapse} closesidebar={closesidebar} opensidepanel={handleButtonClick} closesidepanel={handleCloseClick} sidepanel={sidePanelOpen} cartdata={cartdata} setcartdata={setcartdata} />
        <Routes>

          <Route exact path='/bldc-ceiling-fan' element={< Products />}></Route>
          <Route exact path="/accessories" element={< Accessories />}></Route>
          <Route exact path='/privacy-policy' element={< Privacy />}></Route>
          <Route exact path="/terms-conditions" element={< Terms />}></Route>
          <Route exact path="/return-policy" element={<Return />}></Route>
          <Route exact path="/technology" element={<Technology />}></Route>
          <Route exact path="/blog" element={<Blog />}></Route>
          <Route exact path='/about' element={<About />}> </Route>
          <Route exact path="/sitemap" element={<Sitemap />}></Route>
          <Route exact path="/contact" element={<ContactUs />}></Route>
          <Route exact path="/warranty-registration" element={<RegisterWarranty />}></Route>
          <Route exact path="/track-order" element={<Trackorder />}></Route>
          <Route exact path="/services-policies" element={<Servicepolicies />}></Route>
          <Route exact path="/faq" element={<Faq />}></Route>
          <Route exact path="/blog-info/:id" element={<Blogdesc />}> </Route>
          <Route exact path="/sustainability" element={<Sustainability />}></Route>
          <Route exact path="/login" element={<SignIn setLoggedIn={setLoggedIn} />}></Route>
          <Route exact path='/sign_up' element={<Signup />}></Route>
          <Route exact path="/forgot" element={<Forget />}></Route>
          <Route exact path="/verify_otp" element={<VerifyOtp />}></Route>
          <Route exact path="/reset_password" element={<ResetPassword />}></Route>
          <Route exact path="/success" element={<PasswordSuccess />}></Route>
          <Route exact path="/sign_up_success" element={<SignUpSuccess />}></Route>
          <Route exact path="/sign_up_verify_otp" element={<SignUpVerifyOtp setLoggedIn={setLoggedIn} />}></Route>
          {LoggedIn ? (<Route exact path="/profile" element={<Profile setLoggedIn={setLoggedIn} />}></Route>) :
            (<Route path="/profile" element={<Navigate to="/" />} />)
          }
          {LoggedIn ? (<Route exact path="/Order" element={<Order setLoggedIn={setLoggedIn} />}></Route>) :
            (
              <Route path="/Order" element={<Navigate to="/" />} />
            )}
          {LoggedIn ? (<Route exact path="/order_detail" element={<OrderDetail setLoggedIn={setLoggedIn} />}></Route>) :
            (
              <Route path="/order_detail" element={<Navigate to="/" />} />
            )}
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/dark" element={<Dark opensidepanel={handleButtonClick} cartdata={cartdata} setcartdata={setcartdata} LoggedIn={LoggedIn} />}></Route>
          <Route exact path="/colorbot-stella-quad" element={<Productstella_quad opensidepanel={handleButtonClick} cartdata={cartdata} setcartdata={setcartdata} LoggedIn={LoggedIn} />}></Route>
          <Route exact path="/colorbot-knoxx-quad" element={<Productknoxx_quad opensidepanel={handleButtonClick} cartdata={cartdata} setcartdata={setcartdata} LoggedIn={LoggedIn} />}></Route>
          <Route exact path="/colorbot-stella" element={<Productstella opensidepanel={handleButtonClick} cartdata={cartdata} setcartdata={setcartdata} LoggedIn={LoggedIn} />}></Route>
          <Route exact path="/colorbot-knoxx" element={<Knoxx opensidepanel={handleButtonClick} cartdata={cartdata} setcartdata={setcartdata} LoggedIn={LoggedIn} />}></Route>
          <Route exact path="/colorbot-prizma" element={<Productprizma opensidepanel={handleButtonClick} cartdata={cartdata} setcartdata={setcartdata} LoggedIn={LoggedIn} />}></Route>
          <Route exact path="/colorbot-helix" element={<Producthelix opensidepanel={handleButtonClick} cartdata={cartdata} setcartdata={setcartdata} LoggedIn={LoggedIn} />}></Route>
          <Route exact path="/product_buy_now_login" element={<Buynowlogin cartdata={cartdata} closesidepanel={handleCloseClick} setcartdata={setcartdata} setLoggedIn={setLoggedIn} />}></Route>
          {LoggedIn ? (<Route exact path="/product_buy_now_delivery" element={<Buynowdelivery cartdata={cartdata} setcartdata={setcartdata} closesidepanel={handleCloseClick} />}></Route>) :
            (
              <Route path="/product_buy_now_delivery" element={<Navigate to="/product_buy_now_login" />} />
            )}
          {LoggedIn ? (<Route exact path='/product_buy_now_order_summary' element={<Buynoworder cartdata={cartdata} setcartdata={setcartdata} closesidepanel={handleCloseClick} />}></Route>) :
            (
              <Route path="/product_buy_now_order_summary" element={<Navigate to="/product_buy_now_login" />} />
            )}
          {LoggedIn ? (<Route exact path="/product_buy_now_payment" element={<Buynowpayment cartdata={cartdata} setcartdata={setcartdata} closesidepanel={handleCloseClick} showorderpop={showorderpop} setshoworderpop={setshoworderpop} />}></Route>) :
            (
              <Route path="/product_buy_now_payment" element={<Navigate to="/product_buy_now_login" />} />
            )}
          {LoggedIn ? (<Route exact path="/thank-you" element={<Thankyou setshoworderpop={setshoworderpop} />}></Route>) :
            (
              <Route path="/thank-you" element={<Navigate to="/product_buy_now_login" />} />
            )}
          <Route exact path="/*" element={<NotFound404 />} />
        </Routes>

        <Footer showorderpop={showorderpop} />
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
