import { NavLink } from "react-router-dom";
import "./resetpassword.css"

function ResetPassword() {
    return (
        <>
            <section className="signback mt-5 pt-5 pb-5">
                <div className="container">
                <div className="row justify-content-center mt-4">
                <div className="col-lg-6">
                    <div className="boxxxxxy-2">
                        <div className="resett-password text-center mb-4">
                            <span>Reset Password</span>

                        </div>
                        <div >
                            <input type="password" className="form-control form-alignment mt-1" id="exampleInputPassword1" placeholder="New Password"/>
                            <input type="password" className="form-control form-alignment mt-4" id="exampleInputPassword1" placeholder="Confirm Password"/>
                        </div>

                        <div >
                          <NavLink to ="/success">  <input className="btn button-signin-reset-pass button-signin" type="submit" value="Submit"/></NavLink>
                        </div>


                    </div>
                   
                </div>
            </div>
                </div>
            </section>
        </>
    );
}
export default ResetPassword;