
import { NavLink } from "react-router-dom";
import "./Home.css";
import React, { useState, useEffect, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import FadeInSection from "../FadeInup/FadeInup";
import VideoSlider from "./YoutubeVideo";
import { Helmet } from 'react-helmet';


const HomeHead = () => {
    const [metaData, setMetaData] = useState({
        urlSlug: '',
        titleTag: '',
        metaDescription: '',
        markupName: '',
        jsonLdMarkup: '',
        ogTitle: '',
        ogDescription: '',
        ogImage: '',
    });
    const [jsonData,setJsonData] = useState(null);

    const fetchSeoData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/seo-settings/homeSeo001`);
            if (response.ok) {
                const result = await response.json();
                const data = result.data;
                setMetaData({
                    urlSlug: data.urlSlug || '',
                    titleTag: data.titleTag || '',
                    metaDescription: data.metaDescription || '',
                    markupName: data.markupName || '',
                    jsonLdMarkup: data.jsonLdMarkup || '',
                    ogTitle: data.ogTitle || '',
                    ogDescription: data.ogDescription || '',
                    ogImage: data.ogImage || '',
                });
                const jsonObjects = JSON.parse(`[${data.jsonLdMarkup}]`);
               
                setJsonData(jsonObjects);
            }
        } catch (error) {

        }

    };

    useEffect(() => {
        fetchSeoData();
    }, [])
    return (
        <>
            <Helmet>
                {/* Dynamically setting title, meta, and Open Graph tags */}
                <title>{metaData.titleTag}</title>
                <meta name="description" content={metaData.metaDescription} />
                <link rel="canonical" href={metaData.urlSlug} />
                <meta property="og:title" content={metaData.ogTitle} />
                <meta property="og:site_name" content="Colorbot" />
                <meta property="og:url" content={metaData.urlSlug} />
                <meta property="og:description" content={metaData.ogDescription} />
                <meta property="og:type" content="website" />
              
                {/* Dynamically include JSON-LD markup */}
                {jsonData && jsonData.map((data, index) => (
                    
                    <script type="application/ld+json" key={index}>
                        {JSON.stringify(data)}
                    </script>   
                ))}
            </Helmet>
            </>)
}
            
    

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })
    }, [])

    useEffect(() => {
        document.title = "Colorbot";// Set the title when component mounts
        return () => {
            document.title = "Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    // LOADER CODE
    const [showLoader, setShowLoader] = useState(true)
    const intervalRef = useRef(null);
    const nextSlideButtonRef = useRef(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLoader(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (!showLoader) {
            intervalRef.current = setInterval(() => {
                
                if (!document.getElementById("collapsibleNavbar").classList.contains("show") && !document.getElementById("sidePanel").classList.contains("open")) {
                    if (nextSlideButtonRef.current) {
                        nextSlideButtonRef.current.click();
                    }
                }
            }, 5000);
        }

        return () => clearInterval(intervalRef.current);
    }, [showLoader]);

    const [currentWatt, setCurrentWatt] = useState(82);
    const progressBarRef = useRef(null);
    const h3RefHome = useRef(null);
    const h3Ref1Home = useRef(null);
    const h3Ref2Home = useRef(null);


    const [scrollPosition, setScrollPosition] = useState(0);

    const [isH3Visible, setIsH3Visible] = useState(false);



    const calculateColor = () => {
        const startColor = "#16DFFF"; // Orange color at the start (82 watt)
        const endColor = "#16DFFF"; // Blue color at the end (28 watt)
        const progressPercentage = ((82 - currentWatt) / 54) * 100;
        const red = Math.round(((100 - progressPercentage) / 100) * parseInt(startColor.slice(1, 3), 16) + (progressPercentage / 100) * parseInt(endColor.slice(1, 3), 16));
        const green = Math.round(((100 - progressPercentage) / 100) * parseInt(startColor.slice(3, 5), 16) + (progressPercentage / 100) * parseInt(endColor.slice(3, 5), 16));
        const blue = Math.round(((100 - progressPercentage) / 100) * parseInt(startColor.slice(5, 7), 16) + (progressPercentage / 100) * parseInt(endColor.slice(5, 7), 16));
        return `rgb(${red}, ${green}, ${blue})`;
    };

    const calculateOffset = () => {



        const radius = 80; // Adjust the radius based on the desired circle size
        const circumference = Math.PI * 2 * radius * 1.55; // 89% of the circumference

        const progress = ((currentWatt - 28) / 54) * circumference; // Reverse the calculation
        return circumference - progress;


    };

    const radius = 160;
    const circumference = Math.PI * 2 * radius * 0.89;




    useEffect(() => {

        let timeoutId;

        const handleScroll = () => {

            clearTimeout(timeoutId);
            const rect = progressBarRef?.current?.getBoundingClientRect();
            const isVisible = rect?.top < window.innerHeight && rect?.bottom >= 0;

            if (isVisible) {

                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    const interval = setInterval(() => {
                        setCurrentWatt((prevWatt) => (prevWatt > 28 ? prevWatt - 1 : prevWatt));
                    }, 50);

                    return () => clearInterval(interval);
                }, 600); // Adjust the buffer time as needed

            }
            const scrollPosition = window.scrollY;
            const h3Position = h3RefHome?.current?.offsetTop;
            const offset = window.innerHeight / 2;

            if (scrollPosition + offset >= h3Position) {

                setIsH3Visible(true);

            } else {
                //   setIsH3Visible(false);
            }
            const title2 = h3Ref1Home.current;

            if (title2) {
                const rect2 = title2.getBoundingClientRect();
                const offset2 = window.innerHeight / 2;

                if (rect2.top <= offset2) {
                    title2.classList.add('active');
                } else {
                    // title2.classList.remove('active');
                }
            }
            const title3 = h3Ref2Home.current;

            if (title3) {
                const rect3 = title3.getBoundingClientRect();
                const offset3 = window.innerHeight / 2;

                if (rect3.top <= offset3) {
                    title3.classList.add('active');
                } else {
                    // title2.classList.remove('active');
                }
            }
            setScrollPosition(scrollPosition);


        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {

    }, [scrollPosition]);
    const scrollContainerRef = useRef(null);

    useEffect(() => {

        const scrollContainer = scrollContainerRef?.current;
        const scrollContent = scrollContainer?.querySelector(".scroll-content");

        let scrollPos = 0;
        const scrollSpeed = 1; // Adjust the scrolling speed here

        const scroll = () => {
            scrollPos += scrollSpeed;

            if (scrollPos >= scrollContent?.offsetWidth - scrollContainer?.offsetWidth) {
                scrollPos = 0;
            }
            if (scrollContainer) {
                scrollContainer.scrollLeft = scrollPos;
            }
            requestAnimationFrame(scroll);
        };

        const animationFrameId = requestAnimationFrame(scroll);

        return () => {
            cancelAnimationFrame(animationFrameId);
        };


    }, [showLoader]);


    const videoRef = useRef(null);
    const sideTextRef = useRef(null);
    const [isSideTextVisible, setIsSideTextVisible] = useState(false);
    const [isVideoPlayed, setIsVideoPlayed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const sideTextElement = sideTextRef?.current;
            const rect = sideTextElement?.getBoundingClientRect();
            const isVisible = rect?.top < window.innerHeight && rect?.bottom >= 0;

            setIsSideTextVisible(isVisible);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isSideTextVisible && !isVideoPlayed) {
            setTimeout(() => {
                videoRef.current.play();
                setIsVideoPlayed(true);
            }, 1000)
        } else if (!isSideTextVisible && isVideoPlayed) {
            // videoRef.current.pause();
        }
    }, [isSideTextVisible, isVideoPlayed]);



    const [currentwidth, setCurrentwidth] = useState(window.innerWidth);


    useEffect(() => {
        setCurrentwidth(window.innerWidth);
        const videos = document.querySelectorAll('video');

        const handlePlay = (event) => {
            const currentVideo = event.target;
            videos.forEach((video) => {
                if (video !== currentVideo) {
                    video.pause();
                }
            });
        };

        videos.forEach((video) => {
            video.addEventListener('play', handlePlay);
            video.addEventListener('pause', handlePlay);
        });

        return () => {
            videos.forEach((video) => {
                video.removeEventListener('play', handlePlay);
                video.removeEventListener('pause', handlePlay);
            });
        };
    }, []);


    const videoUrls = [
        "https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/home-helix-3blade.mp4",
        "https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/home-knoxx-3blade.mp4",
        "https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/home-stella-3blade.mp4",
        // "https://www.youtube.com/watch?v=WwHvQ1uVEwI",
        "https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/home-knoxx-4blade.mp4"
    ];




    return (
        showLoader ? <>
            <section className="mt-5 pt-4 loader-align">
                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/loader-gif.gif" alt="loader"></img>
                <p>LOADING COLORBOT...</p>
            </section>
        </>
            :
            <section className="mt-5 pt-4">
                <HomeHead />
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="100000">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current={true} aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item">
                            <LazyLoadImage
                                src={window.innerWidth < 768 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-banner-new1-mob.webp" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-banner-new1.webp"}
                                width={"100vw"}
                                className="d-block w-100 img-fluid"
                                alt="home banner 1"
                                effect="blur"
                            />
                        </div>
                        <div className="carousel-item active">
                            <LazyLoadImage
                                src={window.innerWidth < 768 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-banner-new2-mob.webp" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-banner-new2.webp"}
                                width={"100vw"}
                                className="d-block w-100 img-fluid"
                                alt="home banner 2"
                                effect="blur"
                            />
                        </div>
                        <div className="carousel-item">
                            <LazyLoadImage
                                src={window.innerWidth < 768 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-banner-new3-mob.webp" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-banner-new3.webp"}
                                width={"100vw"}
                                className="d-block w-100 img-fluid"
                                alt="home banner 3"
                                effect="blur"
                            />
                        </div>
                    </div>
                    {/* <NavLink to="/bldc-ceiling-fan">
                        <button className='btn homebtnexplore'>Explore Now</button>
                    </NavLink> */}
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden={true}></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                        className="carousel-control-next"
                        type="button"
                        id="next-slide"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide="next"
                        ref={nextSlideButtonRef}
                    >
                        <span className="carousel-control-next-icon" aria-hidden={true}></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>





                <div className="container-fluid">

                    <div className='row pt-2 homeheight'>



                        <video className='img-fluid hometrans' id='vedioplay' ref={videoRef} muted controls={false} playsInline>
                            <source src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-fan-transparent.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-fan-transparent-mobile.mp4"} type="video/mp4" alt="Transparent motor view of Colorbot Knoxx Ceiling fan" />
                        </video>



                        <div className=" homealign " >
                            <FadeInSection>
                                <div className={`hometext `}>
                                    <h1 className="hometitle">Performance Leveled <span style={{ fontWeight: 600, color: "#16DFFF", marginLeft: "15px", marginRight: "15px", fontSize: "60px" }}>Up</span>  <img src='https://colorbots3.s3.ap-south-1.amazonaws.com/Vector.svg' className='img-fluid' alt='vector' width="25" height="29" />
                                        {/* <video id="arrowup" style={{height:"10vh"}} muted autoPlay loop> 
                                            <source src="https://colorbots3.s3.ap-south-1.amazonaws.com/Knoxxairflow-up/home-up-arrow.mp4" type="video/mp4" />
                                        </video> */}
                                    </h1>
                                    <h2 className="mainsubtitle">Empowered by BLDC technology</h2>
                                    <br />
                                    <br />
                                    <p className="homesubtitle">
                                        <p className='homeleft'>
                                            Every Colorbot BLDC is equipped with the advanced Color-B algorithm for a ceaseless comfort.
                                            The powerful BLDC motors enable smooth and noiseless operation of your fan while ensuring
                                            consistent airflow and adequate air delivery even at fluctuating voltages and on the inverter</p>
                                        <br />
                                        <NavLink to="/technology" className="homemore">Know more.</NavLink>

                                    </p>
                                </div>
                            </FadeInSection>



                        </div>

                        <div className=" homealign1 " id="sidetext" ref={sideTextRef}>
                            <FadeInSection>
                                <div className={`hometext mt-5 pt-5 `}>
                                    <br /><br /><br />
                                    <h2 className="hometitle"><span style={{ color: "#16DFFF", marginRight: "15px", fontSize: "60px", fontWeight: 600 }}>Zero</span> Carbon Footprint  </h2>
                                    <h3 className="mainsubtitle">Sustainable Solution</h3>
                                    <br />
                                    <br />
                                    <p className="homesubtitle">

                                        <p className='homeleft'>
                                            Colorbot is committed to promoting planet-friendly steps towards protecting the environment.
                                            Apart from saving electricity, use of one Colorbot BLDC fan can reduce around 1 Kg of CO2 emission
                                            per year. This makes Colorbot Fans the sustainable choice for your home.
                                        </p>

                                        <br />
                                        <NavLink to="/sustainability" className="homemore">Know more.</NavLink>

                                    </p>
                                </div>
                            </FadeInSection>
                        </div>

                    </div>
                </div>


                {/* // Render a YouTube video player
                <ReactPlayer url='https://colorbots3.s3.ap-south-1.amazonaws.com/Home/Home-video1.mp4' /> */}



                <div className="container-fluid homeprogress">
                    <div className="row pt-5">
                        <div className="col-md-6 loaderalign">
                            <div className="progress-loader" ref={progressBarRef}>
                                <svg className="circle">
                                    <circle className="circle-background" cx="330" cy="180" r={radius} />
                                    <circle
                                        className="circle-progress"
                                        cx="330"
                                        cy="180"
                                        r={radius}
                                        stroke={calculateColor()}
                                        strokeDasharray={`${circumference}`}
                                        strokeDashoffset={calculateOffset()}
                                    />

                                    <circle
                                        className="circle-dot"
                                        cx="150"
                                        cy={radius * 0.25} // Adjust the y-coordinate of the dot based on the desired position
                                        r="5"
                                        fill={calculateColor()}
                                        transform={`rotate(${calculateOffset() / circumference} 150 150)`}
                                    />
                                    <image className="circle-image" xlinkHref="https://colorbots3.s3.ap-south-1.amazonaws.com/progressvector.svg" x="300" y="150" width="50" height="50" alt="A ring structure shows how Colorbot Ceiling fans consume only 28 watts of electricity while normal takes around 80 watts" />

                                    <text className="watt-text" x="350" y="150" fill={calculateColor()}>
                                        {currentWatt}
                                    </text>
                                    <text className="subwatt-text" x="370" y="150" fill={calculateColor()}>
                                        Watts*
                                    </text>
                                </svg>
                            </div>
                        </div>
                        <div className="col-md-6 homealigndark">
                            <FadeInSection>
                                <h2 className="hometitledark">Efficiency Redefined</h2>
                                <h3 className="subtitledark">Endless Breeze. Limitless Power</h3>

                                <br />
                                <p className="homesubtitledark">
                                    <p className='homeleft'>
                                        Colorbot BLDC fans consume only up to 28 watts of electricity at the maximum speed, and even lesser at
                                        lower speeds, saving around 67% of energy. The ultimate efficiency of Colorbot fans lets you experience
                                        a cooler summer even when the fans are used round the clock.
                                    </p>
                                    <br />
                                    <br />
                                    <p style={{ fontSize: "12px", color: "gray" }}><i>*All Colorbot BLDC fans consume ~28 watts at speed 5.</i></p>



                                </p>
                            </FadeInSection>
                        </div>
                    </div>
                </div>

                <div className="container  mb-5" >
                    <div className="row justify-content-center ">
                        <div className="col-lg-8 mt-3 ">
                            <div className="Faqtitle product-margintop ">
                                <h3 className={`border-bottom-animation h3hoverbefore ${isH3Visible ? 'active' : ''}`} ref={h3RefHome}>Best of Colorbot</h3>
                                <br />

                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-4">
                            <div className="Homebox">
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/home-product-stella1.webp"}
                                    width={"100%"}
                                    className=" img-fluid" alt="Image of Colorbot Stella "
                                    effect="blur"
                                />

                                <NavLink to="/colorbot-stella"> <button className="btn homequick">Quick View</button></NavLink>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="Homebox">
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/home-product-stellaquad2.webp"}
                                    width={"100%"}
                                    className=" img-fluid" alt="Image of Colorbot Stella Quad"
                                    effect="blur"
                                />

                                <NavLink to="/colorbot-stella-quad"> <button className="btn homequick">Quick View</button></NavLink>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="Homebox">
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/home-product-knoxx3.webp"}
                                    width={"100%"}
                                    className=" img-fluid" alt="Image of Colorbot Knoxx"
                                    effect="blur"
                                />

                                <NavLink to="/colorbot-knoxx"> <button className="btn homequick">Quick View</button></NavLink>
                            </div>
                        </div>
                    </div>

                </div>
                <br />
                <div className="homebreeze">
                    <div className="Homebanner-txt">
                        <h2>Breeze at your fingertips</h2>
                        <p className='boost'>Boost Mode | Timer | 6-Speed Controls</p>
                        <p>Experience the year-round comfort with reversible mode and the ease of speed control from up to a distance of 20 ft.</p>

                    </div>
                    <div className="Homebanner-img">
                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/homeremote.png"}

                            className=" img-fluid" alt="An image of Colorbot Ceiling fan Remote"
                            effect="blur"
                        />

                    </div>
                </div>
                <div className="container-fluid" style={{ backgroundColor: "#fff" }}>
                    <div className="row justify-content-center" >
                        {/* <div className="col-lg-8 pb-3 ">
                            <div className="Faqtitle">
                                <h3 className="border-bottom-animation " ref={h3Ref1Home}>Style-Up your Space with Colorbot</h3>
                                <br />

                            </div>
                        </div> */}

                        <div className="col-md-12">
                            <div className="scroll-container" ref={scrollContainerRef}>
                                <div className="scrollwidth scroll-content">

                                    <div className="homegrid">
                                        <div className="homegrid1 maingrid">
                                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img1.webp"}

                                                className=" img-fluid" alt="Colorbot Helix Urban Oak Ceiling Fan in room"
                                                effect="blur"
                                            />

                                        </div>
                                        <div className="mainhomegrid">
                                            <div className="subhomegrid">
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img2.webp"}

                                                        className=" img-fluid" alt="Colorbot Stella Cocoa Brown Ceiling Fan in room"
                                                        effect="blur"
                                                    />

                                                </div>
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img3.webp"}

                                                        className=" img-fluid" alt="Colorbot Stella Urban Oak Ceiling Fan in room"
                                                        effect="blur"
                                                    />

                                                </div>
                                            </div>
                                            <div className="subhomegrid">
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img7.webp"}

                                                        className=" img-fluid" alt="Colorbot Knoxx Urban Oak Ceiling Fan in room"
                                                        effect="blur"
                                                    />

                                                </div>
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img5.webp"}

                                                        className=" img-fluid" alt="Colorbot Stella Charcoal Black Ceiling Fan in room"
                                                        effect="blur"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="homegrid">
                                        <div className="mainhomegrid">
                                            <div className="subhomegrid">
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img6.webp"}

                                                        className=" img-fluid" alt="Colorbot Knoxx Midnight Grey Ceiling Fan in room"
                                                        effect="blur"
                                                    />

                                                </div>
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img8.webp"}

                                                        className=" img-fluid" alt="Colorbot Stella Arctic White Ceiling Fan in room"
                                                        effect="blur"
                                                    />

                                                </div>
                                            </div>
                                            <div className="subhomegrid">
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img4.webp"}

                                                        className=" img-fluid" alt="Colorbot Knoxx Urban Oak Ceiling Fan in room"
                                                        effect="blur"
                                                    />

                                                </div>
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img9.webp"}

                                                        className=" img-fluid" alt="Colorbot Stella Urban Oak Ceiling Fan in room"
                                                        effect="blur"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="homegrid">
                                        <div className="homegrid1 maingrid">
                                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img1.png"}

                                                className=" img-fluid" alt="..."
                                                effect="blur"
                                            />

                                        </div>
                                        <div className="mainhomegrid">
                                            <div className="subhomegrid">
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img2.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img3.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                            </div>
                                            <div className="subhomegrid">
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img7.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img5.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="homegrid">
                                        <div className="mainhomegrid">
                                            <div className="subhomegrid">
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img8.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img5.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                            </div>
                                            <div className="subhomegrid">
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img4.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img9.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="homegrid">
                                        <div className="homegrid1 maingrid">
                                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img1.png"}

                                                className=" img-fluid" alt="..."
                                                effect="blur"
                                            />

                                        </div>
                                        <div className="mainhomegrid">
                                            <div className="subhomegrid">
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img2.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img3.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                            </div>
                                            <div className="subhomegrid">
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img7.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img5.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="homegrid">
                                        <div className="mainhomegrid">
                                            <div className="subhomegrid">
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img3.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img8.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                            </div>
                                            <div className="subhomegrid">
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img4.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                                <div className="homegrid1">
                                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Home-room-img9.png"}

                                                        className=" img-fluid" alt="..."
                                                        effect="blur"
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>





                        </div>
                    </div>







                </div>


                <div className="container-fluid mt-2">
                    <div className="row justify-content-center " >
                        <div className="col-lg-8 pb-3 ">
                            <div className="Faqtitle home-slider">
                                <h3 className="border-bottom-animation h3hoverbefore" ref={h3Ref2Home}>Creating a Difference for Everyone</h3>
                                <br />

                            </div>
                        </div>
                        <br />
                        <div className="col-md-12 pb-5 pt-3">

                            <VideoSlider videoUrls={videoUrls} />



                        </div>
                    </div>
                </div>





            </section>

    );
}
export default Home;