import { useEffect, useState } from "react";
import "./Buynowlogin.css"
import { NavLink, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import ProductQuantitySelect from "../Shared/QuantityModule";
function Buynowlogin(props) {
    const [Subtotal, setSubtotal] = useState(0);
    const [otpsend, setotpsend] = useState(false)
    const [number, setNumber] = useState("")
    const [otp, setOtp] = useState("")
    const [time, settime] = useState(30)
    const [error, setError] = useState('');
    const [error1, setError1] = useState('');
    const navigate = useNavigate()
    const [shippingPrice, setShippingPrice] = useState(0)
    const [extendedwarranty, setextendedwarranty] = useState(0)
    const [totalextendedwarranty, settotalextendedwarranty] = useState(0)
    useEffect(() => {


        var sum = 0;
        for (let i = 0; i < props.cartdata.length; i++) {
            sum = sum + Number(props.cartdata[i].productdiscountedprice)
        }
        if (props.cartdata.length === 0) {
            localStorage.removeItem("activeaddress")
        }
        var extendedwarrantysum = 0
        var totalextendedwarrantysum = 0
        var shippingtotalprice = 0
        for (let i = 0; i < props.cartdata.length; i++) {
            if (props.cartdata[i].extendedwarranty === true) {
                sum = sum + 0
                extendedwarrantysum = (extendedwarrantysum + 199) * Number(props.cartdata[i].productquantity)
                totalextendedwarrantysum = (totalextendedwarrantysum + 499) * Number(props.cartdata[i].productquantity)
                shippingtotalprice = (shippingtotalprice + 100) * Number(props.cartdata[i].productquantity)


            }

        }
        setShippingPrice(shippingtotalprice)
        settotalextendedwarranty(totalextendedwarrantysum)
        const coupandata = coupanlist.filter((data) => {
            return data.coupon_code === coupancode.toUpperCase()
        })

        if (coupandata.length > 0) {
            if (coupandata[0].discount_type === "Rs.") {
                if (sum < Number(coupandata[0].min_cart_value)) {
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setCoupanActive(false)
                }
            } else {
                if (sum < Number(coupandata[0].min_cart_value)) {
                    setcoupancode("");
                    setcoupanprice(0);
                    window.localStorage.removeItem("coupanprice");
                    window.localStorage.removeItem("coupancode");
                    setCoupanActive(false)
                }
            }
        }
        setSubtotal(sum)

        setextendedwarranty(extendedwarrantysum)
        if (window.localStorage.getItem("phoneNumber")) {
            setNumber(Number(window.localStorage.getItem("phoneNumber").slice(3, 13)))
        }
        if (props.cartdata.length === 0) {
            navigate('/')
        }

    }, [props, navigate])
    useEffect(() => {

        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })


    }, [])

    useEffect(() => {
        document.title = "Buy Now Login - Colorbot";// Set the title when component mounts
        return () => {
            document.title = "Buy Now Login - Colorbot"; // Reset the title when component unmounts
        };
    }, []);
    const handleChange = (event) => {
        let enteredPhoneNumber = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (enteredPhoneNumber.length > 10) {
            enteredPhoneNumber = enteredPhoneNumber.slice(0, 10); // Trim to maximum 10 digits
        }
        const isValid = /^\d{10}$/.test(enteredPhoneNumber);

        setNumber(enteredPhoneNumber);
        setotpsend(false)
        setError(isValid ? '' : 'Please enter a valid 10-digit phone number');
    };
    async function Login() {
        if (error1 || otp.length < 6) {
            toast.error("Please enter a valid 6-digit OTP"); // Display an error toast notification
        } else {

            try {
                await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotUserotpverify`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ number: window.localStorage.getItem("phoneNumber"), otp: otp }),
                }).then((res) => res.json()).then((response) => {


                    if (response.status === 200) {

                        window.localStorage.setItem('token', response.data.token);
                        window.localStorage.setItem('UserId', response.data.UserId);
                        if (response.data.Email !== undefined) { window.localStorage.setItem("Email", response.data.Email) }
                        if (response.data.FirstName !== undefined) { window.localStorage.setItem("FirstName", response.data.FirstName) }
                        if (response.data.LastName !== undefined) { window.localStorage.setItem("LastName", response.data.LastName) }
                        if (response.data.Address !== undefined) { window.localStorage.setItem("Address", JSON.stringify(response.data.Address)) }


                        window.localStorage.setItem('LoggedIn', true)
                        props.setLoggedIn(true)
                        navigate('/product_buy_now_delivery')
                        toast.success(response.message);
                    } else {

                        toast.error(response.message);
                    }
                })
            } catch (error) {
                console.error('API Error:', error);
                toast.error(error);
            }
        }


    }
    useEffect(() => {
        var interval;
        if (time > 0) {
            interval = setInterval(() => {
                settime(time - 1)
            }
                , 1000)
        }
        else {
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [time])
    const handleSubmit = async () => {

        if (error || number.length < 10) {
            toast.error("Please enter a valid 10-digit phone number"); // Display an error toast notification
        } else {

            try {
                const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotUserotprequest`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ number: "+91" + number }),
                });

                if (response.status === 200) {
                    window.localStorage.setItem('phoneNumber', "+91" + number);
                    setotpsend(true)
                    settime(30)
                    toast.success('OTP Send Successfully');
                } else {
                    toast.error('Failed to Send OTP');
                }
            } catch (error) {
                console.error('API Error:', error);
                toast.error('An error occurred while making the API call');
            }
        }
    };


    const removeItem = (productId) => {
        props.setcartdata(prevData => {
            const updatedData = prevData.filter(item => item.productid !== productId);
            window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
            return updatedData;
        });

        if (props.cartdata.length === 1) {
            localStorage.removeItem("activeaddress")
            navigate('/bldc-ceiling-fan');
        }
    };
    const resend = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotUserotprequest`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ number: window.localStorage.getItem('phoneNumber') }),
            });

            if (response.status === 200) {


                toast.success('OTP Resend successfully');
                settime(30)
                setOtp("")
            } else {
                toast.error('Failed to resend OTP');
            }
        } catch (error) {
            console.error('API Error:', error);
            toast.error('An error occurred while making the API call');
        }
    }
    const handleChangeOtp = (event) => {
        let enteredPhoneNumber = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (enteredPhoneNumber.length > 6) {
            enteredPhoneNumber = enteredPhoneNumber.slice(0, 6); // Trim to maximum 6 digits
        }
        const isValid = /^\d{6}$/.test(enteredPhoneNumber);
        setOtp(enteredPhoneNumber);
        setError1(isValid ? '' : 'Please enter a valid 6-digit OTP');
    }
    const handleChangeQuantity = (productId, newQuantity) => {
        if (newQuantity === "" || newQuantity === 0) {
            toast.error("Please enter a valid Quantity"); // Display an error toast notification
            props.setcartdata(prevData => {
                const updatedData = prevData.map(item => {
                    if (item.productid === productId) {
                        return { ...item, productquantity: newQuantity, productprice: item.fixedproductprice * newQuantity, productdiscountedprice: item.fixedproductdiscountedprice * newQuantity };
                    }
                    return item;
                });

                window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            });
        } else {
            props.setcartdata(prevData => {
                const updatedData = prevData.map(item => {
                    if (item.productid === productId) {
                        return { ...item, productquantity: newQuantity, productprice: item.fixedproductprice * newQuantity, productdiscountedprice: item.fixedproductdiscountedprice * newQuantity };
                    }
                    return item;
                });

                window.localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            });
        }
    };
    const [showMore, setShowMore] = useState(false);
    const [showarr, setShowArr] = useState([0]);

    const toggleShowMore = () => {
        setShowMore(!showMore);
        if (showMore) {
            setShowArr([0]);
        } else {
            setShowArr(props.cartdata.map((_, index) => index));
        }
    };
    const [lgShow1, setLgShow1] = useState(false);
    const [addressdata, setaddressdata] = useState([])
    const [activeaddress, setactiveaddress] = useState(0)
    const [coupanlist, setcoupanlist] = useState([])
    const [coupancode, setcoupancode] = useState("")
    const [coupanprice, setcoupanprice] = useState(0)

    const getCoupan = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetAllCoupan`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                const data = await response.json();
                var coupanlist = []
                for (let i = 0; i < data.data.length; i++) {
                    if (moment().format("YYYY-MM-DD") < data.data[i].end_date && moment().format("YYYY-MM-DD") > data.data[i].start_date) {
                        coupanlist.push(data.data[i])
                    }
                }
                setcoupanlist(coupanlist)

            }
        } catch (error) {
            console.error('API Error:', error);

        }
    }
    const applyCoupan = async (code) => {
        if (code === "") {
            const data = coupanlist.filter((data) => {
                return data.coupon_code === coupancode.toUpperCase()
            })
            if (data.length > 0) {
                if (data[0].discount_type === "Rs.") {
                    if (Subtotal >= data[0].min_cart_value) {
                        setcoupanprice(data[0].discount)
                        window.localStorage.setItem("coupanprice", parseInt(data[0].discount))
                        window.localStorage.setItem("coupancode", data[0].coupon_code)
                        setLgShow1(false)
                        setCoupanActive(true)
                    }
                    else {
                        toast.error("Minimum cart value should be " + data[0].min_cart_value)
                        setcoupancode("")
                    }
                } else {
                    if (Subtotal >= data[0].min_cart_value) {
                        setcoupanprice((Subtotal * data[0].discount) / 100)
                        window.localStorage.setItem("coupanprice", ((Subtotal * parseInt(data[0].discount)) / 100))
                        window.localStorage.setItem("coupancode", data[0].coupon_code)
                        setLgShow1(false)
                    }
                    else {
                        toast.error("Minimum cart value should be " + data[0].min_cart_value)
                        setcoupancode("")
                    }
                }
            } else {
                toast.error("Please enter a valid coupan code")
            }

        } else {
            const data = coupanlist.filter((data) => {
                return data.coupon_code === code
            })
            if (data.length > 0) {
                if (data[0].discount_type === "Rs.") {
                    if (Subtotal >= data[0].min_cart_value) {
                        setcoupanprice(parseInt(data[0].discount))
                        window.localStorage.setItem("coupanprice", parseInt(data[0].discount))
                        window.localStorage.setItem("coupancode", data[0].coupon_code)
                        setLgShow1(false)
                    }
                    else {
                        toast.error("Minimum cart value should be " + data[0].min_cart_value)
                        setcoupancode("")
                    }
                } else {
                    if (Subtotal >= data[0].min_cart_value) {
                        setcoupanprice((Subtotal * data[0].discount) / 100)
                        window.localStorage.setItem("coupanprice", ((Subtotal * parseInt(data[0].discount)) / 100))
                        window.localStorage.setItem("coupancode", data[0].coupon_code)
                        setLgShow1(false)
                    }
                    else {
                        toast.error("Minimum cart value should be " + data[0].min_cart_value)
                        setcoupancode("")
                    }
                }
            } else {
                toast.error("Please enter a valid coupan code")
            }

        }
    }
    const [coupanActive, setCoupanActive] = useState(false)
    const [loginCheck,setLoginCheck] = useState(true)
    useEffect(() => {
        if (window.localStorage.getItem("coupanprice")) {
            setcoupanprice(parseInt(window.localStorage.getItem("coupanprice")))
        }
        if (window.localStorage.getItem("coupancode")) {
            setcoupancode(window.localStorage.getItem("coupancode"))
            setCoupanActive(true)
        } else {

            setCoupanActive(false)
        }
        getCoupan()
    }, [lgShow1])

    const navigateToProduct = (path) => {
        navigate(path);
    }
    useEffect(() => {
        if (JSON.parse(window.localStorage.getItem("cartdata")).length === 0) {
            navigate("/bldc-ceiling-fan")
        }
        if (window.localStorage.getItem("Address")) {

            setaddressdata(JSON.parse(window.localStorage.getItem("Address")))
        }
        if (window.localStorage.getItem("activeaddress")) {
            setactiveaddress(window.localStorage.getItem("activeaddress"))
        }
    }, [])

    return (
        <section className="mt-5 pt-5 pb-5 buynowbackground">
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"

                centered
                show={lgShow1}

                onHide={() => setLgShow1(false)}

            >
                <Modal.Header closeButton style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>
                    <span className="coupantitle">COUPONS</span>
                </Modal.Header>
                <Modal.Body style={{ paddingTop: "0rem" }}>


                    <div className="box-profile-pop-profile">





                        <div className="coupaninput p-3 pt-4">
                            <input type="text" className="form-control" id="exampleInputcoupan1" placeholder="Coupon" style={{
                                textTransform: "uppercase"
                            }} value={coupancode} onChange={(e) => {
                                setcoupancode(e.target.value)

                            }} />
                            {coupanActive ? <button className="btn btncoupan" style={{
                                color: "white",
                                backgroundColor: "#CB0000"
                            }} onClick={() => {
                                setcoupancode("");
                                setcoupanprice(0);
                                window.localStorage.removeItem("coupanprice");
                                window.localStorage.removeItem("coupancode");
                                setLgShow1(false);
                                setCoupanActive(false)
                            }}>Remove</button> :
                                <button className="btn btncoupan" onClick={() => {
                                    applyCoupan("")
                                }}>APPLY</button>}

                        </div>
                        <div className="coupancodebody">
                            {coupanlist.length > 0 && coupanlist.map((data, index) => {
                                return (
                                    <div className="coupanbody" key={index}>


                                        <div>

                                            <p className="coupancode">{data.coupon_code}</p>
                                            <p className="coupandescription">{data.coupon_description}</p>
                                        </div>
                                        <div className="coupanapply">
                                            <button className="btn btncoupanapply" onClick={() => {
                                                setcoupancode(data.coupon_code)
                                                applyCoupan(data.coupon_code)
                                            }}>APPLY</button>
                                        </div>


                                    </div>
                                )
                            })
                            }

                        </div>









                    </div>


                </Modal.Body>
            </Modal>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 col-lg-8">
                        <div className="logincard">
                            {window.innerWidth < 780 ? <div className="productcard">
                                {props.cartdata.map((data, index) => {
                                    const isVisible = showarr.includes(index);
                                    return (
                                        <div className={isVisible ? "productbuynowcard" : "productbuynowcard hidden"} key={index}>
                                            <img src={data.productimage} className="img-fluid" alt={index} style={{
                                                            cursor: "pointer"
                                                        }} onClick={() => {
                                                            navigateToProduct(data.productpath)
                                                        }}/>
                                            <div className="productbuynowcardbody">
                                                <div className="cartproducttitle">{data.productname}</div>
                                                {/* <div className="cartproductcolor"><span style={{ color: "#8A8A8A" }}>Color:</span> {data.productcolor}</div> */}
                                                <div className="fan-specs">
                                                    <div className="cartproductcolor">{data.productcolor}</div>
                                                    <div className="cartproductcolor productsweep">{data.productsweepsize}</div>
                                                </div>
                                                <div className="cartproductquantity">
                                                    <div className="cartproductquantitytitle"></div>
                                                    &nbsp;
                                                    <ProductQuantitySelect initialQuantity={data.productquantity} handleChangeQuantity={handleChangeQuantity} id={data.productid}/>
                                                    {/* <input id={`quantity${index}`}
                                                        type="number"
                                                        className="helixselect cartproductquantityselect" style={{ padding: "2px 2px", marginTop: "0px" }}
                                                        value={data.productquantity}
                                                        onChange={(e) => {
                                                            const inputValue = parseInt(e.target.value, 10);

                                                            if (inputValue < 0 || inputValue > 99) {
                                                                // If the input is invalid, you can choose to handle it here.
                                                                // For example, you might want to show an error message or prevent further processing.
                                                                return;
                                                            }
                                                            handleChangeQuantity(data.productid, e.target.value)

                                                        }}
                                                    /> */}

                                                    <div className="cartproductremove" onClick={() => {
                                                        removeItem(data.productid)
                                                    }}>Remove</div>

                                                    <div className="cartproductprice">Rs.{data.productdiscountedprice}</div>


                                                </div>
                                                {parseInt(data.productquantity) > parseInt(data.inventory) ? <p className="text-danger" style={{ fontSize: "13px" }}>Out of stock</p> : ""}

                                            </div>

                                        </div>
                                    );
                                })}
                                {props.cartdata.length > 1 ? <div className="productshowmore" onClick={toggleShowMore}>
                                    {showMore ? "Show Less" : "Show More"} &nbsp; &nbsp;
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/downarrowbuy.svg" className={showMore ? "img-fluid rotate" : "img-fluid"} alt="icon" />
                                </div> : ""}
                            </div> : ""}
                            <div className="logincardhead">
                                <span>1</span> &nbsp;&nbsp;&nbsp;
                                <h2>Login/Signup</h2>
                            </div>
                            <div className="logincardbody">
                                <div className="row">
                                    <div className="col-md-12 col-lg-6">
                                        <div className="form-signin">
                                            <input type="text" className="form-control form-alignment" id="exampleInputnumber1" autoComplete="off" value={number} onChange={(e) => handleChange(e)}
                                                placeholder="Phone Number" />
                                            {otpsend ? <input type="text" className="form-control form-alignment" id="exampleInputotp1" autoComplete="off" value={otp} onChange={(e) => {
                                                handleChangeOtp(e)
                                            }}
                                                placeholder="Enter OTP" /> : null}

                                            {/* <input type="password" className="form-control form-alignment" id="exampleInputPassword1"
                                        placeholder="Password" /> */}
                                        </div>
                                        {otpsend ? <div className="righhtty-text text-right">
                                            {time > 0 ? `Resend OTP in ${time} sec` : <span style={{ cursor: "pointer" }} onClick={() => {
                                                resend()
                                            }}>Resend</span>}
                                        </div> : null}



                                        <div>

                                            {otpsend ? <button className="btn  button-signin" type="button" onClick={() => {
                                                Login()
                                            }}>Continue</button> : <button className="btn  button-signin" type="button" onClick={() => {
                                                handleSubmit()
                                            }}>Request OTP</button>}
                                        </div>
                                        <br />
                                        <div className="righhtty-text text-left">
                                            By continuing, you agree to Colorbot's <NavLink to="/privacy-policy" >Privacy Policy</NavLink> and <NavLink to="/terms-conditions" >Terms and Conditions. </NavLink>

                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-6">
                                        <br />
                                        <h2 className="loginheadtext login-check"><input className="form-check-input " type="checkbox" checked={loginCheck}  onChange={()=>{
                                            setLoginCheck(!loginCheck)
                                        }} style={{ marginTop: "-0.01rem" }} id={`flexCheckDefaultlogin`}  /> &nbsp; By clicking this you allow communication from Colorbot Pvt. Ltd.</h2>
                                        <br />


                                        <h2 className="loginheadtext"> By Logging in with your Contact Number</h2>
                                        <h2 className="loginheadtext"><img src="assets/coupanbuy.png" className="img-fluid" alt="coupan" width={"24px"} /> &nbsp; &nbsp; Be first to get Coupons and Discounts.</h2>
                                        <h2 className="loginheadtext"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/trackbuy.svg" className="img-fluid" alt="track" /> &nbsp; &nbsp; Track Orders Easily</h2>
                                        <h2 className="loginheadtext"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/alertbuy.svg" className="img-fluid" alt="alert" /> &nbsp; &nbsp; Get relevant alerts</h2>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="logincardhead cardheadactive">
                                <span>2</span> &nbsp;&nbsp;&nbsp;
                                <h2>Billing/Delivery Address</h2>
                            </div>
                            <br />

                            <div className="logincardhead cardheadactive">
                                <span>3</span> &nbsp;&nbsp;&nbsp;
                                <h2>Detailed Order Summary</h2>
                            </div>

                            {window.innerWidth < 780 ? <div className="coupanproductcard">
                                <div className="coupaninput">
                                    <input type="text" className="form-control" id="exampleInputcoupan1" onClick={() => {
                                        setLgShow1(true)
                                    }} placeholder="Coupon" style={{
                                        textTransform: "uppercase"
                                    }} value={coupancode} readOnly />
                                    <button className="btn btncoupan" onClick={() => {
                                        setLgShow1(true)
                                    }}>VIEW</button>
                                </div>

                                <div className="coupanproductcardbody" style={{ borderTop: "2px solid #DDDDDD", marginTop: "1rem" }}>
                                    <span className="productcardbodytitle">Subtotal</span>
                                    <span className="productcardbodydata">Rs. {(Subtotal / 1.18).toFixed(2)}</span>
                                </div>
                                {shippingPrice > 0 ? <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Shipping</span>
                                    <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {shippingPrice}</span></span>
                                </div> : ""}
                                {extendedwarranty > 0 ? <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Extended Warranty</span>
                                    <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {totalextendedwarranty}</span></span>
                                </div> : ""}

                                {coupanprice > 0 ? <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Coupon Discount</span>
                                    <span className="productcardbodyshipping"><span className="productshipping">-Rs. {coupanprice}</span></span>
                                </div> : ""}
                                {addressdata?.[activeaddress]?.State === "Uttar Pradesh" ? <div className="coupanproductcardbody">

                                    <span className="productcardbodytitle">Tax (CGST 9%, SGST 9%)</span>
                                    <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                                </div> : <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle">Tax (IGST 18%)</span>
                                    <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                                </div>}

                                <div className="coupanproductcardbody">
                                    <span className="productcardbodytitle productamount" style={{ color: "#242933", }}>Amount Payable <small> (incl. tax)</small> </span>
                                    <span className="productcardbodyshipping"><span className="productgrandtotal productamountprice ">Rs. {Subtotal - coupanprice}</span></span>
                                </div>

                            </div> : ""}

                            <div className="logincardhead cardheadactive mt-3">
                                <span>4</span> &nbsp;&nbsp;&nbsp;
                                <h2>Payment Options</h2>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4 ">
                        {window.innerWidth > 780 ? <div className="productcard">
                            {props.cartdata.map((data, index) => {
                                const isVisible = showarr.includes(index);
                                return (
                                    <div className={isVisible ? "productbuynowcard" : "productbuynowcard hidden"} key={index}>
                                        <img src={data.productimage} className="img-fluid" alt={index} style={{
                                                            cursor: "pointer"
                                                        }} onClick={() => {
                                                            navigateToProduct(data.productpath)
                                                        }}/>
                                        <div className="productbuynowcardbody">
                                            <div className="cartproducttitle">{data.productname}</div>
                                            {/* <div className="cartproductcolor"><span style={{ color: "#8A8A8A" }}>Color:</span> {data.productcolor}</div> */}
                                            <div className="fan-specs">
                                                <div className="cartproductcolor">{data.productcolor}</div>
                                                <div className="cartproductcolor productsweep">{data.productsweepsize}</div>
                                            </div>
                                            <div className="cartproductquantity">
                                                <div className="cartproductquantitytitle"></div>
                                                &nbsp;
                                                <ProductQuantitySelect initialQuantity={data.productquantity} handleChangeQuantity={handleChangeQuantity} id={data.productid}/>
                                                {/* <input id={`quantity${index}`}
                                                    type="number"
                                                    className="helixselect cartproductquantityselect" style={{ padding: "2px 2px", marginTop: "0px" }}
                                                    value={data.productquantity}
                                                    onChange={(e) => {
                                                        const inputValue = parseInt(e.target.value, 10);

                                                        if (inputValue < 0 || inputValue > 99) {
                                                            // If the input is invalid, you can choose to handle it here.
                                                            // For example, you might want to show an error message or prevent further processing.
                                                            return;
                                                        }
                                                        handleChangeQuantity(data.productid, e.target.value)

                                                    }}
                                                /> */}

                                                <div className="cartproductremove" onClick={() => {
                                                    removeItem(data.productid)
                                                }}>Remove</div>

                                                <div className="cartproductprice">Rs.{data.productdiscountedprice}</div>


                                            </div>
                                            {parseInt(data.productquantity) > parseInt(data.inventory) ? <p className="text-danger" style={{ fontSize: "13px" }}>Out of stock</p> : ""}

                                        </div>

                                    </div>
                                );
                            })}
                            {props.cartdata.length > 1 ? <div className="productshowmore" onClick={toggleShowMore}>
                                {showMore ? "Show Less" : "Show More"} &nbsp; &nbsp;
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/downarrowbuy.svg" className={showMore ? "img-fluid rotate" : "img-fluid"} alt="icon" />
                            </div> : ""}
                        </div> : ""}

                        {window.innerWidth > 780 ? <div className="coupanproductcard">
                            <div className="coupaninput">
                                <input type="text" className="form-control" id="exampleInputcoupan1" placeholder="Coupon" onClick={() => {
                                    setLgShow1(true)
                                }} style={{
                                    textTransform: "uppercase"
                                }} value={coupancode} readOnly />
                                <button className="btn btncoupan" onClick={() => {
                                    setLgShow1(true)
                                }}>VIEW</button>
                            </div>

                            <div className="coupanproductcardbody" style={{ borderTop: "2px solid #DDDDDD", marginTop: "1rem" }}>
                                <span className="productcardbodytitle">Subtotal</span>
                                <span className="productcardbodydata">Rs. {(Subtotal / 1.18).toFixed(2)}</span>
                            </div>
                            {shippingPrice > 0 ? <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Shipping</span>
                                <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {shippingPrice}</span></span>
                            </div> : ""}
                            {extendedwarranty > 0 ? <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Extended Warranty</span>
                                <span className="productcardbodyshipping"><span className="productshipping">Free</span><span style={{ textDecoration: "line-through" }}>Rs. {totalextendedwarranty}</span></span>
                            </div> : ""}

                            {coupanprice > 0 ? <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Coupon Discount</span>
                                <span className="productcardbodyshipping"><span className="productshipping">-Rs. {coupanprice}</span></span>
                            </div> : ""}
                            {addressdata?.[activeaddress]?.State === "Uttar Pradesh" ? <div className="coupanproductcardbody">

                                <span className="productcardbodytitle">Tax (CGST 9%, SGST 9%)</span>
                                <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                            </div> : <div className="coupanproductcardbody">
                                <span className="productcardbodytitle">Tax (IGST 18%)</span>
                                <span className="productcardbodydata">Rs. {(Subtotal - (Subtotal / 1.18)).toFixed(2)}</span>
                            </div>}

                            <div className="coupanproductcardbody">
                                <span className="productcardbodytitle productamount" style={{ color: "#242933", }}>Amount Payable <small> (incl. tax)</small> </span>
                                <span className="productcardbodyshipping"><span className="productgrandtotal productamountprice ">Rs. {Subtotal - coupanprice}</span></span>
                            </div>

                        </div> : ""}

                        <div className="productsafety mt-3">
                            <div className="productsafetydata">
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/secure.svg" className="img-fluid" alt="secure" />
                                <div className="productsafetydatatext">
                                    Secure <p>Payment</p>
                                </div>
                            </div>
                            <div className="productsafetydata">
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/guaranted.svg" className="img-fluid" alt="guaranted" />
                                <div className="productsafetydatatext">
                                    Satisfaction<p> Guaranteed</p>
                                </div>
                            </div>
                            <div className="productsafetydata">
                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/privacybuy.svg" className="img-fluid" alt="privacy" />
                                <div className="productsafetydatatext">
                                    Your privacy <p>is priority</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    );
};
export default Buynowlogin;