import React, { useRef, useEffect, useState } from 'react';
import Slider from "react-slick";
import "./About.css";
import FadeInSection from "../FadeInup/FadeInup";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';


const AboutHead = () => (

    <Helmet>
        <title>About Us</title>
        <meta name="description" content="Colorbot with its eco-friendly approach, offers energy-efficient and stylish BLDC fans that are integrated with innovative technology and advanced features." />
        <link rel="canonical" href="https://colorbot.in/about" />

        <meta property="og:title" content="About Us" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/about" />
        <meta property="og:description" content="Colorbot with its eco-friendly approach, offers energy-efficient and stylish BLDC fans that are integrated with innovative technology and advanced features." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/About-Us/about_banner.png" />

    </Helmet>
);

function throttle(func, limit) {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        if (!timeout) {
            func.apply(context, args);
            timeout = setTimeout(() => {
                timeout = null;
            }, limit);
        }
    };
}
function About() {
    const h3RefAbout = useRef(null);
    const h3RefAbout1 = useRef(null);
    const h3RefAbout2 = useRef(null);
    const h3RefAbout3 = useRef(null);
    const [isH3Visible, setIsH3Visible] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = "About Us - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "About Us - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);



    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const h3Position = h3RefAbout.current.offsetTop;
            const offset = window.innerHeight / 2;

            if (scrollPosition + offset >= h3Position) {
                setIsH3Visible(true);
                setScrollPosition(scrollPosition);
            } else {
                setIsH3Visible(false);
                setScrollPosition(scrollPosition);
            }
        };

        const throttledScrollHandler = throttle(handleScroll, 200); // Throttle scroll event
        window.addEventListener('scroll', throttledScrollHandler);

        return () => {
            window.removeEventListener('scroll', throttledScrollHandler);
        };
    }, []);



    useEffect(() => {
        // const title = h3RefAbout.current;

        // if (title) {
        //     const rect = title.getBoundingClientRect();
        //     const offset = window.innerHeight / 2;

        //     if (rect.top <= offset) {
        //         title.classList.add('active');
        //     } else {
        //         // title.classList.remove('active');
        //     }
        // }
        const title1 = h3RefAbout1.current;

        if (title1) {
            const rect1 = title1.getBoundingClientRect();
            const offset1 = window.innerHeight / 2;

            if (rect1.top <= offset1) {
                title1.classList.add('active');
            } else {
                // title1.classList.remove('active');
            }
        }
        const title2 = h3RefAbout2.current;

        if (title2) {
            const rect2 = title2.getBoundingClientRect();
            const offset2 = window.innerHeight / 2;

            if (rect2.top <= offset2) {
                title2.classList.add('active');
            } else {
                // title2.classList.remove('active');
            }
        }
        const title3 = h3RefAbout3.current;

        if (title3) {
            const rect3 = title3.getBoundingClientRect();
            const offset3 = window.innerHeight / 2;

            if (rect3.top <= offset3) {
                title3.classList.add('active');
            } else {
                // title3.classList.remove('active');
            }
        }
    }, [scrollPosition]);



    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>

            <section className='mt-5 pt-4'>
                <AboutHead />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 padd-neg">
                            <div className="banner-img">
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/About-Us/about_banner.png"}
                                    width={"100vw"}
                                    className="d-block w-100 img-fluid" alt="..."
                                    effect="blur"
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <FadeInSection>
                    <div className="row">
                        <div className="col-md-12 txt-sec about-sec ">




                            <h2 className={`border-bottom-animation about-animation-bot creating-about ${isH3Visible ? 'active' : ''}`} ref={h3RefAbout}>Creating a Change and Making a Difference</h2>

                        </div>
                    </div>
                </FadeInSection>
            </div>
            <FadeInSection>
                <Slider {...settings} className='aboutSlider'>
                    <div>
                        <LazyLoadImage
                            src={"https://colorbots3.s3.ap-south-1.amazonaws.com/About-Us/about-slider1-min.jpg"}
                            width={"100%"}
                            className="img-fluid"
                            alt="..."
                            effect="blur"
                        />
                    </div>
                    <div>
                        <LazyLoadImage
                            src={"https://colorbots3.s3.ap-south-1.amazonaws.com/About-Us/about-slider2-min.jpg"}
                            width={"100%"}
                            className="img-fluid"
                            alt="..."
                            effect="blur"
                        />
                    </div>
                    <div>
                        <LazyLoadImage
                            src={"https://colorbots3.s3.ap-south-1.amazonaws.com/About-Us/about-slider3-min.jpg"}
                            width={"100%"}
                            className="img-fluid"
                            alt="..."
                            effect="blur"
                        />
                    </div>
                    <div>
                        <LazyLoadImage
                            src={"https://colorbots3.s3.ap-south-1.amazonaws.com/About-Us/about-slider4-min.jpg"}
                            width={"100%"}
                            className="img-fluid"
                            alt="..."
                            effect="blur"
                        />
                    </div>
                    <div>
                        <LazyLoadImage
                            src={"https://colorbots3.s3.ap-south-1.amazonaws.com/About-Us/about-slider2-min.jpg"}
                            width={"100%"}
                            className="img-fluid"
                            alt="..."
                            effect="blur"
                        />
                    </div>
                </Slider>
            </FadeInSection>



            <FadeInSection>
                <section>
                    <div className="container">

                        <div className="row  ">
                            <div className="col-lg-6 col-md-6 leftside1-text mt-3">

                                <h2 className="border-bottom-animation1 think-about " ref={h3RefAbout1}>Thinking a Step Ahead</h2>

                                <p className='mt-1'>Colorbot thinks beyond business, it thinks of the future. With a vision of delivering fresh
                                    air to every corner, we are reshaping the future of ceiling fans through a revolutionary
                                    technology. Sincerity is our strategy and quality is our forte. Our mission is to renovate
                                    comfort with a global impact. With this approach, we are moving forward to create a change
                                    you need and we hope that you will be a part of our journey!
                                </p>

                            </div>

                            <div className="col-lg-6 col-md-6 rightside1-image mt-3">
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/About-Us/about-thinking-min.jpg"}
                                    width={"100%"}
                                    className="img-fluid" alt="..."
                                    effect="blur"
                                />


                            </div>
                        </div>
                    </div>
                </section>
            </FadeInSection>


            <FadeInSection>
                <section>
                    <div className="container">
                        <div className="row alignmiddle">
                            <div className="col-lg-6 col-md-6 about-chip">
                                <div className="leftt-picture mt-3">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/About-Us/about-chip-min.jpg"}
                                        width={"100%"}
                                        className="img-fluid" alt="..."
                                        effect="blur"
                                    />

                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 rightt-text mt-3">

                                <h2 className="border-bottom-animation " ref={h3RefAbout2}>Why Colorbot?</h2>
                                <p className='mt-1'>At Colorbot, we recognize the need for better living conditions in the world. To suffice these needs, we are offering energy efficient ceiling fans with a modern and stylish design that will be the focal point of your room while providing better comfort.
                                    With our product and services, we provide you with an ease of living while boosting up your everyday life with freedom of efficiency.</p>

                            </div>
                        </div>
                    </div>
                </section>
            </FadeInSection>

            <FadeInSection>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 txt-sec about-envo">
                                <h2 className="border-bottom-animation " ref={h3RefAbout3}>Empowering Sustainability</h2>
                                <p className='mt-1'>With our products, we are delivering the change; furnishing you with a smart and sustainable
                                    way to improve your indoor climate. We believe that you are not just Colorbot's buyer, but
                                    also its partner in building a greener world with us. The solution of a better world starts
                                    with a small step. In fact, it starts with you, here and now - Colorbot.</p>
                            </div>

                        </div>

                        <div className="grid  mb-5">

                            <div className="grid-item">
                                <div className="emp-img-1 ">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/About-Us/about-sustain1-min.jpg"}
                                        width={"100%"}
                                        className="img-fluid" alt="..."
                                        effect="blur"
                                    />

                                </div>
                            </div>


                            <div className="grid-item">
                                <div className="emp-img-2 ">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/About-Us/about-sustain2-min.jpg"}
                                        width={"100%"}
                                        className="img-fluid" alt="..."
                                        effect="blur"
                                    />

                                </div>
                            </div>
                            <div className="grid-item">
                                <div className="emp-img-3 ">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/About-Us/about-sustain3-min.jpg"}
                                        width={"100%"}
                                        className="img-fluid" alt="..."
                                        effect="blur"
                                    />

                                </div>
                            </div>

                        </div>

                    </div>


                </section >
            </FadeInSection>







        </>
    );
}

export default About;