import { useEffect } from "react";
import "./Privacy_Policy.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Helmet } from 'react-helmet';


const PrivacyHead = () => (

    <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Read Colorbot Privacy Policy to understand how we gather and utilize your personalised data while keeping it safe and secure, ensuring it is never traded." />
        <link rel="canonical" href="https://colorbot.in/privacy-policy" />

        <meta property="og:title" content="Privacy Policy" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/privacy-policy" />
        <meta property="og:description" content="Read Colorbot Privacy Policy to understand how we gather and utilize your personalised data while keeping it safe and secure, ensuring it is never traded." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/banners/privacy-banner.jpg" />
    </Helmet>
);


function Privacy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = "Privacy Policy - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Privacy Policy - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    return (
        <section className="mt-5 mb-4">
            <PrivacyHead/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 padd-neg">
                        <div className="banner-img">
                            {/* <h3 className="banner-text">Privacy Policy</h3> */}
                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/banners/privacy-banner.jpg"}
                                width={"100vw"}
                                className="d-block w-100 img-fluid" alt="Privacy Policy"
                                effect="blur"
                            />

                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
                <br />
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <hh2 className="title">PRIVACY POLICY</hh2>
                        <p className="subtitle">
                            The Privacy Policy explains how Colorbot collects, uses, retains, protects, and with whom we share your personal information when you visit the website, use Colorbot’s services and products, or interact with the website. By accessing or visiting this website, you agree to be bound by the terms and conditions.
                            Please go through the Privacy Policy before using this website or entering your personal information.
                        </p>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <p className="title">Please Note:</p>
                        <ul>
                            <li><p className="subtitle">Our Privacy Policy may change at any time without any prior notice. Therefore you are requested to visit the page time to time to be updated and aware about the amendments.</p></li>
                            <li><p className="subtitle">You are deemed to have given us the permission to use your personal information in accordance with this Privacy Policy.</p></li>
                            <li><p className="subtitle">This Privacy Policy does not apply to any sites maintained by third parties.</p></li>
                        </ul>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">Obtaining Information</h2>
                        <p className="subtitle">
                            The information such as your name, contact number, email address and postal address, etc. are stored with us only when these information are voluntarily entered by the customers.
                            All these information are safely retained with us and are used only to provide you better services and to fulfil your requests.
                        </p>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">Cookies or Tracking Technology</h2>
                        <p className="subtitle">
                            Cookies and Tracking technology are used to gather and analyse user information. This information is collected when you access and use Colorbot website. These tracking technologies are beneficial for gathering data about users which include:</p>

                        <p className="subtitle">- Location of users</p>
                        <p className="subtitle">- Device</p>
                        <p className="subtitle">- Time spent at the site</p>
                        <p className="subtitle">- Type of web browser used</p>
                        <p className="subtitle">- Operating system</p>
                        <p className="subtitle">- Number of visitors to the site</p>

                        <p className="subtitle">  All these data can help us understand our users and their approach in an efficient way.
                        </p>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">Your information is safe with us</h2>
                        <p className="subtitle">
                            Colorbot respects your privacy and is committed to protecting your privacy on the site and in our interactions with you. Your personal information will never be sold or disclosed to third parties.</p>


                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">Contact Details</h2>
                        <p className="subtitle">
                            In case you have any queries regarding this Privacy Policy, you may contact us at the following address:
                            <br />
                        </p>
                        <p className="subtitle">
                            Website:www.colorbot.in  <br />
                            Email Address: support@colorbot.in  <br />
                            Colorbot Pvt. Ltd.
                            03/UG-029, Upper Ground Floor,
                            Ansal Plaza, Sector-1,
                            Vaishali, Ghaziabad,
                            Uttar Pradesh,
                            201010
                        </p>


                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <br />

            </div>
        </section>
    );
}
export default Privacy;