import "./productstella.css"
import { NavLink, useNavigate } from "react-router-dom";

import axios from 'axios';
import Slider from 'react-slick';
import React, { useState, useEffect } from "react";
import FadeInSection from "../FadeInup/FadeInup";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import moment from "moment";
import { Helmet } from 'react-helmet';


const StellaHead = () => (

    <Helmet>
        <title>Buy Colorbot Stella 5 Star Ceiling Fan Online in India</title>
        <meta name="description" content="Buy Colorbot Stella 5-star rated BLDC fan online with smart functioning. Its brushless motor design ensures silent, heatless, reliable, and smoother functioning." />
        <link rel="canonical" href="https://colorbot.in/colorbot-stella" />

        <meta property="og:title" content="Buy Colorbot Stella 5 Star Ceiling Fan Online in India" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/colorbot-stella" />
        <meta property="og:description" content="Buy Colorbot Stella 5-star rated BLDC fan online with smart functioning. Its brushless motor design ensures silent, heatless, reliable, and smoother functioning." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://backend.colorbot.in/product/Stella/FSDB1200/Stella-product1.png" />
    </Helmet>
);


function Stella(props) {
    const [price, setprice] = useState(null)
    const [discountedprice, setdiscountedprice] = useState(null)
    const [fixedprice, setfixedprice] = useState(null)
    const [fixeddiscountedprice, setfixeddiscountedprice] = useState(null)
    const navigate = useNavigate();
    const [product, setproduct] = useState([])
    const [product_Name, setProduct_Name] = useState("Helix")
    const [product_Description, setProduct_Description] = useState("The Ultimate fun for home, featuring 5-star BLDC motor, inverter-friendly technology, higher air delivery, stylish design and remote. Get longer product protection with a 2+2 years warranty.")
    const [itemName, setItemName] = useState("")

    const [inventory, setInventory] = useState(0)

    const getproduct = async () => {
        try {
            await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },

                body: JSON.stringify({ category: "Stella", productfor: "Normal" }),
            }).then((res) => res.json()).then((response) => {


                if (response.status === 200) {
                    setproduct(response.data)
                    setprice(response.data[4].ProductPrice)
                    setdiscountedprice(response.data[4].ProductSelling)
                    setfixedprice(response.data[4].ProductPrice)
                    setfixeddiscountedprice(response.data[4].ProductSelling)
                    setProduct_Name(response.data[4].ProductName)
                    setProduct_Description(response.data[4].ProductDescription)
                    setItemName(response.data[4].ItemNo)
                    setInventory(response.data[4].ProductInventory)

                    var set = new Set();
                    var set1 = new Set();
                    for (let i = 0; i < response.data.length; i++) {


                        set.add(response.data[i].ProductSize)




                    }
                    var data = response.data.filter((item) => {
                        return item.ProductSize === response.data[4].ProductSize
                    })
                    for (let i = 0; i < data.length; i++) {
                        set1.add(data[i].ProductColorCode)
                    }
                    var arr = Array.from(set)
                    var arr1 = Array.from(set1)

                    for (let i = 0; i < arr1.length; i++) {
                        if (arr1[i] === "#000000") {
                            colorlist[0].available = true
                        }
                        if (arr1[i] === "#6d4d33") {
                            colorlist[1].available = true
                        }
                        if (arr1[i] === "#535353") {
                            colorlist[2].available = true
                        }
                        if (arr1[i] === "#d8d8d8") {
                            colorlist[3].available = true
                        }
                        if (arr1[i] === "#6d6d6d") {
                            colorlist[4].available = true
                        }
                        if (arr1[i] === "#F0F0F0") {
                            colorlist[5].available = true
                        }
                        if (arr1[i] === "#A47141") {
                            colorlist[6].available = true
                        }
                        if (arr1[i] === "#4A2413") {
                            colorlist[7].available = true
                        }
                        if (arr1[i] === "#F0F0F1") {
                            colorlist[8].available = true
                        }
                        if (arr1[i] === "#8E8E8E") {
                            colorlist[9].available = true
                        }
                    }

                    for (let i = 0; i < buttonlist.length; i++) {
                        buttonlist[i].available = false;  // Set all buttons to false initially
                    }
                    setcolorlist(colorlist)
                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i] === "900mm") {
                            buttonlist[0].available = true
                        }
                        if (arr[i] === "1200mm") {
                            buttonlist[1].available = true
                        }
                        if (arr[i] === "1400mm") {
                            buttonlist[2].available = true
                        }
                    }
                    setbuttonlist(buttonlist)


                    setbuttonactive(response.data[4].ProductSize)
                    if (localStorage.getItem("activeType") === "Stella") {
                        setactivecolor(localStorage.getItem("activeColor"))
                        setTimeout(() => {
                            document.getElementById(`color-${localStorage.getItem("activeColor")}`).click()
                        }, 300)
                        setShowLoader(false)
                    } else {
                        setactivecolor(response.data[4].ProductColorCode)
                        var imagearr = []
                        imagearr.push(response.data[4].ProductImage)
                        imagearr.push(response.data[4].ProductImage1)
                        imagearr.push(response.data[4].ProductImage2)
                        imagearr.push(response.data[4].ProductImage3)
                        imagearr.push(response.data[4].ProductImage4)
                        imagearr.push(response.data[4].ProductImage5)
                        for (let i = 0; i < 6; i++) {
                            imagearr.push(imagearr[i])
                        }

                        setimage(imagearr)
                        setimageselected(response.data[4].ProductImage)
                        setShowLoader(false)
                    }


                } else {
                    setproduct([])
                    setShowLoader(false)


                }
            })
        } catch (error) {
            console.log(error)
            setShowLoader(false)
        }
    }
    const [currentwidth, setcurrentwidth] = useState(window.innerWidth)
    const [buttonlist, setbuttonlist] = useState([
        {
            value: "900mm",
            available: false,

        },
        {
            value: "1200mm",
            available: false,

        }, {
            value: "1400mm",
            available: false,

        }
    ])
    const [imageselected, setimageselected] = useState("")
    const [image, setimage] = useState([])
    const [buttonactive, setbuttonactive] = useState("900mm")
    const [activecolor, setactivecolor] = useState("#000000")
    const [quantity, setquantity] = useState(1)
    const [colorlist, setcolorlist] = useState([
        {
            value: "Charcoal black",
            color: "#000000",
            secondary: "#000000",
            available: false
        }, {
            value: "Urban Oak",
            color: "#6d4d33",
            secondary: "#000000",
            available: false
        }, {
            value: "Midnight Grey",
            color: "#535353",
            secondary: "#000000",
            available: false
        }, {
            value: "Artic white",
            color: "#d8d8d8",
            secondary: "#d8d8d8",
            available: false
        }, {
            value: "Ash grey",
            color: "#6d6d6d",
            secondary: "#6d6d6d",
            available: false
        }, {
            value: "Noir white",
            color: "#F0F0F0",
            secondary: "#000000",
            available: false
        }, {
            value: "Caramel Brown",
            color: "#A47141",
            secondary: "#A47141",
            available: false
        }, {
            value: "Cocoa Brown",
            color: "#4A2413",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Blanca Brown",
            color: "#F0F0F1",
            secondary: "#4A2413",
            available: false
        }, {
            value: "Luxe Grey",
            color: "#8E8E8E",
            secondary: "#F0F0F0",
            available: false
        }
    ])
    const [pincode, setPincode] = useState('');
    const [city, setCity] = useState('');

    const [state, setState] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        setcurrentwidth(window.innerWidth);
        getproduct()
    }, [])

    // LOADER CODE
    const [showLoader, setShowLoader] = useState(true)

    const checkpincode = () => {
        setCity('');
        setState('');
        var value = pincode;

        if (value.length === 6) {
            setError('');

            axios
                .get(`https://api.postalpincode.in/pincode/${value}`)
                .then((res) => {
                    setState(res.data[0].PostOffice[0].State);
                    setCity(res.data[0].PostOffice[0].Block);


                })
                .then(() => {

                })
                .catch((err) => {

                    setError('Invalid PIN Code');
                });
        }

        if (value.length !== 6) {
            setCity('');

            setState('');
            setError('Pincode must be of 6 digits');
        }

    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        arrow: true,
        autoplaySpeed: 2000,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1002,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    };

    useEffect(() => {

        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })


    }, [])

    useEffect(() => {
        document.title = "Colorbot Stella";// Set the title when component mounts
        return () => {
            document.title = "Colorbot Stella"; // Reset the title when component unmounts
        };
    }, []);

    const [remote, setremote] = useState(true)
    const buyproduct = () => {
        var productprice = Number(quantity * parseInt(price));
        var productdiscountedprice = Number(quantity * parseInt(discountedprice));
        var productname = product_Name;
        var productimage = image[0]
        var productcolor = getActiveColorName();
        var productquantity = quantity;
        var productsweepsize = buttonactive;
        var productid = itemName
        var productremote = remote === true ? "1" : "0";
        var newItem = {
            "productid": productid,
            "productname": productname,
            "productimage": productimage,
            "productprice": productprice,
            "productdiscountedprice": productdiscountedprice,
            "fixedproductprice": Number(fixedprice),
            "fixedproductdiscountedprice": Number(fixeddiscountedprice),
            "productpath": window.location.pathname,
            "productcolor": productcolor,
            "inventory": inventory,
            "extendedwarranty": true,
            "modal": "Stella",
            "productquantity": productquantity,
            "productsweepsize": productsweepsize,
            "productremote": productremote
        }
        addToCart(newItem);
        window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
        if (props.LoggedIn) {

            navigate("/product_buy_now_delivery")
        } else {
            navigate("/product_buy_now_login")
        }

    }
    const addtocart = () => {

        var productprice = Number(quantity * parseInt(price));
        var productdiscountedprice = Number(quantity * parseInt(discountedprice));
        var productname = product_Name;
        var productimage = image[0]
        var productcolor = getActiveColorName();
        var productquantity = quantity;
        var productsweepsize = buttonactive;
        var productid = itemName
        var productremote = remote === true ? "1" : "0";
        var newItem = {
            "productid": productid,
            "productname": productname,
            "productimage": productimage,
            "productprice": productprice,
            "productdiscountedprice": productdiscountedprice,
            "fixedproductprice": Number(fixedprice),
            "fixedproductdiscountedprice": Number(fixeddiscountedprice),
            "productpath": window.location.pathname,
            "productcolor": productcolor,
            "inventory": inventory,
            "extendedwarranty": true,
            "modal": "Stella",
            "productquantity": productquantity,
            "productsweepsize": productsweepsize,
            "productremote": productremote
        }

        addToCart(newItem);
        window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
        props.opensidepanel()
    }
    const addToCart = (newItem) => {


        props.setcartdata((prevData) => {
            const existingItemIndex = prevData.findIndex(
                (item) => item.productid === newItem.productid
            );

            if (existingItemIndex !== -1) {
                const updatedData = prevData.map((item, index) => {
                    if (index === existingItemIndex) {
                        // Update the quantity of the existing item
                        const updatedQuantity = parseInt(item.productquantity) + parseInt(newItem.productquantity);
                        const updatedPrice = parseInt(item.productprice) + parseInt(newItem.productprice);
                        const updatedDiscountedPrice = parseInt(item.productdiscountedprice) + parseInt(newItem.productdiscountedprice);
                        return { ...item, productquantity: updatedQuantity, productprice: updatedPrice, productdiscountedprice: updatedDiscountedPrice };
                    }
                    return item;
                });

                localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            }

            const updatedData = [...prevData, newItem];
            localStorage.setItem('cartdata', JSON.stringify(updatedData));
            window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
            window.localStorage.setItem("addcart", true)
            return updatedData;
        });
    };
    const [activeknoxx, setactiveknoxx] = useState(0)



    const handleChangeQuantity = (newQuantity) => {
        if (newQuantity === "" || newQuantity === 0) {
            // toast.error("Please enter a valid Quantity"); // Display an error toast notification
            setquantity(newQuantity);

        } else {
            setquantity(newQuantity);


        }
    };
    const getActiveColorName = () => {
        const activeColorObject = colorlist.find(color => color.color === activecolor);
        return activeColorObject ? activeColorObject.value : "";
    };

    useEffect(() => {
        getActiveColorName()
    }, [activecolor])

    const getpairedproduct = async (colorcode) => {

        var data = product.filter((item) => {
            return item.ProductColorCode === colorcode && item.ProductSize === buttonactive
        })
        if (data.length > 0) {
            var set = new Set();

            for (let i = 0; i < data.length; i++) {

                if (product[i].ProductColorCode == colorcode) {

                    set.add(product[i].ProductSize)
                }




            }
            var arr = Array.from(set)

            // for (let i = 0; i < buttonlist.length; i++) {
            //     buttonlist[i].available = false;  // Set all buttons to false initially
            // }


            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === "900mm") {
                    buttonlist[0].available = true
                }
                if (arr[i] === "1200mm") {
                    buttonlist[1].available = true
                }
                if (arr[i] === "1400mm") {
                    buttonlist[2].available = true
                }
            }
            setbuttonlist(buttonlist)

            setbuttonactive(data[0].ProductSize)

            setprice(data[0].ProductPrice)
            setdiscountedprice(data[0].ProductSelling)
            setfixedprice(data[0].ProductPrice)
            setfixeddiscountedprice(data[0].ProductSelling)
            setProduct_Name(data[0].ProductName)
            setProduct_Description(data[0].ProductDescription)
            setItemName(data[0].ItemNo)
            setInventory(data[0].ProductInventory)
            var imagearr = []
            imagearr.push(data[0].ProductImage)
            imagearr.push(data[0].ProductImage1)
            imagearr.push(data[0].ProductImage2)
            imagearr.push(data[0].ProductImage3)
            imagearr.push(data[0].ProductImage4)
            imagearr.push(data[0].ProductImage5)
            for (let i = 0; i < 6; i++) {
                imagearr.push(imagearr[i])
            }

            setimage(imagearr)
            setimageselected(data[0].ProductImage)
            setquantity(1)

        }
    }

    const getsizepairedproduct = async (size) => {
        setbuttonactive(size);
        var data = product.filter((item) => {
            return item.ProductSize === size
        })


        if (data.length > 0) {

            var set = new Set();
            var set1 = new Set();

            for (let i = 0; i < product.length; i++) {

                if (product[i].ProductColorCode == activecolor) {

                    set.add(product[i].ProductSize)
                }




            }

            // setactivecolor(data[0].ProductColorCode)
            for (let i = 0; i < data.length; i++) {




                set1.add(data[i].ProductColorCode)


            }
            for (let i = 0; i < colorlist.length; i++) {
                colorlist[i].available = false;  // Set all buttons to false initially
            }


            var arr1 = Array.from(set1)


            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] === "#000000") {
                    colorlist[0].available = true
                }
                if (arr1[i] === "#6d4d33") {
                    colorlist[1].available = true
                }
                if (arr1[i] === "#535353") {
                    colorlist[2].available = true
                }
                if (arr1[i] === "#d8d8d8") {
                    colorlist[3].available = true
                }
                if (arr1[i] === "#6d6d6d") {
                    colorlist[4].available = true
                }
                if (arr1[i] === "#F0F0F0") {
                    colorlist[5].available = true
                }
                if (arr1[i] === "#A47141") {
                    colorlist[6].available = true
                }
                if (arr1[i] === "#4A2413") {
                    colorlist[7].available = true
                }
                if (arr1[i] === "#F0F0F1") {
                    colorlist[8].available = true
                }
                if (arr1[i] === "#8E8E8E") {
                    colorlist[9].available = true
                }
            }


            setcolorlist(colorlist)

            var arr = Array.from(set)



            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === "900mm") {
                    buttonlist[0].available = true
                }
                if (arr[i] === "1200mm") {
                    buttonlist[1].available = true
                }
                if (arr[i] === "1400mm") {
                    buttonlist[2].available = true
                }
            }
            setbuttonlist(buttonlist)
            for (let i = 0; i < data.length; i++) {
                if (arr1.includes(activecolor) && data[i].ProductColorCode === activecolor && data[i].ProductSize === size) {

                    setactivecolor(activecolor)
                    setprice(data[i].ProductPrice)
                    setdiscountedprice(data[i].ProductSelling)
                    setfixedprice(data[i].ProductPrice)
                    setfixeddiscountedprice(data[i].ProductSelling)
                    setProduct_Name(data[i].ProductName)
                    setProduct_Description(data[i].ProductDescription)
                    setItemName(data[i].ItemNo)
                    setInventory(data[i].ProductInventory)
                    var imagearr = []
                    imagearr.push(data[i].ProductImage)
                    imagearr.push(data[i].ProductImage1)
                    imagearr.push(data[i].ProductImage2)
                    imagearr.push(data[i].ProductImage3)
                    imagearr.push(data[i].ProductImage4)
                    imagearr.push(data[i].ProductImage5)
                    for (let i = 0; i < 6; i++) {
                        imagearr.push(imagearr[i])
                    }

                    setimage(imagearr)
                    setimageselected(data[i].ProductImage)
                    setquantity(1)


                } else {
                    setactivecolor(arr1[0])
                    setprice(data[0].ProductPrice)
                    setdiscountedprice(data[0].ProductSelling)
                    setfixedprice(data[0].ProductPrice)
                    setfixeddiscountedprice(data[0].ProductSelling)
                    setProduct_Name(data[0].ProductName)
                    setProduct_Description(data[0].ProductDescription)
                    setItemName(data[0].ItemNo)
                    setInventory(data[0].ProductInventory)
                    var imagearr = []
                    imagearr.push(data[0].ProductImage)
                    imagearr.push(data[0].ProductImage1)
                    imagearr.push(data[0].ProductImage2)
                    imagearr.push(data[0].ProductImage3)
                    imagearr.push(data[0].ProductImage4)
                    imagearr.push(data[0].ProductImage5)
                    for (let i = 0; i < 6; i++) {
                        imagearr.push(imagearr[i])
                    }

                    setimage(imagearr)
                    setimageselected(data[0].ProductImage)
                    setquantity(1)
                }
            }

        }
    }



    const ShareInfo = () => {

        if (navigator.share) {
            navigator.share({
                title: "Color Bot",
                text: "ColorBot Stella Fan",
                url: window.location.href
            }).then(() => {
                console.log('Share successful');
            }).catch((error) => {
                console.error('Error sharing:', error);
            });
        } else {
            // Fallback for browsers that do not support Web Share API
            console.log('Web Share API not supported.');
            // Implement your own custom share functionality here
        }
    };

    var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const convertdate = (date) => {
        var d = date.split("-")[0];
        var m = date.split("-")[1];

        var time = " " + d + "th" + " " + month[parseInt(m) - 1];

        return time;
    }
    const convertdatewithoutMonth = (date) => {
        var d = date.split("-")[0];
        var m = date.split("-")[1];

        var time = d + "th" + " ";

        return time;
    }
    return (

        showLoader ? <>
            <section className="mt-5 pt-4 loader-align">
                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/loader-gif.gif"></img>
                <p>LOADING COLORBOT...</p>
            </section>
        </>
            :

            <section className="mt-5 pt-4">
                <StellaHead />
                <div className="shopnow">
                    FREE SHIPPING on all prepaid orders, <span><NavLink to="/bldc-ceiling-fan">SHOP NOW</NavLink></span>
                </div>
                <div className="">
                    <div className="container pt-4 " >
                        <p className="breadcrumb "><a style={{ color: "#000000" }} href="/" > Home </a> &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <a style={{ color: "#000000" }} href="/bldc-ceiling-fan" > BLDC Ceiling Fans </a>   &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <span style={{ color: "#1428A1", fontSize: "13px" }} >Colorbot Stella</span></p>
                        {product.length > 0 ? <div className="row justify-content-center pt-2">
                            <div className="col-md-12 col-lg-6 pt-2 prod-img-pad">
                                <LazyLoadImage src={imageselected}
                                    width={"100%"}
                                    alt="helixproduct" className="img-fluid darkimage"
                                    effect="blur"
                                />

                                <Slider {...settings} className="helix slick-sliderdark">
                                    {image.map((item, index) => {
                                        return (
                                            <div className="helixmultiple" key={index}>
                                                <LazyLoadImage src={item} onClick={() => {

                                                    setimageselected(item)
                                                }} className={`${imageselected}` === item ? "img-fluid heliximageactive" : "img-fluid"} alt="helixproduct"
                                                    effect="blur" width={"100%"}
                                                />

                                            </div>
                                        )
                                    })}

                                </Slider>
                            </div>
                            <div className="col-md-12 col-lg-6 pt-2">
                                <h1 className="helixproductname producttop">{product_Name}</h1>
                                <p className="helixsubproductname producttop">{product_Description}</p>

                                <span className="helixprice producttop">₹ {discountedprice} <span className="helixmainprice"> {price}</span> <span className="helixdiscount">-{(((price - discountedprice) / price) * 100).toFixed(0)}%</span></span>

                                <p className="line"></p>
                                {/* <br /> */}
                                <div className="helixremoteoption producttop">
                                    <span className="remotename" style={{ marginBottom: "0px" }}>Remote:</span>

                                    &nbsp;&nbsp;&nbsp;
                                    <button className={"helixbuttonlist helixbuttonactive helix-remote remote-white"} onClick={() => {
                                        setremote(true)
                                    }} >White</button>
                                    <button className={"helixbuttonlist helixbuttonactive helix-remote remote-black diagonalCross2"} onClick={() => {
                                        setremote(true)
                                    }} >Black</button>
                                </div>
                                <div className="helixbuttonoption producttop">
                                    <span className="remotename">Sweep Size:</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="buttonlist">
                                        {buttonlist.map((item, index) => {

                                            return !item.available ? (
                                                <button className={item.value === buttonactive ? "helixbuttonlist helixbuttonactive" : "helixbuttonlist diagonalCross2"} disabled={!item.available} key={index} onClick={() => {
                                                    setbuttonactive(item.value);
                                                    getsizepairedproduct(item.value);


                                                }}>{item.value}</button>

                                            ) : (
                                                <button className={item.value === buttonactive ? "helixbuttonlist helixbuttonactive" : "helixbuttonlist "} disabled={!item.available} key={index} onClick={() => {
                                                    setbuttonactive(item.value);
                                                    getsizepairedproduct(item.value);



                                                }}>{item.value}</button>
                                            )
                                        })}

                                    </div>

                                </div>
                                <div className="helixbuttonoption producttop">
                                    <span className="remotename">Color:</span>
                                    &nbsp;
                                    <span className="helixcolor text-center">{getActiveColorName()}</span>
                                    <br />


                                </div>
                                <div className="helixcolorlist color-list">
                                    {colorlist.map((item, index) => {
                                        return (
                                            <div
                                                className={item.color === activecolor ? "helixcolorlistitem helixcoloractive" : "helixcolorlistitem"}
                                                key={index}
                                                id={`color-${item.color}`}
                                                onClick={() => {
                                                    if (item.available) {
                                                        setactivecolor(item.color);
                                                        getpairedproduct(item.color);
                                                    }
                                                }}
                                                style={{
                                                    transform: "rotate(90deg)",
                                                    background: `conic-gradient(${item.secondary} 0deg 180deg, ${item.color} 180deg 360deg)`,
                                                    pointerEvents: !item.available ? "none" : "auto", // Disable interaction when item is available
                                                    opacity: !item.available ? 1 : 1, // Optionally reduce opacity for visual indication of disabled state
                                                }}
                                            >
                                                <div className={!item.available ? "helixcolorlistitemdiagonal" : ""}>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="helixquantity producttop">
                                    <span className="remotename">Quantity:</span>
                                    &nbsp;&nbsp;&nbsp;

                                    <input
                                        type="number"
                                        className="helixselect"
                                        value={quantity}
                                        onChange={(e) => {

                                            const inputValue = parseInt(e.target.value, 10);

                                            if (inputValue < 0 || inputValue > 99) {
                                                // If the input is invalid, you can choose to handle it here.
                                                // For example, you might want to show an error message or prevent further processing.
                                                return;
                                            }
                                            handleChangeQuantity(e.target.value)

                                        }}
                                    />
                                    <span className="helixshare" onClick={() => {
                                        ShareInfo()
                                    }}>Share &nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/share.svg" alt="share" className="img-fluid" /> </span>

                                </div>

                                {inventory > 0 && parseInt(quantity) <= parseInt(inventory) ? <div className="helixbutton">
                                    <button id="stella-fan-buynow" className={quantity > 0 ? "helixbuynow" : "helixbuynow disabled"} disabled={quantity > 0 ? false : true} onClick={() => {
                                        buyproduct()
                                    }}>Buy Now</button>
                                    <button id="stella-fan-addcart" className={quantity > 0 ? "helixaddtocart" : "helixaddtocart disabled"} disabled={quantity > 0 ? false : true} onClick={() => {
                                        addtocart()

                                    }}>Add to Cart</button>
                                </div> : <p className="text-danger text-center">Out of stock!</p>}
                                <div className="helixwarranty">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/2yearwarranty.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="2yearwarranty"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/freeshipping.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="freeshipping"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/easyinstallation.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="easyinstallation"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/5star.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="5star"
                                        effect="blur"
                                    />

                                </div>

                                <div className="amazon-flipkart producttop">
                                    <p><b>Also Available on:</b>   <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Amazon.svg" className="img-fluid" alt="amazon" /> <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Flipkart.svg" className="img-fluid" alt="flipkart" /></p>
                                </div>
                                <div className="pincodechecker">
                                    <div>
                                        <input type="text" className="helixpincode form-control-sm" maxLength={6} placeholder="Pincode" value={pincode} onChange={(e) => {
                                            setPincode(e.target.value);
                                            setCity('');
                                            setState('');
                                            setError('')
                                        }} />
                                        <button className="helixcheck" onClick={() => {
                                            checkpincode();
                                        }}>Check</button>
                                    </div>
                                    <NavLink to="https://colorbots3.s3.ap-south-1.amazonaws.com/Buyers+Guid/Buyers-Guid.pdf" target="blank" >  <span className="buyinguide">Fan Buying Guide &nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/pdf.svg" className="img-fluid" alt="pdf" /></span> </NavLink>                                </div>
                                <div className="Address">
                                    {state !== "" ? <span className="subaddress" >{city} / {state}, {pincode}<br />Delivery by {convertdatewithoutMonth(moment().add(4, 'days').format("DD-MM-YYYY"))}-{convertdate(moment().add(7, 'days').format("DD-MM-YYYY"))}</span> : ""}
                                    <span className="error">{error}</span>
                                </div>
                                <br />
                            </div>
                        </div> : <p className="text-center text-dark m-4">No Products Available</p>}


                    </div>
                    <div className="imagecollarge ">
                        <div className="container pt-5 pb-5">
                            <div className="imagehorizontal subimagehorizontal">
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/stella/stella-tiles1.png"}

                                    className=" img-fluid helix100 p-2" alt=" BLDC chip"
                                    effect="blur"
                                />

                                <div>
                                    <div className="subimagehorizontal">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/stella/stella-tiles2.png"}

                                            className=" img-fluid helix100 p-2 " alt="Stella fans show range of colours"
                                            effect="blur"
                                        />
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/stella/stella-tiles3.png"}

                                            className=" img-fluid helix100 p-2" alt="Stella fan represents bi-directional rotation"
                                            effect="blur"
                                        />


                                    </div>
                                    <div className="subimagehorizontal">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/stella/stella-tiles4.png"}

                                            className=" img-fluid helix100 p-2" alt="Colorbot remote"
                                            effect="blur"
                                        />
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/stella/stella-tiles5.png"}

                                            className=" img-fluid helix100 p-2" alt=" Stella shows its elegant design"
                                            effect="blur"
                                        />


                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="stellacomfort pb-5 pt-5 ">

                        <FadeInSection>
                            <div className="container ">
                                <div className="row">
                                    <div className="col-md-9 pt-5 stellasecond stella-chipp">
                                        <h2 className="helixcomforthead">Explore the BLDC Brilliance</h2>
                                        <br />
                                        <p className="stelachip-p">Colorbot Stella features an intelligent BLDC motor integrated with an advanced Color-B algorithm. It’s the fan for the homes that crave for better performance and bigger savings.</p>
                                        <br />

                                        <p><b>28</b> Watts Energy Consumption</p>
                                        <p><b>67%</b> Energy Savings</p>
                                        <p><b>3x</b> Performance on Inverter Battery</p>

                                    </div>

                                </div>
                            </div>
                        </FadeInSection>
                        <br />
                        <br />
                    </div>
                    <div className="stellathirdcomfort  ">
                        <video autoPlay muted loop id="helixmyVideo" className="fan-vid stela-clr-vid" controls={false} playsInline>
                            <source src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/stella/stella-color-pc.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/stella/stella-color-mobile.mp4"} type="video/mp4" alt="Stella BLDC fan rotating and changing colors" />
                        </video>
                        <FadeInSection>


                            <div className="container  pb-5 stella-color">
                                <div className="row pb-5">
                                    <div className="col-md-9 pt-5 stellasecond ">
                                        <h2 className="helixcomforthead">Stunning Shades.<p></p> Stunning Spins.</h2>
                                        <br />
                                        <p>Elevate the look of your fifth wall by choosing from Colorbot Stella’s range of choicest color combinations.</p>



                                    </div>

                                </div>
                            </div>
                        </FadeInSection>
                        <br />
                        <br />
                    </div>
                    <div className="helixswiper pb-5  prizmasecondtab stella-remotemar">



                        <div className="container-fluid pb-5 ">
                            <FadeInSection>
                                <div className="row justify-content-center">
                                    <div className="col-md-12 col-lg-6">

                                        <h2 className="text-center prizmasecondhead stellaremote">Effortless Control</h2>



                                    </div>
                                </div>
                            </FadeInSection>

                            <FadeInSection>
                                <div className="row justify-content-center">
                                    <div className="col-md-7 col-lg-7">

                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/stella/Stella-remote.png"}

                                            className=" img-fluid w-100 p-2" alt="knoxxprogrammed"
                                            effect="blur"
                                        />




                                    </div>
                                </div>
                            </FadeInSection>
                        </div>
                    </div>

                    <div className="helixopp knoxx28watt stella-smokevid-sec">

                        <video autoPlay muted loop id="helixmyVideo" className="fan-vid stella-smokevid" controls={false} playsInline>
                            <source src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/stella/Stella-aero-pc.mp4" : "https://colorbots3.s3.ap-south-1.amazonaws.com/stella/Stella-aero-mobile.mp4"} type="video/mp4" alt="Stella fan showing its design" />
                        </video>
                        <FadeInSection>
                            <div className="row  stella-smoke-fan">
                                <div className="col-md-5  col-lg-7">

                                </div>
                                <div className="col-md-7 col-lg-5  stella28watt fan-bladestella ">
                                    <h2 className="helixcomforthead">When Style meets Efficiency</h2>
                                    <br />

                                    <p>With aerodynamic blades and a sleek compact motor, Colorbot Stella perfectly blends form and function. Enhanced by LED indicators, the fan smartly serve both as a speed control and a comforting night lamp in dark nights.</p>


                                </div>
                            </div>
                        </FadeInSection>
                    </div>
                    <div className={currentwidth > 758 ? "helixreusepc pb-5 pt-5" : "helixreuse pb-5 pt-5"}>
                        <br />

                        <FadeInSection>

                            <div className="padd ">
                                <h2 className="helixreusehead"><span style={{ color: "#00B207" }}>R</span>educed. <span style={{ color: "#00B207" }}>R</span>eused. <span style={{ color: "#00B207" }}>R</span>ecycled</h2>
                                <br />
                                <p className="helixreusepara padd-p"> Colorbot is one step ahead towards a greener world.
                                    Our packaging materials are made up of reusable and recycled materials that are non-toxic and
                                    non-hazardous, as Colorbot ensures sustainable approach in every step.</p>

                            </div>

                        </FadeInSection>
                        <br />
                    </div>
                </div>
            </section>

    )
}
export default Stella;