import { NavLink } from "react-router-dom";
import "./Buynowpayment.css";
const Thankyou = (props) => {
    return (
        <section className="mt-5 pt-5 pb-5 buynowbackground">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="successcard">
                            <div className="successimgcard">
                                <img  src="https://colorbots3.s3.ap-south-1.amazonaws.com/cartsuccess.webp" className="img-fluid" />
                            </div>
                            <div className="successcardbody">
                                <h4>THANK YOU!</h4>
                                <h6>Your Order has been placed successfully!</h6>
                                <NavLink to="/bldc-ceiling-fan" onClick={() => {
                                    window.localStorage.removeItem('showorderpop');
                                    props.setshoworderpop(false)
                                }}><button className="btn">Continue Shopping</button></NavLink>
                                <NavLink to="/Order" onClick={() => {
                                    window.localStorage.removeItem('showorderpop');
                                    props.setshoworderpop(false)
                                }}><p>View Orders</p></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Thankyou;