import { NavLink } from "react-router-dom";
import "./Servicepolicies.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useEffect, useState, useRef } from "react";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';


const ServicesHead = () => (

    <Helmet>
        <title>Services and Policies</title>
        <meta name="description" content="Discover Colorbot Services and Policies, including our dedication to customer satisfaction, product warranties, and support guidelines for seamless experience." />
        <link rel="canonical" href="https://colorbot.in/services-policies" />

        <meta property="og:title" content="Services and Policies" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/services-policies" />
        <meta property="og:description" content="Discover Colorbot Services and Policies, including our dedication to customer satisfaction, product warranties, and support guidelines for seamless experience." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/banners/Services-banner.jpg" />
    </Helmet>
);

function Servicepolicies() {
    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])

    useEffect(() => {
        document.title = "Services and Policies - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Services and Policies - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    let currentDate = new Date();

    // Subtract 30 days from the current date
    let thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(currentDate.getDate() - 30);
    const today = new Date().toISOString().split('T')[0];
    // Format the warranty date as a string (YYYY-MM-DD)
    let warrantyDate = thirtyDaysAgo.toISOString().split('T')[0];
    const fileInputRef = useRef(null);
    const [filename, setfilename] = useState("")
    const handleButtonClick = () => {
        setfilename("")
        fileInputRef.current.click();
    };
    const [name, setName] = useState('');
    const [file, setFile] = useState(null);

    const [serial, setSerial] = useState('');
    const [modal, setModal] = useState('');
    const [platform, setPlatform] = useState('');
    const [date, setDate] = useState('');
    const [invoice, setInvoice] = useState('');
    const [itemNo, setItemNo] = useState('');
    const [description, setDescription] = useState('');
    const [address, setAddress] = useState('');

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        // Do something with the selected file (e.g., upload, read contents, etc.)

        setfilename(selectedFile.name)
    };
    const [lgShow, setLgShow] = useState(false);

    function warrantypop() {
        setLgShow(true)
    }
    const h3RefRegister = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isH3Visible, setIsH3Visible] = useState(false);


    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [warrantypolicy, setwarrantypolicy] = useState(false)
    const [active, setactive] = useState(false)
    const [lgShow1, setLgShow1] = useState(false);

    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        setPhoneNumber(inputValue);

    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        setEmail(inputValue);

    };



    const handleSubmit = async () => {


        const phoneRegex = /^\d{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (name === "") {
            toast.error("Please enter your name.")
        } else if (phoneNumber === "") {
            toast.error("Please enter your phone number.")
        } else if (!phoneRegex.test(phoneNumber)) {
            toast.error("Please enter a valid 10 digit Phone Number")
        } else if (email === "") {
            toast.error("Please enter your email address.")
        } else if (!emailRegex.test(email)) {
            toast.error("Please enter a valid email address.")

        } else if (serial === "") {
            toast.error("Please enter your product order Id.")
        } else if (modal === "") {
            toast.error("Please select your product modal.")
        } else if (itemNo === "") {
            toast.error("Please enter your product item no.")
        } else if (platform === "") {
            toast.error("Please select your product platform.")
        } else if (date === "") {
            toast.error("Please select your product purchase date.")
        } else if (filename === "") {
            toast.error("Please upload your product invoice.")
        }
        else {

            setactive(true)
            try {

                const formData = new FormData();
                formData.append('name', name);
                formData.append('email', email);
                formData.append('phone', phoneNumber);
                formData.append('address', address);
                formData.append('modal', modal);
                formData.append('orderid', serial);
                formData.append('date', date);
                formData.append('itemno', itemNo);
                formData.append('platform', platform);
                formData.append('file', file);
                formData.append('description', description);

                await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotRegisterWarrantyClaim`, {
                    method: 'POST',
                    body: formData
                }).then((res) => res.json()).then((response) => {


                    if (response.status === 200) {
                        setactive(false)
                        setLgShow1(true)
                        toast.success(response.message)
                        setName("")
                        setEmail("")
                        setPhoneNumber("")
                        setItemNo("")
                        setAddress("")
                        setModal("")
                        setSerial("")
                        setPlatform("")
                        setDate("")
                        setInvoice("")
                        setDescription("")
                        setfilename("")

                    } else {
                        setactive(false)
                        toast.error(response.error)
                    }
                })
            } catch (error) {
                setactive(false)
                console.log(error)
            }

        }


    };
    const [tab, setTab] = useState([

        {
            id: "vendor",
            name: "Distributor Policy"
        },
        {
            id: "shipping",
            name: "Shipping Policy"
        }, {
            id: "warranty",
            name: "Warranty Policy"
        },

        {
            id: "Payment",
            name: "Payment security"
        },
        {
            id: "Customer",
            name: "Customer grievance"
        },
        {
            id: "Register",
            name: "Register New Complaint"
        },

    ])
    const [activetab, setActivetab] = useState(tab[0].id)
    return (
        <>
            <section className='mt-5 pt-4'>
                <ServicesHead />
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    style={{
                        borderRadius: "10px"
                    }}

                    centered
                    show={lgShow1}

                    onHide={() => setLgShow1(false)}

                >
                    <Modal.Header style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>

                    </Modal.Header>
                    <Modal.Body style={{ paddingTop: "0rem" }}>


                        <div className="box-profile-pop-profile">



                            <div className="logoutpop">
                                <img src="assets/successicon.svg" className="img-fluid" alt="exlam" />
                            </div>
                            <div className="logoutpop mt-2">
                                <p>Thank You! Your Complaint has been registered successfully.</p>
                            </div>
                            <div className="logoutpop button">
                                <button className="btn btncancellog" style={{
                                    background: "#242933",
                                    padding: "6px 47px",
                                    color: "white",
                                    borderRadius: "8px"
                                }} onClick={() => setLgShow1(false)}>
                                    Ok
                                </button>

                            </div>







                        </div>


                    </Modal.Body>
                </Modal>


                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 padd-neg">
                            <div className="banner-img">
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/banners/Services-banner.jpg"}
                                    width={"100vw"}
                                    className="d-block w-100 img-fluid" alt="Services and Policies"
                                    effect="blur"
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container p-5" >

                    <ul className="nav nav-tabs service-tab" id="myTab" role="tablist" >
                        {tab.map((item, index) => {
                            return (
                                <li className="nav-item" key={index}>
                                    <a className={`nav-link ${activetab === item.id ? "active" : ""}`} id={`${item.id}-tab`} data-toggle="tab" href={`#${item.id}`} role="tab" aria-controls={`${item.id}`} aria-selected="true" onClick={() => setActivetab(item.id)}>{item.name}</a>
                                </li>
                            )
                        })}

                    </ul>

                    <div className="tab-content" id="myTabContent">

                        {activetab === "vendor" ? (
                            <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="vendor-tab">
                                {/* id="vendor" */}
                                <div className="servicetab">
                                    <h1><b>DISTRIBUTOR POLICY</b></h1>
                                    <br />
                                    <p>
                                        Colorbot extends an invitation to potential distributors interested in joining forces with us. We believe in the power of collaboration and mutual success. By becoming a distributor for us, you're not just partnering with a manufacturer; you're joining a community committed to excellence. From strategic marketing initiatives to targeted sales campaigns, we're here to ensure that our partnership flourishes.
                                        <br />
                                        <br />
                                        Please click on the following link to share your details and business needs with us.
                                        <br />
                                        <br />

                                        <NavLink to="https://docs.google.com/forms/d/1zNGMYmT_RadeXlR0qmexiFjEN7umeGGktIU4hzFF7ew/viewform?edit_requested=true" target="_blank"><button className="btn btn-primary">Become a Distributor</button></NavLink>

                                    </p>
                                </div>
                            </div>) : ("")}

                        {activetab === "shipping" ? (
                            <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="shipping-tab">
                                {/* id="shipping" */}
                                <div className="servicetab">
                                    <h2><b>SHIPPING POLICY</b></h2>
                                    <br />
                                    <ul>
                                        <li>We value our customers the most and delivering the products without any hassle is our first and foremost priority. We ship the products and dispatch as soon as your order is confirmed. However, it is not possible if a product is out of stock or doesn’t match the availability.</li>
                                        <li>Before placing an order, please ensure that the area code or pin code is accessible for our product delivery.</li>
                                        <li>In case the pin code is not accessible, you may check with any alternative pin code that can be convenient for you</li>
                                        <li>We will deliver the product to the address mentioned in the time of purchasing.</li>
                                        <li>After receipt of payment confirmation, we will ship the product.</li>
                                        <li>We offer free shipping to every available pin codes in India. However, the buyers need to pay for any extra tax & Octroi when and where it is applicable.</li>
                                        <li>For any other queries regarding the Shipping Policy, you may contact us at support@colorbot.in / enquiry@colorbot.in.</li>
                                    </ul>
                                </div>
                            </div>
                        ) : ("")}
                        {activetab === "warranty" ? (
                            <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="warranty-tab">
                                {/* id="warranty" */}
                                <div className="servicetab">
                                    <h2><b>WARRANTY POLICY</b></h2>
                                    <br />
                                    <p>
                                        Colorbot Private Limited (CPL) is committed to providing you with the best products and services. It is our first priority to ensure customers’ satisfaction. The warranty services provided by Colorbot therefore guarantees longer protection on your purchase.
                                        <br />
                                        <br />
                                        <b>Scope of Warranty:</b>
                                        <br />

                                        Your product must be in warranty period to avail of free home or on-site services.<br />
                                        For serialized products with stickers or printed manufacture months, warranty consideration shall begin on the date of the end user's sale invoice and continue through the duration listed in the preceding table.<br />
                                        For serialized products without stickers or printed manufacture months, the start date is the date of the end user's sale invoice and continues through the duration of the warranty period listed in the preceding table.<br /><br />
                                        The invoice of customer should have a clear mention of the following things:
                                        <ul>
                                            <li>Date of Purchase</li>
                                            <li>Model Number</li>
                                            <li>Complete address of the shop/ retailing platform from where the product was purchased.</li>
                                            <li>Valid GST number</li>
                                        </ul>

                                        The Warranty Period for any Colorbot product starts from the original date of purchase by the customer/ end-user.<br />
                                        Different products may have different warranty period.<br />
                                        Any complaint with incomplete warranty information will not be entertained for any service.<br />
                                        The consumer needs to file a complaint to the company's mailing address given below for any questions or complaints about the product.<br /><br />

                                        <b>
                                            Contact us at: +91 95 9994 0985. <br />
                                            Email us at: support@colorbot.in.
                                        </b><br /><br />

                                        <b>Warranty Period: </b><br />
                                        All Colorbot products are protected under our warranty coverage.  <br />
                                        The period of warranty may vary from models to models, depending upon the product types. <br />
                                        All the ceiling fans are provided with 2 years of standard warranty.<br />
                                        An extendable warranty of 2 years is available for the ceiling fans which is payable at the time of purchase or within 1 month of the purchase of the same.<br /><br />

                                        <b>Warranty Coverage: </b><br />
                                        Warranty coverage applies to only new and original Colorbot units.  <br />
                                        The Colorbot ceiling fans motor, drive and associated components are covered under warranty services against any manufacturing defects. <br />
                                        If you find any defect at the time of product receipt or within the warranty period, you may lodge a complaint by resgistering a new complaint on website or call on the mentioned number.<br />
                                        You have to mention all required details and submit the original invoice copy to claim any warranty.<br />
                                        Based on the complaint and product’s state, further procedures for repair or replace will be initiated. <br /><br />

                                        <b>Terms and Conditions: </b><br />
                                        The start date of this Warranty is the date of the purchase on invoice. <br />
                                        This Warranty is only accessible to the original buyer of the Product in India. <br />
                                        To obtain this warranty, the original purchase invoice and warranty certificate should be shown when the consumer approaches the merchant or dealer from whom he or she purchased the product in order to obtain warranty services (duly signed and stamped by the Selling Dealer.)<br />
                                        The retailer or dealer will either repair or replace the defective Product if it is found by the retailer or dealer that the Product has any manufacturing flaws.<br />
                                        After then, only the remaining time under the warranty will apply to the repaired or replaced product. Defective Product must be properly returned to the company and becomes its property.<br />
                                        if the same product or a product with a similar design is unavailable due to an unforeseen event, the product will be replaced with the closest model that is currently on the market.<br />
                                        It is made clear that the company will not be liable to the client for any monetary loss, business loss, consequential or resultant responsibility, property damage, or any other harm or loss.<br /><br />

                                        <b>Warranty is not covered if: </b><br />
                                        If the customer operates the product otherwise than in accordance with the product specifications, or if they break the warranty's terms and conditions, installation instructions, or guidelines<br />
                                        If the product has been reconditioned or altered, the serial number has been damaged, lost, or tampered with.<br />
                                        If the product is used in circumstances that are not typical (e.g. abnormal voltage surge, extreme environmental conditions etc.)<br />
                                        If any commercial use of the product is made.<br />
                                        If the accessories have not been mounted using the company's mounting boxes.<br />
                                        Any damage that results from an accident, carelessness, incorrect upkeep, handling carelessly, tampering, or that the client incurs while the product is in transit.<br />
                                        Any damage brought on by unforeseen events, such as a force majeure, etc.<br />
                                        Any damage brought on by a flaw in any wiring, electrical/civil installation, or third-party product.<br />

                                    </p>


                                </div>
                            </div>) : ("")}


                        {activetab === "Payment" ? (
                            <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="Payment-tab">
                                {/* id="Payment" */}
                                <div className="servicetab">
                                    <h2><b>PAYMENT SECURITY</b></h2>
                                    <br />
                                    <p>
                                        Colorbot Pvt. Ltd., hereafter referred to as "the Company," is committed to ensuring the security of payment information on our website. This Payment Security Policy outlines the measures and practices we have in place to protect the payment information of our customers, maintain compliance with relevant regulations, and safeguard the trust of our clients.
                                        <br />
                                        <br />
                                        <b>1.Scope</b>
                                        <br />

                                        When you make any purchase or transaction on our website, Colorbot may collect Payment Information from you related to such Transaction as well as other Personal Information such as shipping address for shipping of the ordered products ordered through the Website. Colorbot may use your Personal Information or Payment Information in order to complete the Transaction and, if applicable, for successful purchase. The Payment Information may also be shared with the third parties, as may be necessary, to complete your Transaction to process the payment made through the credit card, debit card or any other Payment Gateways.
                                        This policy applies to all aspects of our online payment processing, including but not limited to:
                                        <br />
                                        <br />
                                    </p>

                                    <ul>
                                        <li>Online transactions made through our website.</li>
                                        <li>Payment information collected via telephone or other communication channels.</li>
                                        <li>Storage, processing, and transmission of payment data within our internal systems.</li>
                                    </ul>

                                    <p>
                                        <b> 2.Payment Information Collection</b>
                                        <br />

                                        The Company will collect and store payment information for online transactions on a secure server.
                                        <br />
                                        <br />
                                    </p>
                                    <ul>
                                        <li>All payment data will be collected using encryption, such as Secure Sockets Layer (SSL) technology.</li>
                                        <li>Payment information will be stored securely and in compliance with applicable regulations.</li>
                                        <li>We will not store sensitive authentication data, including card validation codes, after authorization.</li>

                                    </ul>

                                    <p>
                                        <b>3.Payment Processing</b>
                                        <br />

                                    </p>
                                    <ul>
                                        <li>The Company will use a trusted and PCI DSS compliant payment gateway to process online payments.</li>
                                        <li>The payment gateway used will have advanced fraud detection and prevention measures in place.</li>
                                        <li>Customer information will be shared with the payment gateway solely for the purpose of processing the payment.</li>
                                    </ul>

                                    <p>
                                        <b>4.Access Controls</b>
                                        <br />

                                    </p>
                                    <ul>
                                        <li>Access to payment data is restricted to authorized personnel only.</li>
                                        <li>All authorized personnel will be trained in payment security best practices</li>
                                        <li>Access to payment data is monitored and logged for security purposes.</li>
                                    </ul>

                                    <p>
                                        <b>5.Regular Security Assessments</b>
                                        <br />

                                        The Company will conduct regular security assessments to ensure the ongoing security and compliance of our payment processing systems.
                                        <br />
                                        <br />
                                    </p>
                                    <ul>
                                        <li>Regular vulnerability assessments and penetration testing will be performed.</li>
                                        <li>Compliance with PCI DSS will be audited annually.</li>
                                    </ul>

                                    <p>
                                        This Payment Security Policy will be reviewed and updated as necessary to adapt to changing threats, regulations, and technologies.
                                    </p>

                                    <p>
                                        <br />

                                        <b> 6.Contact Information</b>
                                        <br />

                                        If you have questions, concerns, or need to report a security issue related to payment information, please contact our customer support team at support@colorbot.in.
                                        Colorbot is dedicated to providing a secure and reliable online shopping experience. This Payment Security Policy is an essential part of our commitment to protecting the privacy and financial information of our customers.
                                        <br />
                                        <br />
                                    </p>

                                </div>
                            </div>
                        ) : ("")}

                        {activetab === "Customer" ? (
                            <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="Customer-tab">
                                {/* id="Customer" */}
                                <div className="servicetab">
                                    <h2><b>CUSTOMER GRIEVANCE</b></h2>
                                    <br />
                                    <p>
                                        Colorbot earnestly tries to provide the customers with best products and services. However, we also understand that there may be time when a customer may need to look out for solutions for any unsatisfactory experiences related to the company’s products and/ or services.
                                        This Customer Grievance Policy is created to outline a clear process for addressing and resolving such incidences and to ensure that all of the Colorbot Customers are heard, their complaints/ grievances are brought forward, necessary actions are taken, and any issue associated are resolved in a timely and unbiased manner.
                                    </p>

                                    <p>
                                        <br />

                                        <b>Purpose:</b>
                                        <br />

                                        The Purpose of this Policy is to
                                    </p>


                                    <ul>
                                        <li>Explain the scope and definition of the Customers’ Grievances</li>
                                        <li>Outline the process of reporting and closure of Grievances.</li>
                                        <li>Define Colorbot’s Confidentiality measures</li>
                                        <li>Describe the disciplinary actions and measures taken against any policy violation</li>
                                    </ul>
                                    <p>
                                        <br />

                                        <b>Scope:</b>
                                        <br />

                                        The Customer Grievance Policy is applicable for all Colorbot employees and for all the customers who have availed any service from the Colorbot or purchased any Colorbot product.
                                    </p>
                                    <p>
                                        <br />

                                        <b>Effective Date:</b>
                                        <br />

                                        This Policy stands effective from 10.10.2023.
                                    </p>

                                    <p>
                                        <br />

                                        <b>Note:</b>
                                        <br />

                                        This policy is applicable to products and services covered under warranty period, products and services not covered under warranty period, for any order that has not been delivered or any services that have not been attended.
                                    </p>
                                    <p>
                                        <br />

                                        <b>This Policy is applicable when:</b>
                                        <br />
                                    </p>


                                    <ul>
                                        <li>The product sold by the company is under warranty and falls short of the performance as per the required criteria and the customer feels unsatisfied.</li>
                                        <li>This policy applies to situations where a customer places an order for a product and the company does not deliver it, even though the consumer has complied with all formalities and conditions specified by the company.</li>
                                        <li>This guideline applies when a customer's service request has been made and has gone unanswered for more than 48 hours</li>
                                        <li>This policy also applies to any other complaints outside of the normal contractual cycle or process, however it excludes situations in which the customer did not follow the company's protocol.</li>
                                        <li>The standard procedure to submit a Customer grievance is through sending an email to the
                                            support@colorbot.in  or writing to us at the following address:
                                            Colorbot Pvt. Ltd. 03/UG-029, Upper Ground Floor,
                                            Ansal Plaza, Sector-1, Vaishali, Ghaziabad,
                                            Uttar Pradesh, 201010

                                        </li>
                                    </ul>

                                </div>
                            </div>
                        ) : ("")}

                        {activetab === "Register" ? (
                            <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="Register-tab">
                                {/* id="Register" */}
                                <div className="servicetab">
                                    <div className="container-fluid ">
                                        <div className="row justify-content-center">

                                            <div className="col-lg-8">
                                                <div className="contact-usregister mt-4">

                                                    <input type="text" placeholder="Name*" className="form-control form-control-lg mt-4" value={name} onChange={(e) => {
                                                        setName(e.target.value)
                                                    }} />
                                                    <input
                                                        type="text"
                                                        placeholder="Phone*"
                                                        className="form-control form-control-lg mt-3"
                                                        value={phoneNumber}
                                                        onChange={handlePhoneChange}
                                                        minLength={10}
                                                        maxLength={10}

                                                    />

                                                    <input
                                                        type="email"
                                                        placeholder="Email*"
                                                        className="form-control form-control-lg mt-3"
                                                        value={email}
                                                        onChange={handleEmailChange}

                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder="Address*"
                                                        className="form-control form-control-lg mt-3"
                                                        value={address}
                                                        onChange={(e) => {
                                                            setAddress(e.target.value)

                                                        }}

                                                    />
                                                    <input type="text" placeholder="Order ID*" className="form-control form-control-lg mt-3" value={serial} onChange={(e) => {
                                                        setSerial(e.target.value)
                                                    }} />
                                                    <select className="form-control form-control-lg mt-3" style={{
                                                        padding: "8px 25px",
                                                        color: "#6a6f73",

                                                        fontSize: "14px",
                                                        fontStyle: "normal",
                                                        fontWeight: "400",
                                                        lineHeight: "normal"

                                                    }} value={modal} onChange={(e) => {
                                                        setModal(e.target.value)

                                                    }}>
                                                        <option value={""}>Product Model</option>
                                                        <option value={"Helix"}>Helix</option>
                                                        <option value={"Stella"}>Stella</option>
                                                        <option value={"Prizma"}>Prizma</option>
                                                        <option value={"Knoxx"}>Knoxx</option>
                                                        <option value={"Knoxx-Quad"}>Knoxx Quad</option>
                                                        <option value={"Stella-Quad"}>Stella Quad</option>

                                                    </select>
                                                    <input type="text" placeholder="Item No*" className="form-control form-control-lg mt-3" value={itemNo} onChange={(e) => {
                                                        setItemNo(e.target.value)
                                                    }} />
                                                    <select className="form-control form-control-lg mt-3" style={{
                                                        padding: "8px 25px",
                                                        color: "#6a6f73",

                                                        fontSize: "14px",
                                                        fontStyle: "normal",
                                                        fontWeight: "400",
                                                        lineHeight: "normal"
                                                    }} value={platform} onChange={(e) => {
                                                        setPlatform(e.target.value)

                                                    }}>
                                                        <option value={""}>Platform</option>
                                                        <option value={"ColorBot"}>Colorbot Website</option>
                                                        <option value={"Amazon"}>Amazon</option>
                                                        <option value={"Flipkart"}>Flipkart</option>
                                                    </select>

                                                    <label className="mt-3" >Date of Purchase*</label>

                                                    <input type="date" className="form-control form-control-lg mt-1" max={today} min={warrantyDate} value={date} onChange={(e) => {
                                                        setDate(e.target.value)
                                                    }} />
                                                    <label className="mt-3">Upload Invoice</label>
                                                    <button className="btn-primary btn-upload mt-2" onClick={handleButtonClick}>
                                                        Choose File
                                                    </button> {filename}
                                                    <input
                                                        type="file"
                                                        ref={fileInputRef}
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange}
                                                    />

                                                    <textarea className="form-control form-control-lg  mt-3" placeholder="Description*" value={description} onChange={(e) => {
                                                        setDescription(e.target.value)
                                                    }}></textarea>


                                                    <button className="btn btn-lg btn-primary mt-4 mb-4 contactsubmit" disabled={active} onClick={() => {
                                                        handleSubmit()
                                                    }}>SUBMIT</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ("")}



                    </div>

                </div>
            </section>
        </>
    );

}
export default Servicepolicies;