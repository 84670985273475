import { useEffect } from "react";
import "./Return_Policy.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from 'react-helmet';


const ReturnHead = () => (

    <Helmet>
        <title>Return Policy</title>
        <meta name="description" content="Discover the Colorbot Return Policy to understand how we handle returns and replacements. Learn about our process and conditions for trouble-free shopping." />
        <link rel="canonical" href="https://colorbot.in/return-policy" />

        <meta property="og:title" content="Return Policy" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/return-policy" />
        <meta property="og:description" content="Discover the Colorbot Return Policy to understand how we handle returns and replacements. Learn about our process and conditions for trouble-free shopping." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="Imagelink" />
    </Helmet>
);
function Return() {
    useEffect(() => {

        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })


    }, [])

    useEffect(() => {
        document.title = "Return Policy - Colorbot";// Set the title when component mounts
        return () => {
            document.title = "Return Policy - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    return (
        <section className="mt-5 mb-4">
            <ReturnHead />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 padd-neg">
                        <div className="banner-img">
                            <h1 className="banner-text">Return Policies </h1>
                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/banners/Return-policy-banner.jpg"}
                                width={"100vw"}
                                className="d-block w-100 img-fluid" alt="..."
                                effect="blur"
                            />

                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
                <br />
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">RETURN, REPLACEMENT AND CANCELLATION POLICY</h2>
                        <br />
                        <p className="subtitle">
                            Thank you for choosing Colorbot! We strive to provide you with the best products and services. Please read our return
                            policy carefully to ensure a smooth experience.

                            <br />  <br />
                            <h3><b>1. Return Policy:</b></h3> Once purchased, Colorbot Pvt. Ltd. doesn’t accept any request for return or refund of
                            the product. If you need to initiate a complaint or have any concerns regarding your order, please contact our
                            customer service team within 10 days from the delivery date. To raise any complaint against any manufacturing defect
                            in the received product, you may register a complaint on our website and follow the procedure. You may contact us
                            directly and we will guide you through the process and assist you accordingly.

                            <br />  <br />
                            <h3><b>2. Cancellation Policy:</b></h3> You can cancel your order before it is shipped. No order can be cancelled after
                            the shipment. Any payment made for the purchase will be refunded to the original source of payment in 3 to 5 business
                            days. <br />
                            The order can not be cancelled partially. In case of multiple product purchase, all the items will be cancelled once
                            you cancel the order. No amount will be refunded if the cancellation is unsuccessful for any reason.


                            <br />  <br />
                            <h3><b>3. Replacement Policy:</b></h3> We offer a replacement for damaged or defective items. Make sure that the product
                            is in warranty period. To check if your product is under warranty period, you may contact the customer care.  <br />
                            Products can not be accepted for replacement if:  <br />  <br />
                            Products returned without original packaging.<br />
                            Items that show signs of use or damage not related to transportation.<br />
                            Products returned after the specified return period.<br />
                            Products exceeding warranty period. (You may read our Warranty Policy to know more.) <br />

                            <br />  <br />
                            <h3><b>4. Refund Policy:</b></h3> No refunds will be issued once a product is purchased on Colorbot.

                            <br />  <br />
                            <h3><b>5. Delivery Attempts:</b></h3>Our delivery partners will attempt to deliver your order a maximum of two times. If, after two attempts, the order is still not received by the customer, it will be returned to our warehouse.

                            <br />  <br />
                            <h3><b>6. Reordering:</b></h3>If your order is returned to the warehouse due to failed delivery attempts, and you wish to reorder the same items, please note that an additional amount for shipment charges will be added to your order total.

                            <br />  <br />
                            <h3><b>7. Prepaid Payment</b></h3>For orders with prepaid payment, please be advised that if the customer has not responded to delivery attempts or has returned the order without any valid reason, no amount will be refunded.

                            <br />  <br />
                            <h3><b>8. Valid Reasons for Returns:</b></h3>Damaged or defective products upon delivery.<br />
                            Incorrect items received.<br />
                            Missing items from the order. In case of missing items, the missing object will be sent via courier and no other extra amount will be charged.

                            <br />  <br />
                            <h3><b>9. Contact Information:</b></h3>For any inquiries or assistance regarding returns, feel free to reach out to us
                            via support@colorbot.in
                            Note: This return policy is subject to change without prior notice. Please check our website or contact customer
                            service for the most up-to-date information.
                            Thank you for your understanding and cooperation!



                        </p>
                    </div>
                    <div className="col-md-1"></div>
                </div>

                <br />
                {/* <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <p className="title">Please Note:</p>
                        <ul>
                            <li><p className="subtitle">Our Return Policy may change at any time without any prior notice. Therefore you are requested to visit the page time to time to be updated and aware about the amendments.</p></li>
                            <li><p className="subtitle">You are deemed to have given us the permission to use your personal information in accordance with this Return Policy.</p></li>
                            <li><p className="subtitle">This Return Policy does not apply to any sites maintained by third parties.</p></li>
                        </ul>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <p className="title">Obtaining Information</p>
                        <p className="subtitle">
                            The information such as your name, contact number, email address and postal address, etc. are stored with us only when these information are voluntarily entered by the customers.
                            All these information are safely retained with us and are used only to provide you better services and to fulfil your requests.
                        </p>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <p className="title">Cookies or Tracking Technology</p>
                        <p className="subtitle">
                            Cookies and Tracking technology are used to gather and analyse user information. This information is collected when you access and use Colorbot website. These tracking technologies are beneficial for gathering data about users which include:</p>

                        <p className="subtitle">- Location of users</p>
                        <p className="subtitle">- Device</p>
                        <p className="subtitle">- Time spent at the site</p>
                        <p className="subtitle">- Type of web browser used</p>
                        <p className="subtitle">- Operating system</p>
                        <p className="subtitle">- Number of visitors to the site</p>

                        <p className="subtitle">  All these data can help us understand our users and their approach in an efficient way.
                        </p>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <p className="title">Your information is safe with us</p>
                        <p className="subtitle">
                            Colorbot respects your privacy and is committed to protecting your privacy on the site and in our interactions with you. Your personal information will never be sold or disclosed to third parties.</p>


                    </div>
                    <div className="col-md-1"></div>
                </div> */}
                <br />
                <br />

            </div>
        </section>
    );
}
export default Return;