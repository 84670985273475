import { useEffect } from "react";
import { NavLink } from "react-router-dom"
import "./Sitemap.css";
function Sitemap() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = "Sitemap - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Sitemap - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    return (
        <section className="mt-5 mb-4">
            <div className="container-fluid ">

                <div className="row row-flex pt-5 justify-content-center text-center ">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 mt-4 ">
                        <h1>Sitemap</h1>
                    </div>

                </div>
                <div className="row row-flex pb-5 justify-content-center ">
                    <div className="col-md-6 col-lg-3 col-sm-6 col-xs-12 mt-4 mb-5 ">
                        <div className="sitewidth">
                            <div className="siteheight"></div>
                            <div className="sitemap-card">
                                <br />
                                <br />

                                <div className="sitemap-card-img">
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/product_site.svg" alt="" />
                                </div>
                                <h2>Our Products</h2>
                                <br />
                                <NavLink to="/colorbot-helix"><p className="sitesubtitle">Helix</p></NavLink>
                                <NavLink to="/colorbot-knoxx"><p className="sitesubtitle">Knoxx</p></NavLink>
                                <NavLink to="/colorbot-stella"><p className="sitesubtitle">Stella</p></NavLink>
                                <NavLink to="/colorbot-knoxx-quad"><p className="sitesubtitle">Knoxx Quad</p></NavLink>
                                <NavLink to="/colorbot-stella-quad"><p className="sitesubtitle">Stella Quad</p></NavLink>
                                <NavLink to="/dark"><p className="sitesubtitle">Dark</p></NavLink>
                                {/* <NavLink to="/colorbot-prizma"><p className="sitesubtitle">Prizma</p></NavLink> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-sm-6 col-xs-12 mt-4 mb-5">
                        <div className="sitewidth">
                            <div className="siteheight"></div>
                            <div className="sitemap-card">
                                <br />
                                <br />

                                <div className="sitemap-card-img ">
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/idea_site.svg" alt="" />
                                </div>
                                <h2>Discover Us</h2>
                                <br />
                                <NavLink to="/about"><p className="sitesubtitle">About Us</p></NavLink>
                                <NavLink to="/contact"><p className="sitesubtitle">Contact Us</p></NavLink>
                                <NavLink to="/blog"><p className="sitesubtitle">Blogs</p></NavLink>
                                <NavLink to="/accessories"><p className="sitesubtitle">Accessories</p></NavLink>
                                <NavLink to="/technology"><p className="sitesubtitle">Technology</p></NavLink>
                                <NavLink to="/sustainability"><p className="sitesubtitle">Sustainability</p></NavLink>


                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-sm-6 col-xs-12 mt-4 mb-5">
                        <div className="sitewidth">
                            <div className="siteheight"></div>
                            <div className="sitemap-card">
                                <br />
                                <br />

                                <div className="sitemap-card-img ">
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/policies_site.svg" alt="" />
                                </div>
                                <h2>Policies</h2>
                                <br />
                                <NavLink to="/return-policy"><p className="sitesubtitle">Return Policy</p></NavLink>
                                <NavLink to="/services-policies"><p className="sitesubtitle">Shipping policy</p></NavLink>
                                <NavLink to="/services-policies"><p className="sitesubtitle">Warranty policy</p></NavLink>
                                <NavLink to="/services-policies"><p className="sitesubtitle">Distributor Policy</p></NavLink>
                                <NavLink to="/services-policies"><p className="sitesubtitle">Payment security</p></NavLink>
                                <NavLink to="/services-policies"><p className="sitesubtitle">Customer grievance</p></NavLink>
                                <NavLink to="/privacy-policy"><p className="sitesubtitle">Privacy Policy</p></NavLink>
                                <NavLink to="/terms-conditions"><p className="sitesubtitle">Terms & Conditions</p></NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 col-sm-6 col-xs-12 mt-4 mb-5">
                        <div className="sitewidth">
                            <div className="siteheight"></div>
                            <div className="sitemap-card">
                                <br />
                                <br />

                                <div className="sitemap-card-img ">
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/link_site.svg" alt="" />
                                </div>
                                <h2>Important Links</h2>
                                <br />
                                <NavLink to="/warranty-registration"><p className="sitesubtitle">Register Warranty</p></NavLink>
                                <NavLink to="/track-order"><p className="sitesubtitle">Track your order</p></NavLink>
                                <NavLink to="/services-policies"><p className="sitesubtitle">Register New Complaint</p></NavLink>
                                <NavLink to="/faq"><p className="sitesubtitle">FAQ</p></NavLink>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </section>
    );
}
export default Sitemap;