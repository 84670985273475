import "./ContactUs.css";
import { NavLink } from "react-router-dom"
import React, { useState, useEffect, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';


const ContactHead = () => (

    <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Contact us for any inquiries and support regarding our fan products. Our experts are here to assist you in finding the perfect cooling solution for your home." />
        <link rel="canonical" href="https://colorbot.in/contact" />

        <meta property="og:title" content="Contact Us" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/contact" />
        <meta property="og:description" content="Contact us for any inquiries and support regarding our fan products. Our experts are here to assist you in finding the perfect cooling solution for your home." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/banners/Contact-banner.jpg" />
    </Helmet>
);

function ContactUs() {
    const [captchaCode, setCaptchaCode] = useState(generateCaptchaCode());
    const [captcha, setcaptcha] = useState("")
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [enquiryType, setEnquiryType] = useState('');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = "Contact Us - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Contact Us - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    const [isH3Visible, setIsH3Visible] = useState(false);
    function generateCaptchaCode() {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let captchaCode = '';
        for (let i = 0; i < 6; i++) {
            captchaCode += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return captchaCode;
    }

    function reloadCaptcha() {
        const newCaptchaCode = generateCaptchaCode();
        setCaptchaCode(newCaptchaCode);
    }
    const h3RefContact = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const h3Position = h3RefContact.current.offsetTop;
            const offset = window.innerHeight / 2;

            if (scrollPosition + offset >= h3Position) {
                setTimeout(() => {
                    setIsH3Visible(true);
                }, 500);
            } else {
                //   setIsH3Visible(false);
            }
            setScrollPosition(scrollPosition);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    useEffect(() => {
        // const title = h3RefContact.current;

        // if (title) {
        //     const rect = title.getBoundingClientRect();
        //     const offset = window.innerHeight / 2;

        //     if (rect.top <= offset) {
        //         title.classList.add('active');
        //     } else {
        //         // title.classList.remove('active');
        //     }
        // }
    }, [scrollPosition])
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');


    const handlePhoneChange = (e) => {
        const inputValue = e.target.value;
        setPhoneNumber(inputValue);

    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        setEmail(inputValue);

    };



    const handleSubmit = () => {

        const phoneRegex = /^\d{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (name === "") {
            toast.error("Please enter your name")
        } else if (phoneNumber === "") {
            toast.error("Please enter your phone number")
        } else if (!phoneRegex.test(phoneNumber)) {
            toast.error("Please enter a valid 10 digit Phone Number")
        } else if (email === "") {
            toast.error("Please enter your email address")
        }
        else if (!emailRegex.test(email)) {
            toast.error("Please enter a valid email address.")

        } else if (enquiryType === "") {
            toast.error("Please select enquiry type")
        } else if (message === "") {
            toast.error("Please enter your message")
        } else if (captcha !== captchaCode) {
            toast.error("Captcha Did Not Match")
            reloadCaptcha()

        } else {

            fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotContactUS`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: name,
                    email: email,
                    phone: phoneNumber,
                    enquiryType: enquiryType,
                    message: message
                })
            }).then(res => res.json()).then(data => {
                if (data.error) {
                    toast.error(data.error)
                } else {
                    toast.success(data.message)
                    setName("")
                    setEmail("")
                    setPhoneNumber("")
                    setEnquiryType("")
                    setMessage("")
                    setcaptcha("")
                    reloadCaptcha()
                }
            }).catch(err => {
                console.log(err)
            })


        }


    };
    return (
        <>
            <section className='mt-5 pt-4'>
            <ContactHead />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 padd-neg">
                            <div className="banner-img">
                                <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/banners/Contact-banner.jpg"}
                                    width={"100vw"}
                                    className="d-block w-100 img-fluid" alt="Contact Us"
                                    effect="blur"
                                />

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row justify-content-center pt-4 pb-4  mt-3">
                        <div className="col-lg-6">
                            <div className="contact-us">
                                <h1 className={`border-bottom-animation h3hoverbefore ${isH3Visible ? 'active' : ''}`} ref={h3RefContact}>Leave us a message</h1>
                                <br />
                                <input type="text" placeholder="Name" className="form-control form-control-lg" value={name} onChange={(e) => {
                                    setName(e.target.value)

                                }} />
                                <input
                                    type="text"
                                    placeholder="Phone*"
                                    className="form-control form-control-lg mt-3"
                                    value={phoneNumber}
                                    onChange={handlePhoneChange}
                                    minLength={10}
                                    maxLength={10}

                                />

                                <input
                                    type="email"
                                    placeholder="Email*"
                                    className="form-control form-control-lg mt-3"
                                    value={email}
                                    onChange={handleEmailChange}

                                />

                                <select className="form-control form-control-lg mt-3" style={{
                                    padding: "8px 25px",
                                    color: "#212121",
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal"
                                }} value={enquiryType} onChange={(e) => {
                                    setEnquiryType(e.target.value)
                                }}>

                                    <option value={""}>Enquire Type</option>
                                    <option value={"General Enquiry"}>General Enquiry</option>
                                    <option value={"Sales Enquiry"}>Sales Enquiry</option>
                                    <option value={"Support Enquiry"}>Support Enquiry</option>
                                    <option value={"Other"}>Other</option>


                                </select>

                                <textarea className="form-control form-control-lg mt-3" rows="3" placeholder="Your Message" value={message} onChange={(e) => {
                                    setMessage(e.target.value)

                                }}></textarea>
                                <div className="gridcontact mt-3">
                                    <div className="grid-itemcontact">
                                        <input type="text" className="form-control form-control-lg" placeholder="Captcha Code" value={captcha} onChange={(e) => {
                                            setcaptcha(e.target.value)
                                        }} />
                                    </div>
                                    <div className="cont-capt">
                                        <div className="grid-itemcontact">
                                            <div className="captcha">
                                                {captchaCode}
                                            </div>
                                        </div>
                                        <div className="grid-itemcontact">
                                            <button className="reload" onClick={() => { reloadCaptcha() }}>
                                                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/reload.svg" className="img-fluid" alt="reload" />
                                            </button>
                                        </div>
                                    </div>


                                </div>
                                <button className="btn btn-lg btn-primary mt-4 mb-4 contactsubmit" onClick={() => {
                                    handleSubmit()
                                }}>SUBMIT</button>
                            </div>

                        </div>
                        <div className="col-lg-5 alignmiddlecontact">
                            <div className="contact-usright">
                                <div className="contactimage"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/email.svg" className="img-fluid" alt="" /></div>
                                <div className="contacttext">
                                    <h2>Email us on</h2>
                                    <p>enquiry@colorbot.in</p>
                                </div>


                            </div>
                            <br />
                            <div className="contact-usright">
                                <div className="contactimage"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/phone.svg" className="img-fluid" alt="" /></div>
                                <div className="contacttext">
                                    <h2>Call us on</h2>
                                    <p>+91 92 8930 5540</p>
                                </div>


                            </div>
                            <br />
                            <div className="contact-usright blackcontact">

                                <div className="contacttextblack">
                                    <h2>Connect with us</h2>
                                    <div className="socio">
                                        <NavLink to="https://www.instagram.com/colorbot.in/" target="_blank"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/insta.svg" alt="instagram" /></NavLink>
                                        <NavLink to="https://www.facebook.com/colorbot.in" target="_blank"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/fb.svg" alt="facebook" /></NavLink>
                                        <NavLink to="https://twitter.com/Colorbot_in" target="_blank"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/twi.svg" alt="twitter" /></NavLink>
                                        <NavLink to="https://www.linkedin.com/company/colorbot-pvt-ltd/mycompany/" target="_blank"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/linkedln.svg" alt="linkedin" /></NavLink>
                                        <NavLink to="https://www.youtube.com/@colorbot" target="_blank"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/yout.svg" alt="youtube" /></NavLink>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default ContactUs;