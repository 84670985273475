
import "./Trackorder.css";

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';


const TrackHead = () => (

    <Helmet>
        <title>Track Your Order</title>
        <meta name="description" content="Track your order with Colorbot to get updates on the status and delivery of your purchase by entering your tracking details for real-time updates." />
        <link rel="canonical" href="https://colorbot.in/track-order" />

        <meta property="og:title" content="Track Your Order" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/track-order" />
        <meta property="og:description" content="Track your order with Colorbot to get updates on the status and delivery of your purchase by entering your tracking details for real-time updates." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="Imagelink" />
    </Helmet>
);
function Trackorder() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = "Track Your Order - Colorbot";// Set the title when component mounts
        return () => {
            document.title = "Track Your Order - Colorbot"; // Reset the title when component unmounts
        };
    }, []);
    const h3RefTrack = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isH3Visible, setIsH3Visible] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const h3Position = h3RefTrack.current.offsetTop;
            const offset = window.innerHeight / 2;

            if (scrollPosition + offset >= h3Position) {
                setTimeout(() => {
                    setIsH3Visible(true);
                }, 500);
            } else {
                //   setIsH3Visible(false);
            }
            setScrollPosition(scrollPosition);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        // const title = h3RefTrack.current;

        // if (title) {
        //     const rect = title.getBoundingClientRect();
        //     const offset = window.innerHeight / 2;

        //     if (rect.top <= offset) {
        //         title.classList.add('active');
        //     } else {
        //         // title.classList.remove('active');
        //     }
        // }
    }, [scrollPosition])
    const [phoneNumber, setPhoneNumber] = useState('');
    const [orderId, setOrderId] = useState('');
    const [trackingId, setTrackingId] = useState(false);
    const handleChange = (event) => {
        let enteredPhoneNumber = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (enteredPhoneNumber.length > 10) {
            enteredPhoneNumber = enteredPhoneNumber.slice(0, 10); // Trim to maximum 10 digits
        }
        const isValid = /^\d{10}$/.test(enteredPhoneNumber);

        setPhoneNumber(enteredPhoneNumber);

    };
    const handleSumbit = () => {
        if (trackingId) {
            if (orderId.length < 6) {
                toast.error("Please enter a valid 7-digit order id"); // Display an error toast notification
            } else if (phoneNumber.length < 10) {
                toast.error("Please enter a valid 10-digit phone number"); // Display an error toast notification
            } else if (!/^\d{10}$/.test(phoneNumber)) {
                toast.error("Please enter a valid 10-digit phone number"); // Display an error toast notification
            } else {
                let url = `https://shiprocket.co/tracking/order/${orderId}?company_id=4092080`

                window.open(url, '_blank');
                setOrderId('');
                setPhoneNumber('');
                setTrackingId(false);
            }
            return;
        } else {
            toast.error("Please select order id option"); // Display an error toast notification
        }


    }
    return (
        <>

            <section className='mt-5 pt-4'>
                <TrackHead />




                <div className="container-fluid mt-5">
                    <div className="row justify-content-center mt-4">
                        <div className="col-lg-8 mt-3 ">
                            <div className="Tracktitle">
                                <h1 className={`border-bottom-animation h3hoverbefore ${isH3Visible ? 'active' : ''}`} ref={h3RefTrack}>Track Your Order</h1>
                            </div>
                        </div>
                        <div className="col-lg-5 mt-2">
                            <div className="contact-usTrack mt-4 p-5">
                                <div className="row justify-content-center">
                                    {/* <div className="col-lg-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefaulttrack1" />
                                            <label className="form-check-label labeltitle track-text" htmlFor="flexRadioDefaulttrack1">
                                                Traking ID
                                            </label>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-4">
                                        <div className="form-check">
                                            <input className="form-check-input" checked={trackingId} onChange={() => {
                                                setTrackingId(!trackingId);
                                            }} type="radio" name="flexRadioDefault" id="flexRadioDefaulttrack2" />
                                            <label className="form-check-label labeltitle" htmlFor="flexRadioDefaulttrack2">
                                                Order ID
                                            </label>
                                        </div>

                                    </div>
                                </div>
                                <input type="text" value={orderId} maxLength={15} onChange={(e) => {
                                    setOrderId(e.target.value);
                                }} placeholder="Order Id*" className="form-control form-control-lg mt-3" />
                                <input type="text" value={phoneNumber}
                                    onChange={handleChange} placeholder="Enter Mobile Number*" className="form-control form-control-lg mt-4" />





                                <button className="btn btn-lg btn-primary mt-4 mb-4 contactsubmit" onClick={handleSumbit}>Track Order</button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />

            </section>









        </>
    );
}
export default Trackorder;