import { NavLink } from "react-router-dom";

const NotFound404 = () => {
    return (
        <section className=" mt-2 pt-5 pb-5">
            <div className="container pb-5">
                <div className="row justify-content-center mt-4">
                    <div className="col-md-4">
                        <div >
                            <div className="">
                                <img src="assets/404.svg" alt="" className="img-fluid" />
                            </div>
                            <div className="mainbox-para text-center">
                                <span>The page you are trying to access doesn’t exist or has been moved.Try going back to our homepage.</span>
                            </div>

                            <NavLink to="/">  <div className="button-my-order-empty">
                                <input className="btn button-signin " type="submit" value="Go to Home Page" />
                            </div></NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}
export default NotFound404;