import { NavLink } from "react-router-dom";
import "./signupsuccess.css"

function SignUpSuccess() {
    return (
        <>
            <section className="signback mt-5 pt-5 pb-5">
                <div className="container">
                    <div className="row justify-content-center  sign-box">

                        <div className="col-lg-6">
                            <div className="boxxxxxy1-3">
                                <div className="text-center">
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/charm_circle-tick.svg" alt=""/>
                                </div>
                                <div className="signup-success text-center">
                                    <span>Sign Up Successful!</span>
                                </div>
                                <div className="description1 text-center">
                                    <span>Welcome To Colorbot</span>
                                </div>

                                <div className=" button-signup-success">
                                 <NavLink to="/login">   <input className="btn button-signin" type="submit" value="Okay"/></NavLink>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default SignUpSuccess;