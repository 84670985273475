import { NavLink, useNavigate } from "react-router-dom";
import "./profile.css"
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function Profile(props) {
    const [lgShow, setLgShow] = useState(false);
    const [lgShow1, setLgShow1] = useState(false);
    const [buttontype, setbuttontype] = useState("Update")
    const [addresstype, setaddresstype] = useState("Add")
    const navigate = useNavigate()
    function Logout() {
        setLgShow1(true)

    }
    function Logoutmain() {
        window.localStorage.setItem('LoggedIn', false)
        props.setLoggedIn(false)
        window.localStorage.clear()
        navigate("/")
    }
    const [number, setNumber] = useState("")
    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [showtab, setShowtab] = useState(1)
    const [Country, setCountry] = useState("India")
    const [State, setState] = useState("")
    const [City, setCity] = useState("")
    const [pincode, setPincode] = useState("")
    const [Address, setAddress] = useState("")
    const [Address1, setAddress1] = useState("")
    const [addfirst, setaddfirst] = useState("")
    const [addlast, setaddlast] = useState("")
    const [addphone, setaddphone] = useState("")
    const [addtype, setaddtype] = useState(false)
    const [error, setError] = useState("")
    const [addressdata, setaddressdata] = useState([])
    const [addressindex, setaddressindex] = useState(0)


    useEffect(() => {
        fetchuser()
        window.scrollTo(0, 0, {
            behavior: "smooth"
        })
    }, [])

    useEffect(() => {
        document.title = "Profile - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Profile - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    const handleChange = (event) => {
        let enteredPhoneNumber = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (enteredPhoneNumber.length > 10) {
            enteredPhoneNumber = enteredPhoneNumber.slice(0, 10); // Trim to maximum 10 digits
        }
        // const isValid = /^\d{10}$/.test(enteredPhoneNumber);

        setNumber(enteredPhoneNumber);
        // if(isValid === false){
        //     toast.error("Please enter a valid 10-digit phone number"); // Display an error toast notification
        // }

    };
    const handleEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);

        // Regular expression for email validation
        // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // const isValidEmail = emailPattern.test(inputEmail);
        // if(isValidEmail === false){
        //     toast.error("Please enter a valid email"); // Display an error toast notification
        // }
    };
    const updateuser = async () => {
        if (firstName.length < 1) {
            toast.error("Please enter a valid First Name"); // Display an error toast notification
        } else if (lastName.length < 1) {
            toast.error("Please enter a valid Last Name"); // Display an error toast notification
        } else if (/^\d{10}$/.test(number) === false) {
            toast.error("Please enter a valid 10-digit phone number"); // Display an error toast notification
        } else if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) === false) {
            toast.error("Please enter a valid email"); // Display an error toast notification
        } else {
            try {
                await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotUserUpdate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                    },

                    body: JSON.stringify({ Phone: "+91" + number, UserId: window.localStorage.getItem('UserId'), FirstName: firstName, LastName: lastName, Email: email }),
                }).then((res) => res.json()).then((response) => {


                    if (response.status === 200) {

                        window.localStorage.setItem('token', response.data.token);
                        window.localStorage.setItem('UserId', response.data.UserId);
                        if (response.data.Email !== undefined) { window.localStorage.setItem("Email", response.data.Email) }
                        if (response.data.FirstName !== undefined) { window.localStorage.setItem("FirstName", response.data.FirstName) }
                        if (response.data.LastName !== undefined) { window.localStorage.setItem("LastName", response.data.LastName) }
                        if (response.data.Address !== undefined) { window.localStorage.setItem("Address", JSON.stringify(response.data.Address)) }


                        fetchuser()
                        setLgShow(false)
                        toast.success(response.message);
                    } else {

                        toast.error(response.message);
                    }
                })
            } catch (error) {
                console.error('API Error:', error);
                toast.error(error);
            }
        }

    }
    const fetchuser = async () => {
        if (window.localStorage.getItem("phoneNumber")) {
            setNumber(window.localStorage.getItem("phoneNumber").slice(3, 13))
        }
        if (window.localStorage.getItem("Email")) {
            setEmail(window.localStorage.getItem("Email"))
        }
        if (window.localStorage.getItem("FirstName")) {
            setFirstName(window.localStorage.getItem("FirstName"))
        }
        if (window.localStorage.getItem("LastName")) {
            setLastName(window.localStorage.getItem("LastName"))
        }
        if (window.localStorage.getItem("Address")) {
            setaddressdata(JSON.parse(window.localStorage.getItem("Address")))
            if(JSON.parse(window.localStorage.getItem("Address")).length === 0){
                setaddphone(window.localStorage.getItem("phoneNumber").slice(3, 13))
            }
        } else {
            setaddressdata([])
            if(JSON.parse(window.localStorage.getItem("Address")).length === 0){
                setaddphone(window.localStorage.getItem("phoneNumber").slice(3, 13))
            }
        }


    }

    const addaddress = async () => {
        if (Country.length < 1) {
            toast.error("Please enter a valid Country"); // Display an error toast notification
        } else if (addfirst.length < 1) {
            toast.error("Please enter a valid First Name"); // Display an error toast notification
        } else if (addlast.length < 1) {
            toast.error("Please enter a valid Last Name"); // Display an error toast notification
        } else if (Address.length < 1) {
            toast.error("Please enter a valid Address"); // Display an error toast notification
        } else if (pincode.length !== 6) {
            toast.error("Please enter a valid Pincode"); // Display an error toast notification
        } else if (City.length < 1) {
            toast.error("Please enter a valid City"); // Display an error toast notification
        } else if (State.length < 1) {
            toast.error("Please enter a valid State"); // Display an error toast notification
        } else if (/^[6-9]\d{9}$/.test(addphone) === false) {
            toast.error("Please enter a valid 10-digit phone number"); // Display an error toast notification
        } else {
            var data = {
                "FirstName": addfirst,
                "LastName": addlast,
                "Address": Address,
                "Address1": Address1,
                "Pincode": pincode,
                "City": City,
                "State": State,
                "Country": Country,
                "Phone": "+91" + addphone,
                "AddressType": addtype === true ? "Default" : "Other",



            }
            var addressarr = []
            var address = window.localStorage.getItem("Address") === "" ? null : JSON.parse(window.localStorage.getItem("Address"))
            if (address === null) {
                addressarr.push(data)
            } else {
                addressarr = address
                if (addressindex === null) {

                    addressarr.push(data)
                } else {
                    addressarr[addressindex] = data
                }
            }
            if (addtype === true) {
                for (let i = 0; i < addressarr.length; i++) {
                    if (i === addressindex && addtype === true) {
                        addressarr[i].AddressType = "Default"
                    } else {
                        addressarr[i].AddressType = "Other"
                    }
                }
            }
            try {
                await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotUserAddressUpdate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                    },

                    body: JSON.stringify({ Address: addressarr, Phone: window.localStorage.getItem("phoneNumber") }),
                }).then((res) => res.json()).then((response) => {


                    if (response.status === 200) {

                        window.localStorage.setItem('token', response.data.token);
                        window.localStorage.setItem('UserId', response.data.UserId);
                        if (response.data.Email !== undefined) { window.localStorage.setItem("Email", response.data.Email) }
                        if (response.data.FirstName !== undefined) { window.localStorage.setItem("FirstName", response.data.FirstName) }
                        if (response.data.LastName !== undefined) { window.localStorage.setItem("LastName", response.data.LastName) }
                        if (response.data.Address !== undefined) { window.localStorage.setItem("Address", JSON.stringify(response.data.Address)) }
                        setAddress("")
                        setAddress1("")
                        setaddlast("")
                        setaddfirst("")
                        setaddphone("")
                        setaddtype(false)
                        setPincode("")
                        setCity("")
                        setState("")
                        setCountry("India")



                        fetchuser()
                        setLgShow(false)
                        toast.success(response.message);
                    } else {

                        toast.error(response.message);
                    }
                })
            } catch (error) {
                console.error('API Error:', error);
                toast.error(error);
            }

        }
    }
    const checkpincode = (e) => {
        setCity('');
        setState('');
        var value = e.target.value;
        setPincode(value);

        if (value.length === 6) {
            setError('');

            axios
                .get(`https://api.postalpincode.in/pincode/${value}`)
                .then((res) => {
                    setState(res.data[0].PostOffice[0].State);
                    setCity(res.data[0].PostOffice[0].Block);


                })
                .then(() => {

                })
                .catch((err) => {

                    setError('Invalid PIN Code');
                });
        }

        if (value.length !== 6) {
            setCity('');

            setState('');
            setError('Pincode must be of 6 digits');
        }

    }
    const updateAddress = async (index) => {
        setaddressindex(index)
        setAddress(addressdata[index].Address)
        setAddress1(addressdata[index].Address1)
        setaddlast(addressdata[index].LastName)
        setaddfirst(addressdata[index].FirstName)
        setaddphone(addressdata[index].Phone.slice(3, 13))
        setaddtype(addressdata[index].AddressType === "Default" ? true : false)
        setPincode(addressdata[index].Pincode)
        setCity(addressdata[index].City)
        setState(addressdata[index].State)
        setCountry(addressdata[index].Country)
        if (addressdata[index].AddressType === "Default") {
            setaddtype(true)
        } else {
            setaddtype(false)
        }
    }
    const deleteAddress = async (index) => {
        addressdata.splice(index, 1)
        try {
            await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotUserAddressDelete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },

                body: JSON.stringify({ Address: addressdata, Phone: window.localStorage.getItem("phoneNumber") }),
            }).then((res) => res.json()).then((response) => {


                if (response.status === 200) {

                    window.localStorage.setItem('token', response.data.token);
                    window.localStorage.setItem('UserId', response.data.UserId);
                    if (response.data.Email !== undefined) { window.localStorage.setItem("Email", response.data.Email) }
                    if (response.data.FirstName !== undefined) { window.localStorage.setItem("FirstName", response.data.FirstName) }
                    if (response.data.LastName !== undefined) { window.localStorage.setItem("LastName", response.data.LastName) }
                    if (response.data.Address !== undefined) { window.localStorage.setItem("Address", JSON.stringify(response.data.Address)) } else {
                        window.localStorage.setItem("Address", "")
                    }
                    setAddress("")
                    setAddress1("")
                    setaddlast("")
                    setaddfirst("")
                    setaddphone("")
                    setaddtype(false)
                    setPincode("")
                    setCity("")
                    setState("")
                    setCountry("India")


                    fetchuser()
                    setLgShow(false)
                    toast.success(response.message);
                } else {

                    toast.error(response.message);
                }
            })
        } catch (error) {
            console.error('API Error:', error);
            toast.error(error);
        }
    }
    return (
        <>

            <section className="signback mt-5 pt-3 pb-5">
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"

                    centered
                    show={lgShow1}

                    onHide={() => setLgShow1(false)}

                >
                    <Modal.Header closeButton style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>

                    </Modal.Header>
                    <Modal.Body style={{ paddingTop: "0rem" }}>


                        <div className="box-profile-pop-profile">



                            <div className="logoutpop">
                                <img src="assets/Vector.svg" className="img-fluid" alt="exlam" />
                            </div>
                            <div className="logoutpop mt-2">
                                <p>Are you sure you want to logout?</p>
                            </div>
                            <div className="logoutpop button">
                                <button className="btn btncancellog" onClick={() => setLgShow1(false)}>
                                    Cancel
                                </button>
                                <button className="btn btnsubmitlog" onClick={() => {
                                    Logoutmain()
                                }}>Logout</button>
                            </div>







                        </div>


                    </Modal.Body>
                </Modal>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    style={{ marginTop: "0px" }}
                    centered
                    show={lgShow}

                    onHide={() => setLgShow(false)}

                >
                    <Modal.Header closeButton style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>

                    </Modal.Header>
                    <Modal.Body style={{ paddingTop: "0rem" }}>
                        {showtab === 1 ?

                            <div className="profile-pop-boxxx">


                                <div className="profile-pop-box2">
                                    <div className="profile-pop-text text-center">
                                        <span>{addresstype} Address</span>

                                    </div>
                                    <div className="form-profile-pop">

                                        <select className="form-select  form-alignment" value={Country} onChange={(e) => {
                                            setCountry(e.target.value)
                                        }}>

                                            <option value="India">India</option>

                                        </select>
                                        <div className="row">
                                            <div className="col-md-6 form-partition">
                                                <input type="text" className="form-control form-alignment" placeholder="First Name" value={addfirst} onChange={(e) => {
                                                    setaddfirst(e.target.value)
                                                }} />
                                            </div>
                                            <div className="col-md-6 form-partition">
                                                <input type="text" className="form-control form-alignment" placeholder="Last Name" value={addlast} onChange={(e) => {
                                                    setaddlast(e.target.value)
                                                }} />
                                            </div>
                                        </div>



                                        <input type="text" className="form-control form-alignment" placeholder="Address 1" value={Address} onChange={(e) => {
                                            setAddress(e.target.value)
                                        }} />

                                        <input type="text" className="form-control form-alignment" placeholder="Address 2" value={Address1} onChange={(e) => {
                                            setAddress1(e.target.value)
                                        }} />

                                        <div className="row">
                                            <div className="col-md-4 form-partition">
                                                <input type="text" className="form-control form-alignment " placeholder="PIN Code" value={pincode} onChange={(e) => {
                                                    checkpincode(e)
                                                }} />
                                            </div>
                                            <div className="col-md-4 form-partition">
                                                <input type="text" className="form-control form-alignment " placeholder="City" readOnly value={City} onChange={(e) => {
                                                    setCity(e.target.value)
                                                }} />
                                            </div>
                                            <div className="col-md-4 form-partition">
                                                <input type="text" className="form-control form-alignment " placeholder="State" readOnly value={State} onChange={(e) => {
                                                    setState(e.target.value)
                                                }} />
                                            </div>
                                        </div>
                                        <span className="text-danger " style={{ marginTop: "1px" }}>{error}</span>

                                        <input type="number" className="form-control form-alignment " placeholder="Phone" value={addphone} onChange={(e) => {
                                            setaddphone(e.target.value)
                                        }} />


                                    </div>

                                    <div className="check-box d-flex">
                                        <input type="checkbox" checked={addtype} onChange={() => {
                                            setaddtype(!addtype)
                                        }} /><span>Set as default address</span>
                                    </div>

                                    <div className="d-flex justify-content-center">
                                        <input className="btn button-profile-pop button-signin" type="button" value={buttontype} onClick={() => {
                                            addaddress()
                                        }} />
                                    </div>



                                </div>
                            </div>

                            : ""}
                        {showtab === 2 ?
                            <div className="box-profile-pop-profile">




                                <div className="second-box-pop">
                                    <div className=" spna-text text-center">
                                        <span>Edit Profile</span>
                                    </div>
                                    <div className="row form-pop ">
                                        <div className="col-md-6">
                                            <input type="text" className="form-control form-alignment" placeholder="First Name" value={firstName} onChange={(e) => {
                                                setFirstName(e.target.value)
                                            }} />
                                            <input type="number" className="form-control form-alignment" placeholder="Phone" value={number} readOnly onChange={(e) => {
                                                handleChange(e)
                                            }} />

                                        </div>
                                        <div className="col-md-6">
                                            <input type="text" className="form-control form-alignment" placeholder="Last Name" value={lastName} onChange={(e) => {
                                                setLastName(e.target.value)
                                            }} />
                                            <input type="email" className="form-control form-alignment" placeholder="Email" value={email} onChange={(e) => {
                                                handleEmailChange(e)
                                            }} />

                                        </div>

                                        <div className="button-pop d-flex justify-content-center">
                                            <input className="btn button-signin" type="button" value="Save" onClick={() => {
                                                updateuser()
                                            }} />
                                        </div>
                                    </div>
                                </div>


                            </div>
                            : ""}

                    </Modal.Body>
                </Modal>
                <div className="container">
                    <div className="row justify-content-center mt-5">
                        <div className="col-lg-3 profilecolwidth mt-3">
                            <div className="profile_left">

                                <NavLink to="/Order" className="nodecoration">  <div className="tab_left">
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/myorder_left.svg" className="img-fluid" alt="order" />&nbsp;&nbsp;&nbsp; My Orders
                                </div></NavLink>
                                <NavLink to="/profile" className="nodecoration"> <div className="tab_left tab-leftactive">
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/profile_left.svg" className="img-fluid" alt="profile" />&nbsp;&nbsp;&nbsp;&nbsp; Profile
                                </div></NavLink>
                                <div className="tab_left" onClick={() => {
                                    Logout()
                                }}>
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/logout_left.svg" className="img-fluid" alt="logout" />&nbsp;&nbsp;&nbsp;&nbsp; Logout
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-9 mt-3">
                            <div className="modal-box">

                                <div className="modal-text">
                                    <span>Profile</span>
                                    <div>
                                        <button className="edit" onClick={() => {
                                            setLgShow(true);
                                            setShowtab(2)

                                        }}> <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/edit.icon.svg" alt="edit" style={{ marginTop: "-4px" }} />&nbsp;
                                            Edit</button>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-profile">
                                            <input type="text" className="form-control form-alignment" placeholder="First Name" readOnly value={firstName} onChange={(e) => {
                                                setFirstName(e.target.value)
                                            }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-profile">
                                            <input type="text" className="form-control form-alignment" placeholder="Last Name" readOnly value={lastName} onChange={(e) => {
                                                setLastName(e.target.value)
                                            }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-profile">
                                            <input type="number" className="form-control form-alignment" placeholder="Phone" readOnly value={number} onChange={(e) => {
                                                handleChange(e)
                                            }} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-profile">
                                            <input type="email" className="form-control form-alignment" placeholder="Email" readOnly value={email} onChange={(e) => {
                                                handleEmailChange(e)
                                            }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-text2">
                                    <span>Addresses</span>
                                    <div>
                                        <button className="add" onClick={() => {
                                            setLgShow(true);
                                            setShowtab(1)
                                            setaddresstype("Add")
                                            setbuttontype("Save")
                                            setAddress("")
                                            setAddress1("")
                                            setaddlast("")
                                            setaddfirst("")
                                            JSON.parse(window.localStorage.getItem("Address")).length === 0 ? setaddphone(window.localStorage.getItem("phoneNumber").slice(3, 13)) : setaddphone("")
                                           
                                            setaddtype(false)
                                            setPincode("")
                                            setCity("")
                                            setState("")
                                            setCountry("India")
                                            setaddressindex(null)

                                        }}><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/plus.svg" alt="" />&nbsp;
                                            Add</button>

                                    </div>
                                </div>
                                <div className="row">
                                    {addressdata.length > 0 && addressdata.map((item, index) => {
                                        return (
                                            <div className="col-md-6" key={index}>
                                                <div className="address-bar-box">
                                                    <div className="fullname-name">
                                                        <span>{item.FirstName} {item.LastName} &nbsp;<span style={{ fontSize: "10px", fontWeight: "400" }}>({item.AddressType})</span></span>
                                                        <div className="iconny-image">
                                                            <button className="edit" onClick={() => {
                                                                setLgShow(true);
                                                                setShowtab(1)
                                                                setaddresstype("Edit")
                                                                setbuttontype("Update")
                                                                updateAddress(index);

                                                            }}><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/edit.icon.svg" alt="edit" className="img-fluid px-1" /></button> &nbsp;
                                                            <button className="delete" onClick={() => {
                                                                deleteAddress(index)
                                                            }}><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/delete icon.svg" alt="delete" className="img-fluid" /></button>
                                                        </div>

                                                    </div>

                                                    <div className="address-text2 mb-2">

                                                        <span>{item.Address} {item.Address1}, {item.City}, {item.State}, {item.Pincode}</span>
                                                    </div>

                                                    <div className="address-text3">
                                                        <p><b>{item.Phone.slice(3, 13)} </b></p>
                                                    </div>


                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>


                            </div>

                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}
export default Profile;