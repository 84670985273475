import "./Technology.css"
import React, { useRef, useEffect, useState } from 'react';
import FadeInSection from "../FadeInup/FadeInup";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from 'react-helmet';


const TechnologyHead = () => (

    <Helmet>
        <title>Reinventing Ceiling Fan Motors with BLDC Technology - Colorbot</title>
        <meta name="description" content="Explore Colorbot high-quality BLDC fans which utilize cutting-edge technology with microprocessor chip functioning, ensuring smoother and efficient operation." />
        <link rel="canonical" href="https://colorbot.in/technology" />

        <meta property="og:title" content="Reinventing Ceiling Fan Motors with BLDC Technology - Colorbot" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/technology" />
        <meta property="og:description" content="Explore Colorbot high-quality BLDC fans which utilize cutting-edge technology with microprocessor chip functioning, ensuring smoother and efficient operation." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/Technology/Techno-Chip.png" />

    </Helmet>
);

function Technology() {
    const h3Ref = useRef(null);
    const h3Ref1 = useRef(null);
    const h3Ref2 = useRef(null);
    const h3Ref3 = useRef(null);
    const [isH3Visible, setIsH3Visible] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = "Technology - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Technology - Colorbot"; // Reset the title when component unmounts
        };
    }, []);


    const [scrollPosition, setScrollPosition] = useState(0);


    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const h3Position = h3Ref.current.offsetTop;
            const offset = window.innerHeight / 2;

            if (scrollPosition + offset >= h3Position) {
                setTimeout(() => {
                    setIsH3Visible(true);
                }, 500);
            } else {
                //   setIsH3Visible(false);
            }


            setScrollPosition(scrollPosition);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // const title = h3Ref.current;

        // if (title) {
        //     const rect = title.getBoundingClientRect();
        //     const offset = window.innerHeight / 2;

        //     if (rect.top <= offset) {
        //         title.classList.add('active');
        //     } else {
        //         title.classList.remove('active');
        //     }
        // }
        const title1 = h3Ref1.current;

        if (title1) {
            const rect1 = title1.getBoundingClientRect();
            const offset1 = window.innerHeight / 2;

            if (rect1.top <= offset1) {
                title1.classList.add('active');
            } else {
                // title1.classList.remove('active');
            }
        }
        const title2 = h3Ref2.current;

        if (title2) {
            const rect2 = title2.getBoundingClientRect();
            const offset2 = window.innerHeight / 2;

            if (rect2.top <= offset2) {
                title2.classList.add('active');
            } else {
                // title2.classList.remove('active');
            }
        }
        const title3 = h3Ref3.current;

        if (title3) {
            const rect3 = title3.getBoundingClientRect();
            const offset3 = window.innerHeight / 2;

            if (rect3.top <= offset3) {
                title3.classList.add('active');
            } else {
                // title3.classList.remove('active');
            }
        }
    }, [scrollPosition]);


    return (

        <section className="mt-5 pt-4">
            <TechnologyHead />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 padd-neg">
                        <div className="banner-img">
                            <h1 className="banner-text">Technology </h1>
                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Techbanner.svg"}
                                width={"100vw"}
                                className="d-block w-100 img-fluid" alt="..."
                                effect="blur"
                            />

                        </div>
                    </div>
                </div>
            </div>




            <div className="container">
                <FadeInSection>
                    <div className="row">
                        <div className="col-md-12 txt-sec tectext">

                            <h2 className={`border-bottom-animation h3hoverbefore ${isH3Visible ? 'active' : ''}`} ref={h3Ref}>BLDC- Reinventing Ceiling Fan Motors</h2>
                            <p>BLDC, a revolutionary technology in fans that combines efficiency, performance, and
                                durability with design. Applying this cutting-edge technology, Colorbot BLDC fans bring to
                                you better comfort at lesser energy consumption.</p>
                            <br />
                            <p> The motor uses Carbon brushless operation,
                                resulting in a heatless, noiseless and carbon-
                                less sustainable performance. Because of its
                                energyefficiency, a Colorbot BLDC fan runs for
                                a longer period of time on inverters.
                            </p>


                        </div>
                        <div className="col-md-12 mb-5">
                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Technology/Techno-motor.png"}
                                width={"100%"}
                                className="img-fluid brdr-tech" alt="BLDC motor"
                                effect="blur"
                            />

                        </div>

                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="row">
                        <div className="col-md-5  left-text">

                            <p className="top-tag" >Color-Byte</p >
                            <h2 className="border-bottom-animation1 h3hover1before tech-smart" ref={h3Ref1}>Smarter & Better</h2>
                            <p>Fans are outfitted with smarter
                                electronic algorithm for a real-time
                                motor control and optimum efficiency.
                                The Ul-based technology ensures fan's smooth
                                operation at voltage fluctuations, current surges
                                and coil heating
                            </p>

                        </div>

                        <div className="col-md-7">
                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Technology/Techno-Chip.png"}
                                width={"100%"}
                                className="img-fluid mb-5 mt-5" alt="BLDC chip"
                                effect="blur"
                            />


                        </div>
                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="row alignreverse">
                        <div className="col-md-7 col-lg-7">
                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Technology/Techno-remote.jpg"}
                                width={"100%"}
                                className="img-fluid mb-5 mt-5" alt="Colorbot BLDC fan remote"
                                effect="blur"
                            />



                        </div>


                        <div className="col-md-5 col-lg-5 right-text">

                            <p className="top-tag" >Remote Control</p >
                            <h2 className="border-bottom-animation h3hover2before" ref={h3Ref2}>Air Under Control</h2>
                            <p>Change fan modes, set timers and speed
                                with a click.
                                Integrated features come in sleek and
                                handy remote allow you to access control
                                from even a distance of 20 ft.</p>

                        </div>
                    </div>
                </FadeInSection>
                <FadeInSection>
                    <div className="row">
                        <div className="col-md-12 middle-text">

                            <p className="top-tag" >Design & Dynamic</p >
                            <h2 className="border-bottom-animation h3hover3before" ref={h3Ref3}>Elevate Your Air</h2>
                            <p>Our advanced motor technology not only complements the fan's aesthetics but also
                                enhances its overall efficiency and durability. Colorbot fans now feature
                                aerodynamically designed blades with optimum lengths and precise angles.
                                These innovative blade designs ensure better airflow and distribution,
                                providing a refreshing breeze throughout the room. With this improvement,
                                our fans are perfectly stylish but also highly effective in maintaining a
                                comfortable environment.</p>


                        </div>
                        <div className="col-md-12 text-center">
                            <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Technology/Techno-sustain.png"}
                                width={"100%"}
                                className="img-fluid mb-5 mt-5" alt="An image of windmills"
                                effect="blur"
                            />

                        </div>
                    </div>
                </FadeInSection>
            </div>
        </section>















    )
}
export default Technology