import "./FadeInup.css"
import React, { useEffect, useRef, useState } from 'react';

function FadeInSection(props) {
    const [isAnimated, setIsAnimated] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const elementRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (elementRef.current) {
                const elementTop = elementRef.current.getBoundingClientRect().top;
                const elementBottom = elementRef.current.getBoundingClientRect().bottom;

                if (elementTop < window.innerHeight && elementBottom > 0 && !isAnimated) {
                    setIsAnimated(true);
                    setIsVisible(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    return (
        <div
            className={`fade-in-up ${isVisible ? 'is-visible' : ''}`}
            ref={elementRef}
        >
            {props.children}
        </div>
    );
}
export default FadeInSection;