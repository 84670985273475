import { NavLink } from "react-router-dom";
import "./Forget.css"

function Forget() {
    

    return (
        <>
            <section className="signback mt-5 pt-5 pb-5">
                
                <div className="container" >
                    <div className="row justify-content-center mt-4">

                        <div className="col-md-6">
                            <div className="boxxxy">
                                <div className="heading-textt">
                                    <p> Forgot Password?</p>

                                </div>
                                <div className="heading-textt2">
                                    <p>We will send you an email to reset <br/> your password.</p>
                                </div>
                                <div>
                                    <input type="email" className="form-control form-alignment form-forgot-pass" placeholder="Enter Your Email ID"/>
                                </div>
                                <div>
                                   <NavLink to="/verify_otp"> <input className="btn buton-forgot-pass button-signin" type="submit" value="Submit"/></NavLink>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                
            </section>
        </>
    )
}
export default Forget;