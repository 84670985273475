import "./Sustainability.css";
import FadeInSection from "../FadeInup/FadeInup";
import { useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from 'react-helmet';


const SustainabilityHead = () => (

    <Helmet>
        <title>Sustainabe Approach by Colorbot</title>
        <meta name="description" content="Explore the ultra-efficient ceiling fans with no carbon emissions. Colorbot’s sustainable approach aims for Zero Carbon and Zero Waste with renewable fans." />
        <link rel="canonical" href="https://colorbot.in/sustainability" />

        <meta property="og:title" content="Sustainabe Approach by Colorbot" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/sustainability" />
        <meta property="og:description" content="Explore the ultra-efficient ceiling fans with no carbon emissions. Colorbot’s sustainable approach aims for Zero Carbon and Zero Waste with renewable fans." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/Sustainability/Sustainability-img2.png" />
    </Helmet>
);

function Sustainability() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        document.title = "Sustainability - Colorbot";// Set the title when component mounts
        return () => {
            document.title = "Sustainability - Colorbot"; // Reset the title when component unmounts
        };
    }, []);
    // LOADER CODE
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setShowLoader(false)
        }, 2000)
    }, [])

    return (

        showLoader ? <>
            <section className="mt-5 pt-4 loader-align">
                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/loader-gif.gif"></img>
                <p>LOADING COLORBOT...</p>
            </section>
        </>
            :

            <section className="mt-5 pt-4">
                <SustainabilityHead/>
                <div className="suscontainer iphone-suscontainer">

                    <div className="suscontainer-text ">
                        <h1>Colorbot Cares about Tomorrow</h1>
                        <h6>#thegreen<span style={{ color: "#027600" }}>spin</span></h6>
                    </div>

                    <div className="container ">
                        {/* <div className="suscontainer-text ">
                            <h3>Colorbot Cares about Tomorrow</h3>
                            <h6>#thegreen<span style={{ color: "#027600" }}>spin</span></h6>
                        </div> */}
                        <br />
                        <br />
                        <FadeInSection>
                            <div className="suscontainer-text1 mt-5">
                                <div className="row justify-content-center mt-5 sust_align">
                                    <div className="col-lg-8 col-md-6 sust_text2">
                                        <h2 >For the <span style={{ color: "#027600" }}>World</span>, We <span style={{ color: "#027600" }}>Care</span></h2>
                                        <br />
                                        <h6>With BLDC technology, fans offer the feel of fresh air for your home,<br />
                                            while reduce the amount of Carbon footprint for the planet.</h6>
                                    </div>
                                    <div className="col-lg-4 col-md-6 text-center sustimagealign">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Sustainability/Sustainability-img1.png"}

                                            className="img-fluid" alt="An image symbolizes that Colorbot fans can reduce carbon footprint"
                                            effect="blur"
                                        />

                                    </div>
                                </div>
                            </div>
                        </FadeInSection>
                        <FadeInSection>
                            <div className="suscontainer-text2 mt-5">
                                <div className="row  mt-5 sust_align">
                                    <div className="col-lg-4 col-md-6 sustimagealign">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Sustainability/Sustainability-img2.png"}

                                            className="img-fluid" alt="Image shows energy efficient Colorbot fans are eco-friednly"
                                            effect="blur"
                                        />

                                    </div>
                                    <div className="col-lg-8 col-md-6 sust_text3">
                                        <h2 >Our  <span style={{ color: "#027600" }}>Approach</span>
                                        </h2>
                                        <br />
                                        <h6>We understand that even a small step towards environment safety can achieve something big in the future. Our fans have no carbon emissions which takes us a step closer towards Carbon neutrality. With Carbon-free, energy efficient fans, we  save power for your home as well as for the planet earth for upcoming generations.
                                            <br /><br />
                                            We have reduced the use of plastic  wherever we could do. The packaging is done with non-hazardous, bio degradable materials. From carbon-free motors  to packaging and delivering, we make sure everything is conducted through a planet-friendly  and sustainable approach.
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </FadeInSection>

                        <div className="suscontainer-text3 mt-5">
                            <FadeInSection>
                                <div className="row  mt-5 sust_align">
                                    <div className="col-lg-8 col-md-6 sust_text4">
                                        <h2 >Because <span style={{ color: "#027600" }}>Less</span> is <span style={{ color: "#027600" }}>More</span>
                                        </h2>
                                        <br />
                                        <h6><ul>
                                            <li>
                                                Colorbot fans reduce electricity consumption up to 65%.
                                            </li>
                                            <li>
                                                Lessening up more than 25% Carbon footprint with brushless motor technology.
                                            </li>
                                            <li>Our fans ensure Lesser energy take-up and greater performance.</li>
                                            <li>When we say we use less, we actually use the least- just 28 watts of energy, and that’s it.</li>
                                        </ul></h6>
                                    </div>
                                    <div className="col-lg-4 col-md-6 sustimagealign">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Sustainability/Sustainability-img3.png"}

                                            className="img-fluid" alt="Image shows how Colorbot fans reduce energy consumption"
                                            effect="blur"
                                        />

                                    </div>
                                </div>
                            </FadeInSection>

                        </div>
                        <div className="suscontainer-text5 ">
                            <FadeInSection>
                                <div className="row  mt-5 sust_align">
                                    <div className="col-lg-4 col-md-6  sustimagealign">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Sustainability/Sustainability-img4.png"}

                                            className="img-fluid" alt="Image shows the sustainable approach of Colorbot"
                                            effect="blur"
                                        />

                                    </div>
                                    <div className="col-lg-8 col-md-6 sust_text5">
                                        <h2 ><span style={{ color: "#027600" }}>R</span>educed. <span style={{ color: "#027600" }}>R</span>eused. <span style={{ color: "#027600" }}>R</span>ecycled
                                        </h2>
                                        <br />
                                        <h6>No waste remains waste. Everything generated during manufacturing and packaging is recycled and then reused, and everything that is not required is reduced in any further processing.
                                        </h6>
                                        <br />
                                        {/* <p>Know more about Colorbot's waste management.</p> */}
                                    </div>
                                </div>
                            </FadeInSection>
                        </div>

                        <div className="suscontainer-text6 ">
                            <FadeInSection>
                                <h2 style={{ color: "#027600" }}>Our Target is <span style={{ color: "#ffffff" }}>Zero</span></h2>
                                <h6>Towards 2030, we are determined to achieve
                                    Zero Carbon and Zero Waste.</h6>
                            </FadeInSection>
                        </div>


                    </div>




                </div>


            </section>

    );
}

export default Sustainability;