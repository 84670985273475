import React from "react";
import "./QuantityModule.css";
const ProductQuantitySelect = (props) => {
    

    const handleAddQuantity = () => {
        if (props.initialQuantity  < 100) {
            let newQuantity = Math.min(parseInt(props.initialQuantity) + 1, 100);
            props.handleChangeQuantity(props.id, parseInt(newQuantity));
           
           
        }
    };

    const handleRemoveQuantity = () => {
        if (props.initialQuantity  > 1) {
            let newQuantity = Math.max(parseInt(props.initialQuantity)  - 1, 1);
            props.handleChangeQuantity(props.id, parseInt(newQuantity));
            
        }
    };

    return (
        <div className="quantityAlignCenter">
            <button className="buttonquantity" onClick={handleRemoveQuantity}>-</button>
            <div className="buttonvalue">{props.initialQuantity }</div>
            <button className="buttonquantity" onClick={handleAddQuantity}>+</button>
        </div>
    );
};

export default ProductQuantitySelect;
