import { NavLink, useNavigate } from "react-router-dom";
import "./signupverifyotp.css"
import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function SignUpVerifyOtp(props) {
    const navigate = useNavigate();
    const [number, setNumber] = useState("")
    const [otp, setOtp] = useState("")
    const [error, setError] = useState("")
    const [time, settime] = useState(30)
    useEffect(() => {
        var interval;
        if (time > 0) {
            interval = setInterval(() => {
                settime(time - 1)
            }
                , 1000)
        }
        else {
            clearInterval(interval)
        }
        return () => clearInterval(interval)
    }, [time])
    async function Login() {
        if (error || otp.length < 6) {
            toast.error("Please enter a valid 6-digit OTP"); // Display an error toast notification
        } else {

            try {
                await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotUserotpverify`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ number: window.localStorage.getItem("phoneNumber"), otp: otp }),
                }).then((res) => res.json()).then((response) => {


                    if (response.status === 200) {
                        window.localStorage.setItem('token', response.data.token);
                        window.localStorage.setItem('UserId', response.data.UserId);
                        if(response.data.Email !== undefined) {window.localStorage.setItem("Email", response.data.Email)}
                        if(response.data.FirstName !== undefined){window.localStorage.setItem("FirstName", response.data.FirstName)}
                        if(response.data.LastName !== undefined){window.localStorage.setItem("LastName", response.data.LastName)}
                        if(response.data.Address !== undefined){window.localStorage.setItem("Address", JSON.stringify(response.data.Address))}


                        window.localStorage.setItem('LoggedIn', true)
                        props.setLoggedIn(true)
                        navigate('/Order')
                        toast.success(response.message);
                    } else {

                        toast.error(response.message);
                    }
                })
            } catch (error) {
                console.error('API Error:', error);
                toast.error(error);
            }
        }


    }
    useEffect(() => {
        if (window.localStorage.getItem('phoneNumber')) {
            setNumber(window.localStorage.getItem('phoneNumber').slice(0, 3) + " " + window.localStorage.getItem('phoneNumber').slice(3, 13))
        }
    }, [])

    useEffect(() => {

        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })


    }, [])

    useEffect(() => {
        document.title = "Verify-OTP - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Verify-OTP - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    const handleChange = (event) => {
        let enteredPhoneNumber = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (enteredPhoneNumber.length > 6) {
            enteredPhoneNumber = enteredPhoneNumber.slice(0, 6); // Trim to maximum 6 digits
        }
        const isValid = /^\d{6}$/.test(enteredPhoneNumber);
        setOtp(enteredPhoneNumber);
        setError(isValid ? '' : 'Please enter a valid 6-digit OTP');
    }
    const resend = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotUserotprequest`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ number: window.localStorage.getItem('phoneNumber') }),
            });

            if (response.status === 200) {


                toast.success('OTP Resend successfully');
                settime(30)
                setOtp("")
            } else {
                toast.error('Failed to resend OTP');
            }
        } catch (error) {
            console.error('API Error:', error);
            toast.error('An error occurred while making the API call');
        }
    }

    return (
        <>
            <section className="signback mt-5 pt-5 pb-5">
                <div className="containe pt-5 pb-5">
                    <div className="row justify-content-center mt-4 pt-5 pb-5">
                        <div className="col-md-6">
                            <div className="boxxxxxy">
                                <div className="heading-texxt text-center">
                                    <span>Login / Sign Up</span>
                                </div>




                                <div className="form-signin">
                                    <input type="text" className="form-control form-alignment" id="exampleInputnumber1"
                                        placeholder="Phone Number" value={number} readOnly />
                                    <span className="changeno"><NavLink to="/login"> Change</NavLink></span>

                                    <input type="text" className="form-control form-alignment" maxLength={"6"} id="exampleInputotp1" autoComplete="off"
                                        placeholder="Enter OTP" value={otp} onChange={(e) => {
                                            handleChange(e)
                                        }} />
                                </div>


                                <div className="righhtty-text text-right">
                                    {time > 0 ? `Resend OTP in ${time} sec` : <span style={{ cursor: "pointer" }} onClick={() => {
                                        resend()
                                    }}>Resend</span>}

                                </div>
                                <br />
                                <div className="righhtty-text text-left">
                                    By continuing, you agree to Colorbot's <NavLink to="/privacy-policy" >Privacy Policy</NavLink> and <NavLink to="/terms-conditions" >Terms and Conditions. </NavLink>

                                </div>
                                <div>

                                    <input type="submit" className="btn  button-signin" value="Continue" onClick={() => { Login() }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SignUpVerifyOtp;