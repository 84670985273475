import "./orderdetail.css"
import "./invoice.css"
import { NavLink, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import { ToWords } from 'to-words';
import html2pdf from 'html2pdf.js'
import { toast } from "react-toastify";

function OrderDetail(props) {
    const toWords = new ToWords();
    const navigate = useNavigate()
    const [orderdetail, setOrderDetail] = useState([])
    const [lgShow1, setLgShow1] = useState(false);
    const [modalShow2, setModalShow2] = useState(false);
    const [ordertotal, setOrderTotal] = useState(0)
    const [ordersubtotal, setOrderSubTotal] = useState(0)
    const statesArray = [
        { code: 1, name: "JAMMU AND KASHMIR" },
        { code: 2, name: "HIMACHAL PRADESH" },
        { code: 3, name: "PUNJAB" },
        { code: 4, name: "CHANDIGARH" },
        { code: 5, name: "UTTARAKHAND" },
        { code: 6, name: "HARYANA" },
        { code: 7, name: "DELHI" },
        { code: 8, name: "RAJASTHAN" },
        { code: 9, name: "UTTAR PRADESH" },
        { code: 10, name: "BIHAR" },
        { code: 11, name: "SIKKIM" },
        { code: 12, name: "ARUNACHAL PRADESH" },
        { code: 13, name: "NAGALAND" },
        { code: 14, name: "MANIPUR" },
        { code: 15, name: "MIZORAM" },
        { code: 16, name: "TRIPURA" },
        { code: 17, name: "MEGHALAYA" },
        { code: 18, name: "ASSAM" },
        { code: 19, name: "WEST BENGAL" },
        { code: 20, name: "JHARKHAND" },
        { code: 21, name: "ORISSA" },
        { code: 22, name: "CHHATTISGARH" },
        { code: 23, name: "MADHYA PRADESH" },
        { code: 24, name: "GUJARAT" },
        { code: 25, name: "DAMAN AND DIU" },
        { code: 26, name: "DADAR AND NAGAR HAVELI" },
        { code: 27, name: "MAHARASHTRA" },
        { code: 29, name: "KARNATAKA" },
        { code: 30, name: "GOA" },
        { code: 31, name: "LAKSHADWEEP" },
        { code: 32, name: "KERALA" },
        { code: 33, name: "TAMIL NADU" },
        { code: 34, name: "PUDUCHERRY" },
        { code: 35, name: "ANDAMAN AND NICOBAR" },
        { code: 36, name: "TELANGANA" },
        { code: 37, name: "ANDHRA PRADESH" },
        { code: 97, name: "OTHER TERRITORY" }
    ];
    function getCodeByStateName(stateName) {
        const stateObject = statesArray.find(state => state.name === stateName?.toUpperCase());
        return stateObject ? stateObject.code : null;
    }
    const print = async () => {
        const elementToCapture = document.getElementById('invoiceprint');

        // Use html2pdf to convert the element to a PDF
        await html2pdf(elementToCapture)
            .then(pdf => {
                // You can then save the PDF or perform other actions
                // For example, to save the PDF:
                pdf.save("invoice.pdf");

            })
            .catch(error => {
                console.error('Error converting element to PDF:', error);
            });
    }
    function Logout() {
        setLgShow1(true)

    }
    function Logoutmain() {
        window.localStorage.setItem('LoggedIn', false)
        props.setLoggedIn(false)
        window.localStorage.clear()
        navigate("/")
    }
    var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const convertdate = (date) => {

        if (date !== undefined) {
            var d = date.split("-")[0];
            var m = date.split("-")[1];
            var y = date.split("-")[2];
            var time = d + " " + month[parseInt(m) - 1] + " " + y;

            return time;
        }


    }

    useEffect(() => {

        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })


    }, [])

    useEffect(() => {
        document.title = "Order Details - Colorbot";// Set the title when component mounts
        return () => {
            document.title = "Order Details - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    useEffect(() => {
        if (window.localStorage.getItem("orderdetail") !== null) {
            var data = JSON.parse(window.localStorage.getItem("orderdetail"))
            data = [data]

            var totalsum = 0
            var subtotal = 0
            for (var i = 0; i < data[0].OrderDetail.length; i++) {
                totalsum += parseInt(data[0].OrderDetail[i].OrderTotal)
                subtotal += parseInt(data[0].OrderDetail[i].OrderSubTotal)
            }
            setOrderTotal(totalsum)
            setOrderSubTotal(subtotal)
            setOrderDetail(data)
        }

    }, [])
    const [showMore, setShowMore] = useState(false);
    const [showarr, setShowArr] = useState([0]);
    const [lgShow2, setLgShow2] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
        if (showMore) {
            setShowArr([0]);
        } else {
            setShowArr(orderdetail[0].OrderDetail.map((_, index) => index));
        }
    };

    const updateStatus = async () => {
        await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotAdminCancelOrder`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${window.localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                OrderId: orderdetail[0].OrderId,
                OrderStatus: "Cancelled",
                ShipRocket: orderdetail[0].ShipRocket,
            })

        }).then((res) => res.json()).then((response) => {


            if (response.status === 200) {
                navigate("/Order")
                toast.success(response.message)

            } else {
                toast.error(response.message)
            }
        }).catch((err) => {
            console.log(err)
        })

    }
    function calculateFinalAmount(order, id) {
        // Extract values for better readability
        const totalAmount = Number(ordersubtotal);
        const orderCouponPrice = Number(order[id]?.OrderCoupanPrice);


        // Calculate the discount without tax
        const discountWithoutTax = totalAmount - orderCouponPrice;


        // Calculate the tax amount (18% in this case)
        const taxAmount = discountWithoutTax - (discountWithoutTax / 1.18);


        // Calculate the final amount
        const finalAmount = (discountWithoutTax / 1.18 + taxAmount).toFixed(2);

        return finalAmount;
    }
    function calculateTaxAmount(order, id) {
        // Extract values for better readability
        const totalAmount = Number(ordersubtotal);
        const orderCouponPrice = Number(order[id]?.OrderCoupanPrice);


        // Calculate the discount without tax
        const discountWithoutTax = totalAmount - orderCouponPrice;


        // Calculate the tax amount (18% in this case)
        const taxAmount = discountWithoutTax - (discountWithoutTax / 1.18);





        return taxAmount;
    }
    const navigateToProduct = (path) => {
        navigate(path);
    }

    return (
        <>
            <section className="signback mt-5 pt-3 pb-5">

                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"

                    centered
                    show={lgShow1}

                    onHide={() => setLgShow1(false)}

                >
                    <Modal.Header closeButton style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>

                    </Modal.Header>
                    <Modal.Body style={{ paddingTop: "0rem" }}>


                        <div className="box-profile-pop-profile">



                            <div className="logoutpop">
                                <img src="assets/Vector.svg" className="img-fluid" alt="exlam" />
                            </div>
                            <div className="logoutpop mt-2">
                                <p>Are you sure you want to logout?</p>
                            </div>
                            <div className="logoutpop button">
                                <button className="btn btncancellog" onClick={() => setLgShow1(false)}>
                                    Cancel
                                </button>
                                <button className="btn btnsubmitlog" onClick={() => {
                                    Logoutmain()
                                }}>Logout</button>
                            </div>







                        </div>


                    </Modal.Body>
                </Modal>
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"

                    centered
                    show={lgShow2}

                    onHide={() => setLgShow2(false)}

                >
                    <Modal.Header closeButton style={{ borderBottom: "none", padding: "1rem 1rem 0rem 0rem" }}>

                    </Modal.Header>
                    <Modal.Body style={{ paddingTop: "0rem" }}>


                        <div className="box-profile-pop-profile">



                            <div className="logoutpop">
                                <img src="assets/Vector.svg" className="img-fluid" alt="exlam" />
                            </div>
                            <div className="logoutpop mt-2">
                                <p>Are you sure you want to cancel the order?</p>
                            </div>

                            <div className="cancel-pop">
                                <div className="form-check mt-2">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label labeltitle" htmlFor="flexRadioDefault1">
                                        Placed order by mistake
                                    </label>
                                </div>
                                <div className="form-check mt-2">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className="form-check-label labeltitle" htmlFor="flexRadioDefault2">
                                        Estimated delivery is too long
                                    </label>
                                </div>
                                <div className="form-check mt-2">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                    <label className="form-check-label labeltitle" htmlFor="flexRadioDefault3">
                                        Changed my mind
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                    <label className="form-check-label labeltitle" htmlFor="flexRadioDefault4">
                                        Others
                                    </label>
                                </div>

                                <textarea className="form-control form-control-lg mt-3" rows="2" placeholder="Enter Your Message" ></textarea>

                            </div>

                            <div className="logoutpop button">
                                <button className="btn btncancellog" onClick={() => setLgShow2(false)}>
                                    No
                                </button>
                                <button className="btn btnsubmitlog" onClick={() => {
                                    updateStatus()
                                }}>Yes</button>
                            </div>







                        </div>


                    </Modal.Body>
                </Modal>
                <Modal
                    show={modalShow2}
                    size="lg"
                    scrollable={true}
                    fullscreen={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered

                >
                    <Modal.Header style={{ borderBottom: "none", padding: "4rem 1rem 0rem 0rem" }}>

                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", justifyContent: "center", backgroundColor: "rgb(226, 226, 226)" }} id="invoiceprint">

                        {orderdetail.length > 0 && orderdetail.map((item, index) => {

                            return (
                                <div className='invoice' key={index} >
                                    <div className="invoice-box ">

                                        <div className="invoice-head">
                                            <div className="color-logo">
                                                <img src="assets/Colorbot_INVOICE/COLORBOT-R-logo.png" alt="" />
                                            </div>
                                            <div className="color-logo-txt">
                                                <p><b>TAX INVOICE</b></p>
                                                ORIGINAL FOR CUSTOMER
                                            </div>

                                        </div>


                                        <table className="compny-address" style={{ width: "100%" }}>
                                            <tr>
                                                <th></th>
                                                <th></th>

                                            </tr>
                                            <tr>
                                                <td className="tble-left">


                                                    <div className="compny-add">
                                                        <p className="sol">Sold by:</p>
                                                        <p className="address"> <b>Colorbot Private Limited</b> <br />
                                                            03/UG-29, UPPER GROUND
                                                            FLOOR, ANSAL PLAZA, SECTOR-1, VAISHALI,
                                                            GHAZIABAD-201010 (U.P.)
                                                        </p>
                                                    </div>
                                                    <div className="gtin">
                                                        <span>GSTIN : </span>
                                                        <span>09AAKCC5866H1Z6</span>
                                                    </div>
                                                    <div className="gtin">
                                                        <span>PAN : </span>
                                                        <span>AAKCC5866H</span>
                                                    </div>
                                                    <br />


                                                    <div className="invoice-no">
                                                        <span>Invoice No : </span>
                                                        <span>{item.InvoiceNumber}</span>
                                                    </div>
                                                    <div className="invoice-no">
                                                        <span>Invoice Date :</span>
                                                        <span>{item.OrderDate.split(" ")[0]}</span>
                                                    </div>

                                                </td>

                                                <td className="tble-left tble-rght">
                                                    <div className="invoice-no">
                                                        <span>Order ID : </span>
                                                        <span>{item.OrderId}</span>
                                                    </div>
                                                    <div className="invoice-no">
                                                        <span>Order Date:</span>
                                                        <span>{item.OrderDate.split(" ")[0]}</span>
                                                    </div>

                                                </td>
                                            </tr>
                                        </table>

                                        <table className="compny-address" style={{ width: "100%" }}>
                                            <tr>
                                                <th></th>
                                                <th></th>

                                            </tr>
                                            <tr>
                                                <td className="tble-left">
                                                    <div className="compny-add">
                                                        <p className="sol">Bill To :</p>
                                                        <p className="address"> <b>{item.OrderGST !== "NA" ? item.OrderGSTBussinessName : item.OrderAddress?.[0]?.FirstName} {item.OrderAddress?.[0]?.LastName}</b> <br />
                                                            {item.OrderAddress?.[0]?.Address} {item.OrderAddress?.[0]?.Address1}<p></p>
                                                            {item.OrderAddress?.[0]?.City} {item.OrderAddress?.[0]?.State}<p></p>
                                                            {item.OrderAddress?.[0]?.Pincode} IN
                                                        </p>
                                                    </div>

                                                    <div className="gtin input-widt">
                                                        <span>State/UT Code : </span>
                                                        <span>{getCodeByStateName(item.OrderAddress?.[0]?.State.toUpperCase())}</span>
                                                    </div>
                                                    <div className="gtin">
                                                        <span>GSTIN : </span>
                                                        <span>{item.OrderGST}</span>
                                                    </div>

                                                </td>

                                                <td className="tble-left tble-rght">
                                                    <div className="compny-add">
                                                        <p className="sol">Ship To :</p>
                                                        <p className="address"> <b>{item.OrderAddress?.[0]?.FirstName} {item.OrderAddress?.[0]?.LastName}</b> <br />
                                                            {item.OrderAddress?.[0]?.Address} {item.OrderAddress?.[0]?.Address1}<p></p>
                                                            {item.OrderAddress?.[0]?.City} {item.OrderAddress?.[0]?.State}<p></p>
                                                            {item.OrderAddress?.[0]?.Pincode} IN
                                                        </p>
                                                    </div>
                                                    {/* <div className="gtin input-widt">
                        <span>State/UT Code : </span>
                        <span>09</span>
                      </div> */}
                                                </td>
                                            </tr>
                                        </table>

                                        <table className="invoice-details" style={{ width: "100%" }}>
                                            <tr className="t-head">
                                                <th>S. No.</th>
                                                <th>Item Code</th>
                                                <th>Item Description</th>
                                                <th>HSN No.</th>

                                                <th>Qty</th>
                                                <th>Amount <br /> <span>(exclusive of GST)</span></th>
                                            </tr>
                                            {item.OrderDetail.length > 0 && item.OrderDetail.map((item, index) => {
                                                return (
                                                    <tr className="t-body" key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.OrderItemId}</td>
                                                        <td>{item.OrderName}</td>
                                                        <td>84145120</td>

                                                        <td>{item.OrderQuantity}</td>
                                                        <td>{(Number(item.OrderSubTotal) / 1.18).toFixed(2)}</td>
                                                    </tr>
                                                )
                                            })
                                            }

                                        </table>

                                        <table className="tax-detail" style={{ width: "100%" }}>
                                            <tr>
                                                <th></th>
                                            </tr>

                                            <tr>
                                                <td> <b>Total Amount </b>(exclusive of GST) </td>

                                                <td></td>
                                                <td>{(Number(ordersubtotal) / 1.18).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td> <b>Warranty </b>(exclusive of GST) </td>

                                                <td></td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td> <b>Shipping </b>(exclusive of GST) </td>

                                                <td></td>
                                                <td>0</td>
                                            </tr>
                                            <tr>
                                                <td> <b>Discount (coupon) </b>(exclusive of GST) </td>

                                                <td></td>
                                                <td>{(Number(item.OrderCoupanPrice) / 1.18).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td> <b>Subtotal </b>(exclusive of GST) </td>

                                                <td></td>
                                                <td>{((Number(ordersubtotal) - Number(item.OrderCoupanPrice)) / 1.18).toFixed(2)}</td>
                                            </tr>

                                            <tr>

                                                <td></td>
                                                <td> <b>Tax Rate: </b><br />
                                                    {item.OrderAddress?.[0]?.State === "Uttar Pradesh" ? " CGST 9%" : "IGST 18%"}
                                                    {item.OrderAddress?.[0]?.State === "Uttar Pradesh" ? <br /> : ""}
                                                    {item.OrderAddress?.[0]?.State === "Uttar Pradesh" ? "SGST 9%" : ""}
                                                </td>
                                                <td style={{ paddingTop: "22px" }}>

                                                    {item.OrderAddress?.[0]?.State === "Uttar Pradesh" ? (calculateTaxAmount(orderdetail, 0) / 2).toFixed(2) : ""}
                                                    {item.OrderAddress?.[0]?.State === "Uttar Pradesh" ? <br /> : ""}
                                                    {item.OrderAddress?.[0]?.State === "Uttar Pradesh" ? (calculateTaxAmount(orderdetail, 0) / 2).toFixed(2) : calculateTaxAmount(orderdetail, 0).toFixed(2)}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td><b>Payable amount</b></td>

                                                <td></td>

                                                <td className="pay-amnt"> <b>Rs. {calculateFinalAmount(orderdetail, 0)}</b>
                                                </td>
                                            </tr>
                                        </table>

                                        <table className="signature" style={{ width: "100%" }}>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            <tr>
                                                <td className="word-amnt">Amount in Words: <br />
                                                    {ordersubtotal > 0 ? toWords.convert(calculateFinalAmount(orderdetail, 0), { currency: true }) : ""}
                                                </td>
                                                <td className="sign-stmp"> For Colorbot Pvt. Ltd. <br />
                                                    Authorized Signatory <br />
                                                    <img src="assets/Colorbot_INVOICE/invoice-sign.png" alt="" />
                                                    <img src="assets/Colorbot_INVOICE/stamp-dummy.png" alt="" />
                                                </td>
                                            </tr>
                                        </table>

                                        <table className="invoice-tc" style={{ width: "100%" }}>
                                            <tr>
                                                <th></th>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className="text-cont">
                                                        <h6>Whether tax is payable under reverse charge - No</h6>
                                                        <p>Declaration: This invoice declares that this invoice shows the actual price of the goods
                                                            described and
                                                            that all particulars are true and correct</p>
                                                        <ul>
                                                            <li>Disputes or complaints if any, must be intimated within 3 days in writing and shall be
                                                                referred to
                                                                Colorbot Pvt Ltd. 03/UG-29, UPPER GROUND FLOOR, ANSAL PLAZA, SECTOR-1, VAISHALI,
                                                                GHAZIABAD-201010
                                                                (U.P.)</li>
                                                            <li>Invoice shall be deemed accepted if any dispute is not raised within 3 days of invoice
                                                                delivery over
                                                                email.</li>
                                                            <li>TRAI rules shall be applicable at all times for SMS and Voice services.</li>
                                                            <li>WhatApp rules and policies shall be applicable for WhatsApp services.</li>
                                                            <li>Keep the Invoice and manufacturer box for warranty purposes.</li>
                                                            <li>For any concerns or feedback,please contact us at support@colorbot.in.</li>
                                                            <li>Please visit our website www.colorbot.in for more details/information</li>
                                                            <li>This is an electronicaly generated invoice</li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>

                                        <table className="last-row" style={{ width: "100%" }}>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>

                                            <tr>
                                                <td> <span><b>Invoice Amount</b> </span> <br />

                                                    <span>Rs. {calculateFinalAmount(orderdetail, 0)}  /-</span>
                                                </td>
                                                <td> <span><b>Invoice Date</b> </span> <br />
                                                    <span>{item.OrderDate.split(" ")[0]}</span>
                                                </td>
                                                <td><span><b>Payment Mode</b> </span> <br />
                                                    <span>{item.OrderPaymentType}</span>
                                                </td>
                                            </tr>
                                        </table>

                                    </div>
                                </div>
                            )
                        })}
                    </Modal.Body>
                    <Modal.Footer >
                        <div className="buton  d-flex align-content-end justify-content-end ">
                            <button className="btn1" onClick={() => {
                                setModalShow2(false);


                            }}>Cancel</button> &nbsp;&nbsp;
                            <button className="btn2 " onClick={() => {
                                print()
                            }}>Print</button>
                        </div>
                    </Modal.Footer>
                </Modal>
                <div className="container">

                    <div className="row justify-content-center mt-5">
                        <div className="col-lg-3 profilecolwidth mt-3">
                            <div className="profile_left">

                                <NavLink to="/Order" className="nodecoration">  <div className="tab_left tab-leftactive">
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/myorder_left.svg" className="img-fluid" alt="order" />&nbsp;&nbsp;&nbsp; My Orders
                                </div></NavLink>
                                <NavLink to="/profile" className="nodecoration"> <div className="tab_left ">
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/profile_left.svg" className="img-fluid" alt="profile" />&nbsp;&nbsp;&nbsp;&nbsp; Profile
                                </div></NavLink>
                                <div className="tab_left" onClick={() => {
                                    Logout()
                                }}>
                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/logout_left.svg" className="img-fluid" alt="logout" />&nbsp;&nbsp;&nbsp;&nbsp; Logout
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 mt-3">

                            <div className="background-boxx">
                                {orderdetail.length > 0 && orderdetail.map((item, index) => {
                                    let quantity = 0
                                    item.OrderDetail.map((item, index) => {
                                        quantity += parseInt(item.OrderQuantity)
                                    })
                                    let warranty = false
                                    item.OrderDetail.map((item, index) => {
                                        if (item.OrderExtendedWarranty) {
                                            warranty = true
                                        }
                                    })
                                    let warrantyprice = 0
                                    item.OrderDetail.map((item, index) => {
                                        if (item.OrderExtendedWarranty) {
                                            warrantyprice += 499 * parseInt(item.OrderQuantity)
                                        }
                                    })
                                    return (
                                        <div className="row" key={index}>
                                            <div className="col-md-12">
                                                <div className="order-id2">
                                                    <span>Order Id: <b>{item.OrderId}</b></span>
                                                </div>

                                                {item.OrderDetail.length > 0 && item.OrderDetail.map((mainitem, mainindex) => {
                                                    const isVisible = showarr.includes(mainindex);
                                                    return (

                                                        <div className={isVisible ? "all-side-box" : "all-side-box hidden"} key={mainindex}>
                                                            <div className="left-portition">

                                                                <div className="order-name">
                                                                    <span>{mainitem.OrderName}</span>
                                                                </div>
                                                                <div className="order-quantity">
                                                                    <span>Qty: {mainitem.OrderQuantity}</span>
                                                                </div>
                                                                <div className="order-color">
                                                                    <span>Color: {mainitem.OrderColor}</span>
                                                                </div>
                                                                <div className="order-color">
                                                                    <span>Sweep Size: {mainitem.OrderSize}</span>
                                                                </div>

                                                            </div>

                                                            <div className="right-portition">
                                                                <div className="order-image">
                                                                    <img src={mainitem.OrderImage} alt="" style={{
                                                                        cursor: "pointer"
                                                                    }} onClick={() => {
                                                                        navigateToProduct(mainitem.ProductPath)
                                                                    }} />

                                                                </div>
                                                                <div className="order-price">
                                                                    <span>Rs. {mainitem.OrderSubTotal}</span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    );
                                                })}
                                                {orderdetail[0].OrderDetail.length > 1 ? <div className="text-center orderallbutton" onClick={toggleShowMore}>
                                                    {showMore ? "View Less" : "View All"} &nbsp; &nbsp;
                                                    <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/downarrowbuy.svg" className={showMore ? "img-fluid rotate" : "img-fluid"} alt="icon" />
                                                </div> : ""}


                                                <hr />

                                            </div>
                                            <br />
                                            {item.OrderStatus !== "Cancelled" ? <div className="col-md-12 mt-4">
                                                <div className="mid-side-box">
                                                    <div className="ordered-confirmed ">
                                                        <span>Confirmed</span>
                                                        <p>{convertdate(item.OrderDate)}</p>
                                                    </div>
                                                    <div className="ordered-shipped">
                                                        <span>Shipped</span>
                                                        <p>{convertdate(item.OrdershippedDate)}</p>
                                                    </div>

                                                    {item.OrderStatus === "RTO" ? <div className="ordered-delivered ">
                                                        <span>Denied</span>
                                                        <p>{convertdate(item.OrderRTODate)}</p>
                                                    </div> :
                                                        <div className="ordered-delivered ">
                                                            <span>Delivered</span>
                                                            <p>{convertdate(item.OrderDeliveredDate)}</p>
                                                        </div>}
                                                </div>
                                            </div> : ""}
                                            {item.OrderStatus !== "Cancelled" ? <div className="col-md-12">
                                                {item.OrderStatus === "Order Confirmed" ? <>
                                                    <div className="griddetail">
                                                        <div className={"order-tracking completed flexcenter"}>

                                                            <span className="is-complete"></span>
                                                        </div>
                                                        <div className={"order-tracking  flexcenter"}>

                                                            <span className="is-complete"></span>
                                                        </div>
                                                        <div className={"order-tracking  flexcenter"}>

                                                            <span className="is-complete"></span>
                                                        </div>
                                                    </div></> : ""}
                                                {item.OrderStatus === "Shipped" ? <>
                                                    <div className="griddetail">
                                                        <div className={"order-tracking completed flexcenter"}>

                                                            <span className="is-complete"></span>
                                                        </div>
                                                        <div className={"order-tracking completed  flexcenter"}>

                                                            <span className="is-complete"></span>
                                                        </div>
                                                        <div className={"order-tracking  flexcenter"}>

                                                            <span className="is-complete"></span>
                                                        </div>
                                                    </div></> : ""}
                                                {item.OrderStatus === "Delivered" ? <>
                                                    <div className="griddetail">
                                                        <div className={"order-tracking completed flexcenter"}>

                                                            <span className="is-complete"></span>
                                                        </div>
                                                        <div className={"order-tracking completed  flexcenter"}>

                                                            <span className="is-complete"></span>
                                                        </div>
                                                        <div className={"order-tracking completed flexcenter"}>

                                                            <span className="is-complete"></span>
                                                        </div>
                                                    </div></> : ""}
                                                {item.OrderStatus === "RTO" ? <>
                                                    <div className="griddetail">
                                                        <div className={"order-tracking completed flexcenter"}>

                                                            <span className="is-complete"></span>
                                                        </div>
                                                        <div className={"order-tracking completed  flexcenter"}>

                                                            <span className="is-complete"></span>
                                                        </div>
                                                        <div className={"order-tracking rto flexcenter"}>

                                                            <span className="is-rto"></span>
                                                        </div>
                                                    </div></> : ""}


                                            </div> : <div className="col-md-12 pt-3">
                                                <p className="text-danger text-center">Order Cancelled</p>
                                            </div>}
                                            <br />
                                            <div className="col-md-12">
                                                {item.OrderStatus === "Delivered" ?
                                                    <div className="thirrd-box" style={{ cursor: "pointer" }} onClick={() => {
                                                        setModalShow2(true)
                                                    }}>
                                                        <div className="first-imagee">
                                                            <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/file.svg" alt="" />
                                                            <span>Invoice Download</span>
                                                        </div>

                                                        <div className="second-image">
                                                            <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/download.svg" alt="" />
                                                        </div>
                                                    </div> : <div className="thirrd-box" >
                                                        <div className="first-imagee">
                                                            <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/file.svg" alt="" />
                                                            <span>Invoice Download</span>
                                                        </div>

                                                        <div className="second-image">
                                                            <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/download.svg" alt="" />
                                                        </div>
                                                    </div>}
                                            </div>

                                            <div className="col-md-6">
                                                <div className="shipping-details">
                                                    <span>Shipping Details</span>

                                                </div>
                                                <div className="shipping-detail-box">
                                                    <div className="fulll-name">
                                                        <span>{item.OrderAddress?.[0]?.FirstName} {item.OrderAddress?.[0]?.LastName}</span>
                                                    </div>
                                                    <div className="shipping-address mt-2">
                                                        <span>{item.OrderAddress?.[0]?.Address} {item.OrderAddress?.[0]?.Address1}<br />
                                                            {item.OrderAddress?.[0]?.City},<br />
                                                            {item.OrderAddress?.[0]?.State}, {item.OrderAddress?.[0]?.Pincode}</span>
                                                    </div>
                                                    <div className="shipping-phone-no mt-2">
                                                        <span>{item.OrderAddress?.[0]?.Phone.slice(3)}</span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="price-details">
                                                    <span>Price Details</span>

                                                </div>
                                                <div className="price-details-box">
                                                    <div className="list-of-desc">
                                                        <div>
                                                            <span>Listing Price</span>
                                                        </div>
                                                        <div className="mt-2">
                                                            <span>Selling Price</span>
                                                        </div>
                                                        <div className="mt-2">
                                                            <span>Total Saving</span>
                                                        </div>
                                                        <div className="mt-2">
                                                            <span>Shipping</span>
                                                        </div>
                                                        {warranty ? <div className="mt-2">
                                                            <span>Extended Warranty</span>
                                                        </div> : ""}
                                                        {parseInt(item.OrderCoupanPrice) > 0 ? <div className="mt-2">
                                                            <span>Coupon</span>
                                                        </div> : ""}
                                                        <div className="mt-2">
                                                            <span>Total Amount ({item.OrderPaymentType}) </span>
                                                        </div>
                                                    </div>
                                                    <div className="list-of-price text-end">
                                                        <div>
                                                            <span>Rs. {ordertotal}</span>
                                                        </div>
                                                        <div className="mt-2" >
                                                            <span>Rs. {ordersubtotal}</span>
                                                        </div>
                                                        <div className="mt-2">
                                                            <span>Rs. {parseInt(ordertotal) - parseInt(ordersubtotal) - parseInt(item.OrderCoupanPrice)}</span>
                                                        </div>
                                                        <div className="freee mt-2">
                                                            <strong>Free</strong> <span>Rs. {100 * quantity}</span>
                                                        </div>
                                                        {warranty ? <div className="freee mt-2">
                                                            <strong>Free</strong> <span>Rs. {warrantyprice}</span>
                                                        </div> : ""}
                                                        {parseInt(item.OrderCoupanPrice) > 0 ? <div className="mt-2">
                                                            <span>Rs. {item.OrderCoupanPrice}</span>
                                                        </div> : ""}
                                                        <div className="mt-2">
                                                            <span>Rs. {ordersubtotal - parseInt(item.OrderCoupanPrice)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />


                                            {moment().diff(moment(item.OrderDate, 'DD-MM-YYYY HH:mm:ss'), 'minutes') < 240 && item.OrderStatus === "Order Confirmed" ?

                                                <div className="col-md-12 d-flex justify-content-between ">

                                                    <div className="d-flex justify-content-end"><button className="btn btn-cancel" onClick={() => {
                                                        setLgShow2(true)
                                                    }}>Cancel Order</button>
                                                        <p className="btn-txt">
                                                            Note: Once the order ships, you will not be able to cancel the order.
                                                        </p>
                                                    </div>
                                                    {item.OrderStatus !== "Delivered" && item.OrderStatus !== "Cancelled" ? <a href="https://www.shiprocket.in/shipment-tracking/" target="_blank" className="cncle-dis"> <button className="btn btn-cancel" style={{
                                                        border: " 0.8px solid #242933",
                                                        color: "#242933"
                                                    }}>Track Order</button></a> : <div className="d-flex justify-content-end cncle-dis"><button className="btn btn-cancel" disabled={item.OrderStatus === "Delivered" || item.OrderStatus === "Cancelled"} style={{
                                                        border: " 0.8px solid #242933",
                                                        color: "#242933"
                                                    }}>Track Order</button></div>}
                                                </div>
                                                : <div className="col-md-12 d-flex justify-content-between">

                                                    <div className="d-flex justify-content-end cncle-dis">
                                                        <button className="btn btn-cancel" disabled onClick={() => {
                                                            setLgShow2(true)
                                                        }}>Cancel Order</button>
                                                        <p className="btn-txt">
                                                            Note: Once the order ships, you will not be able to cancel the order.
                                                        </p></div>
                                                    {item.OrderStatus !== "Delivered" && item.OrderStatus !== "Cancelled" ? <a href="https://www.shiprocket.in/shipment-tracking/" target="_blank" className="cncle-dis"> <button className="btn btn-cancel" style={{
                                                        border: " 0.8px solid #242933",
                                                        color: "#242933"
                                                    }}>Track Order</button></a> : <div className="d-flex justify-content-end cncle-dis"><button className="btn btn-cancel" disabled={item.OrderStatus === "Delivered" || item.OrderStatus === "Cancelled"} >Track Order</button></div>}
                                                </div>}





                                        </div>
                                    )
                                })}




                            </div>

                        </div>


                    </div>
                </div>
            </section>
        </>
    );
}
export default OrderDetail;