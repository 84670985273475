import { useEffect } from "react";
import "./Terms_Conditions.css"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from 'react-helmet';


const TermHead = () => (

    <Helmet>
        <title>Terms and Conditions</title>
        <meta name="description" content="Review Colorbot Terms and Conditions before signing in to the website and understand the rules thoroughly for accessing and utilizing our website." />
        <link rel="canonical" href="https://colorbot.in/terms-conditions" />

        <meta property="og:title" content="Terms and Conditions" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/terms-conditions" />
        <meta property="og:description" content="Discover Colorbot Services and Policies, including our dedication to customer satisfaction, product warranties, and support guidelines for seamless experience." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/banners/terms&conditions-banner.jpg" />

    </Helmet>
);

function Terms() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        document.title = "Terms and Conditions - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Terms and Conditions - Colorbot"; // Reset the title when component unmounts
        };
    }, []);
    return (
        <section className="mt-5 mb-4">
            <TermHead />
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">

                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/banners/terms&conditions-banner.jpg"}
                            width={"100vw"}
                            className="d-block w-100 img-fluid" alt="Terms and Conditions"
                            effect="blur"
                        />

                    </div>

                </div>

            </div>

            <div className="container">
                <br />
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h1 className="title">Terms and Conditions</h1>
                        <p className="subtitle">
                            Use of Colorbot is subject to the following Terms and Conditions. By accessing and using this Website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not use the Website.
                        </p>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">1. Acceptance of Terms:</h2>
                        <p className="subtitle">
                            By accessing this Website, you acknowledge that you have read, understood, and agreed to these Terms and Conditions. Colorbot reserves the right to modify or update these terms at any time without prior notice. It is your responsibility to review these Terms and Conditions periodically.</p>
                        {/* <ul>
                            <li><p className="subtitle">Our Terms and Conditions may change at any time without any prior notice. Therefore you are requested to visit the page time to time to be updated and aware about the amendments.</p></li>
                            <li><p className="subtitle">You are deemed to have given us the permission to use your personal information in accordance with this Terms and Conditions.</p></li>
                            <li><p className="subtitle">This Terms and Conditions does not apply to any sites maintained by third parties.</p></li>
                        </ul> */}
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">2. Intellectual Property:</h2>
                        <p className="subtitle">
                            All content on the Website, including but not limited to text, images, graphics, logos, videos, and software, is the property of Colorbot or its licensors and is protected by applicable copyright and trademark laws. You may not reproduce, distribute, modify, transmit, or use any content from this Website without prior written consent from Colorbot
                        </p>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">3. Use of Website:</h2>
                        <p className="subtitle">
                            You agree to use the Website only for lawful purposes and in a way that does not infringe upon the rights of others. You shall not engage in any activity that may damage, disable, or impair the functioning of the Website or its services.</p>

                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">4. Privacy Policy:</h2>
                        <p className="subtitle">
                            Your use of the Website is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using this Website, you consent to the practices described in the Privacy Policy.</p>


                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">5. Product Information:</h2>
                        <p className="subtitle">
                            The content on the Website may include information about Colorbot's products, including specifications, pricing,
                            and availability. While we strive to provide accurate and up-to-date information, we do not guarantee the accuracy
                            or completeness of the content. Product availability is subject to change without notice.</p>
                    </div>
                    <div className="col-md-1"></div>
                </div>

                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">6. Links to Third-Party Websites:</h2>
                        <p className="subtitle">
                            The Website may contain links to third-party websites that are not under the control of Colorbot. We are not
                            responsible for the content, availability, or practices of these websites. The inclusion of any link does not
                            imply endorsement by Colorbot.</p>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">7. Disclaimer of Warranties:</h2>
                        <p className="subtitle">
                            The Website and its contents are offered "as is" and without any express or implied warranties of any kind. All warranties, including the implied warranty of merchantability, suitability for a particular purpose, and non-infringement, are disclaimed by Colorbot. We do not guarantee the accuracy, reliability, or completeness of the content on the Website.</p>
                    </div>
                    <div className="col-md-1"></div>
                </div>

                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">8. Limitation of Liability:</h2>
                        <p className="subtitle">
                            Colorbot shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the Website, even if we have been advised of the possibility of such damages. Your sole remedy for dissatisfaction with the Website is to stop using it.</p>
                    </div>
                    <div className="col-md-1"></div>
                </div>

                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">9. Indemnification:</h2>
                        <p className="subtitle">
                            By using the Website, violating these Terms and Conditions, or infringing on the rights of others, you undertake to defend, indemnify, and hold Colorbot harmless from all claims, losses, damages, obligations, and expenses (including legal fees).</p>
                    </div>
                    <div className="col-md-1"></div>
                </div>

                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">10. Governing Law:</h2>
                        <p className="subtitle">
                            These Terms and Conditions are governed by and construed in accordance with the laws of Colorbot, without regard to its conflict of law principles.</p>
                    </div>
                    <div className="col-md-1"></div>
                </div>

                <br />
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10 ">
                        <h2 className="title">11. Contact Information:</h2>
                        <p className="subtitle">
                            If you have any questions or concerns about these Terms and Conditions, please contact us at support@colorbot.in
                            <br />
                            By using the Colorbot website, you agree to abide by these Terms and Conditions. Thank you for visiting our Website.
                        </p>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <br />
                <br />

            </div>
        </section>
    );
}
export default Terms