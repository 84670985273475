import { NavLink, useNavigate } from "react-router-dom";
import "./signin.css"
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function SignIn(props) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    useEffect(()=>{
        if(window.localStorage.getItem("phoneNumber")){
            setPhoneNumber(window.localStorage.getItem("phoneNumber").slice(3,13))
        }

    },[])

    const handleChange = (event) => {
        let enteredPhoneNumber = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
        if (enteredPhoneNumber.length > 10) {
            enteredPhoneNumber = enteredPhoneNumber.slice(0, 10); // Trim to maximum 10 digits
        }
        const isValid = /^\d{10}$/.test(enteredPhoneNumber);

        setPhoneNumber(enteredPhoneNumber);
        setError(isValid ? '' : 'Please enter a valid 10-digit phone number');
    };

    useEffect(() => {

        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })


    }, [])

    useEffect(() => {
        document.title = "SignIn - Colorbot";  // Set the title when component mounts
        return () => {
            document.title = "SignIn - Colorbot"; // Reset the title when component unmounts
        };
    }, []);
    const handleSubmit = async () => {
       
        if (error || phoneNumber.length < 10) {
            toast.error("Please enter a valid 10-digit phone number"); // Display an error toast notification
        } else {

            try {
                const response = await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotUserotprequest`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ number: "+91" + phoneNumber }),
                });
                const data = await response.json();

                if (response.status === 200) {
                    window.localStorage.setItem('phoneNumber', "+91"+phoneNumber);
                    navigate("/sign_up_verify_otp")
                    toast.success('OTP Send Successfully');
                } else {
                    
                    toast.error(data.message);
                }
            } catch (error) {
                console.error('API Error:', error);
                toast.error('An error occurred while making the API call');
            }
        }
    };
    return (
        <>
            <section className="signback mt-5 pt-5 pb-5">
                <div className="container pt-5 pb-5">
                    <div className="row justify-content-center mt-4 pt-5 pb-5">

                        <div className=" col-md-6 pt-5 pb-5">
                            <div className=" boxxx">
                                <div className="heading-texxt text-center">
                                    <span>Login / Sign Up</span>
                                </div>




                                <div className="form-signin">
                                    <input
                                        type="text"
                                        className="form-control form-alignment"
                                        id="exampleInputnumber1"
                                        placeholder="Phone Number"
                                        value={phoneNumber}
                                        onChange={handleChange}
                                        autoComplete="off"
                                    />



                                    {/* <input type="password" className="form-control form-alignment" id="exampleInputPassword1"
                                        placeholder="Password" /> */}
                                </div>
                                <br />

                                <div className="righhtty-text text-left">
                                    By continuing, you agree to Colorbot's <NavLink to="/privacy-policy" >Privacy Policy</NavLink> and <NavLink to="/terms-conditions" >Terms and Conditions. </NavLink>

                                </div>

                                <div>

                                    <input type="submit" className="btn  button-signin" value="Request OTP" onClick={() => { handleSubmit() }} />
                                </div>




                                {/* <div className=" paraa-1 mt-4">
                                    <p>Or</p>
                                </div> */}


                                {/* <div className=" paraa-2 ">
                                    <p>Login with</p>
                                </div>


                                <div className="icconny">
                                    <div className="iconny-1 ">
                                        <button className="btn socialbtn"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/facebook 1.svg" alt="" /></button>
                                    </div>

                                    <div className="iconny-2">
                                        <button className="btn socialbtn"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/google-symbol 1.svg" alt="" /></button>
                                    </div>
                                </div>

                                <div className="bottom-para">
                                    <span>Don't have an account?</span> <NavLink to="/sign_up" className="signup"> <u>Sign Up</u></NavLink>
                                </div> */}


                            </div>

                        </div>

                    </div>
                </div>

            </section>
        </>
    );
}
export default SignIn;