import { NavLink } from "react-router-dom";
import "./verifyotp.css"
import React, { useRef } from "react";

function VerifyOtp() {
    const input1Ref = useRef(null);
    const input2Ref = useRef(null);
    const input3Ref = useRef(null);
    const input4Ref = useRef(null);

    function handleKeyUp(e, inputRef) {
        if (e.target.value.length === 1) {
            inputRef.current.focus();
        }
    }

    function handleKeyPress(e) {
        const keyCode = e.which || e.keyCode;
        if (keyCode < 48 || keyCode > 57) {
            e.preventDefault();
        }
    }

    return (
        <>
            <section className="signback mt-5 pt-5 pb-5">
                <div className="container">
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-6">
                            <div className="boxxxxxy">
                                <div className="heading-texttt text-center pt-3">
                                    <span>Verification Code</span>
                                </div>
                                <div className="heading-textt3 text-center">
                                    <span>
                                        Enter the verification code sent <br /> to your registration
                                        email
                                    </span>
                                </div>
                                <div className="otp-box">
                                    <input
                                        type="text"
                                        className="form-control form-alignment"
                                        aria-describedby="emailHelp"
                                        maxLength={1}
                                        onKeyPress={handleKeyPress}
                                        onKeyUp={(e) => handleKeyUp(e, input2Ref)}
                                        ref={input1Ref}
                                    />
                                    <input
                                        type="text"
                                        className="form-control form-alignment"
                                        aria-describedby="emailHelp"
                                        maxLength={1}
                                        onKeyPress={handleKeyPress}
                                        onKeyUp={(e) => handleKeyUp(e, input3Ref)}
                                        ref={input2Ref}
                                    />
                                    <input
                                        type="text"
                                        className="form-control form-alignment"
                                        aria-describedby="emailHelp"
                                        maxLength={1}
                                        onKeyPress={handleKeyPress}
                                        onKeyUp={(e) => handleKeyUp(e, input4Ref)}
                                        ref={input3Ref}
                                    />
                                    <input
                                        type="text"
                                        className="form-control form-alignment"
                                        aria-describedby="emailHelp"
                                        maxLength={1}
                                        onKeyPress={handleKeyPress}
                                        ref={input4Ref}
                                    />
                                </div>

                                <div className="button-alignn">
                                   <NavLink to="/reset_password"> <input className="btn button-signin" type="submit" value="Verify" /></NavLink>
                                </div>

                                <div className="bottom2-para text-center">
                                    <span>Didn't receive the verification code?</span>
                                    <span>
                                        <NavLink to="/verify_otp" className="resend">
                                            RESEND
                                        </NavLink>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default VerifyOtp;