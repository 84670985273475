import "./Dark.css"
import { NavLink, useNavigate } from "react-router-dom";

import axios from 'axios';
import Slider from 'react-slick';
import React, { useState, useEffect, useRef } from "react";
import FadeInSection from "../FadeInup/FadeInup";
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import moment from "moment";
import { Helmet } from 'react-helmet';


const DarkHead = () => (

    <Helmet>
        <title>Buy Colorbot Dark Series Ceiling Fans Online India- Colorbot</title>
        <meta name="description" content="Shop the dark edition of Colorbot ceiling fans online in stunning black shades and add bold visuals to your home ambience with fascinating designs." />
        <link rel="canonical" href="https://colorbot.in/dark" />

        <meta property="og:title" content="Buy Colorbot Dark Series Ceiling Fans Online India- Colorbot" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/dark" />
        <meta property="og:description" content="Shop the dark edition of Colorbot ceiling fans online in stunning black shades and add bold visuals to your home ambience with fascinating designs." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://colorbots3.s3.ap-south-1.amazonaws.com/darkimage1.svg" />
    </Helmet>
);



function Dark(props) {
    const [price, setprice] = useState(null)
    const [discountedprice, setdiscountedprice] = useState(null)
    const [fixedprice, setfixedprice] = useState(null)
    const [fixeddiscountedprice, setfixeddiscountedprice] = useState(null)
    const navigate = useNavigate()
    const [product, setproduct] = useState([])
    const [product_Name, setProduct_Name] = useState("Helix")
    const [product_Description, setProduct_Description] = useState("The Ultimate fun for home, featuring 5-star BLDC motor, inverter-friendly technology, higher air delivery, stylish design and remote. Get longer product protection with a 2+2 years warranty.")
    const [itemName, setItemName] = useState("")
    const [producttype, setproducttype] = useState([])
    const [inventory, setInventory] = useState(0)
    const [fantypedata, setfantypedata] = useState([
        {
            name: "Helix",
            image: "Helix-black-select",

        },
        {
            name: "Knoxx",
            image: "Knoxx-black-select"
        }, {
            name: "Prizma",
            image: "Prizma-black-select"
        }
    ])

    const getproduct = async () => {
        try {
            await fetch(`${process.env.REACT_APP_PROD_URL}/ColorBotGetDarkProduct`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${window.localStorage.getItem('token')}`
                },

                body: JSON.stringify({ productfor: "Dark Edition" }),
            }).then((res) => res.json()).then((response) => {


                if (response.status === 200) {
                    var productset = new Set();
                    for (let i = 0; i < response.data.length; i++) {
                        productset.add({
                            ProductCategory: response.data[i].ProductCategory,

                        }
                        )
                    }
                    var productarr = Array.from(productset)

                    // Filter product arr on behave productcategory only unique values but add thumbnail as well

                    productarr = productarr.filter((item, index) => {
                        return productarr.findIndex((item2) => {
                            return item2.ProductCategory === item.ProductCategory
                        }) === index
                    })

                    for (let i = 0; i < productarr.length; i++) {
                        var data = response.data.filter((item) => {
                            return item.ProductCategory === productarr[i].ProductCategory
                        })
                        productarr[i].Productthumbnail = data[0].Productthumbnail
                    }











                    setproducttype(productarr)
                    setproduct(response.data)
                    setprice(response.data[0].ProductPrice)
                    setdiscountedprice(response.data[0].ProductSelling)
                    setfixedprice(response.data[0].ProductPrice)
                    setfixeddiscountedprice(response.data[0].ProductSelling)

                    setProduct_Name(response.data[0].ProductName)
                    setProduct_Description(response.data[0].ProductDescription)
                    setItemName(response.data[0].ItemNo)
                    setInventory(response.data[0].ProductInventory)
                    setfantype(response.data[0].ProductCategory)
                    setShowLoader(false)

                    var set = new Set();

                    for (let i = 0; i < response.data.length; i++) {

                        if (response.data[i].ProductCategory === "Helix") {
                            set.add(response.data[i].ProductSize)
                        }






                    }

                    var arr = Array.from(set)


                    for (let i = 0; i < buttonlist.length; i++) {
                        buttonlist[i].available = false;  // Set all buttons to false initially
                    }


                    for (let i = 0; i < arr.length; i++) {
                        if (arr[i] === "900mm") {
                            buttonlist[0].available = true
                        }
                        if (arr[i] === "1200mm") {
                            buttonlist[1].available = true
                        }
                        if (arr[i] === "1400mm") {
                            buttonlist[2].available = true
                        }
                    }

                    setbuttonlist(buttonlist)

                    var imagearr = []
                    imagearr.push(response.data[0].ProductImage)
                    imagearr.push(response.data[0].ProductImage1)
                    imagearr.push(response.data[0].ProductImage2)
                    imagearr.push(response.data[0].ProductImage3)
                    imagearr.push(response.data[0].ProductImage4)
                    imagearr.push(response.data[0].ProductImage5)
                    for (let i = 0; i < 6; i++) {
                        imagearr.push(imagearr[i])
                    }

                    setimage(imagearr)
                    setimageselected(response.data[0].ProductImage)
                    setbuttonactive(response.data[0].ProductSize)


                } else {
                    setproduct([])
                    setShowLoader(false)


                }
            })
        } catch (error) {
            console.log(error)
            setShowLoader(false)
        }
    }
    useEffect(() => {
        window.scrollTo(0, 0, {
            behavior: 'smooth'
        })
        getproduct()
    }, [])

    useEffect(() => {
        document.title = "Colorbot: Dark Edition BLDC Fans"; // Set the title when component mounts
        return () => {
            document.title = "Colorbot: Dark Edition BLDC Fans"; // Reset the title when component unmounts
        };
    }, []);

    // LOADER CODE
    const [showLoader, setShowLoader] = useState(true)

    const [buttonlist, setbuttonlist] = useState([
        {
            value: "900mm",
            available: false,

        },
        {
            value: "1200mm",
            available: false,

        }, {
            value: "1400mm",
            available: false,

        }
    ])
    const [fantype, setfantype] = useState("Helix")
    const [imageselected, setimageselected] = useState(fantype)
    const [image, setimage] = useState(fantype + "2")
    const [buttonactive, setbuttonactive] = useState("")

    const [quantity, setquantity] = useState(1)

    const [pincode, setPincode] = useState('');
    const [city, setCity] = useState('');

    const [state, setState] = useState('');
    const [error, setError] = useState('');
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        autoplaySpeed: 2000,
        autoplay: true,
        arrow: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            }
        ]
    };
    const [remote, setremote] = useState(true)
    const buyproduct = () => {
        var productprice = Number(quantity * parseInt(price));
        var productdiscountedprice = Number(quantity * parseInt(discountedprice));
        var productname = product_Name;
        var productimage = image[0]
        var productcolor = "Charcoal black"
        var productquantity = quantity;
        var productsweepsize = buttonactive;
        var productid = itemName
        var productremote = remote === true ? "1" : "0";
        var newItem = {
            "productid": productid,
            "productname": productname,
            "productimage": productimage,
            "productprice": productprice,
            "productdiscountedprice": productdiscountedprice,
            "fixedproductprice": Number(fixedprice),
            "fixedproductdiscountedprice": Number(fixeddiscountedprice),
            "productpath": window.location.pathname,
            "productcolor": productcolor,
            "inventory": inventory,
            "modal": `${activefantype}-Dark`,
            "extendedwarranty": true,
            "productquantity": productquantity,
            "productsweepsize": productsweepsize,
            "productremote": productremote
        }
        addToCart(newItem);
        window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
        if (props.LoggedIn) {

            navigate("/product_buy_now_delivery")
        } else {
            navigate("/product_buy_now_login")
        }

    }
    const addtocart = () => {

        var productprice = Number(quantity * parseInt(price));
        var productdiscountedprice = Number(quantity * parseInt(discountedprice));
        var productname = product_Name;
        var productimage = image[0]
        var productcolor = "Charcoal black"
        var productquantity = quantity;
        var productsweepsize = buttonactive;
        var productid = itemName
        var productremote = remote === true ? "1" : "0";
        var newItem = {
            "productid": productid,
            "productname": productname,
            "productimage": productimage,
            "productprice": productprice,
            "productdiscountedprice": productdiscountedprice,
            "fixedproductprice": Number(fixedprice),
            "fixedproductdiscountedprice": Number(fixeddiscountedprice),
            "productpath": window.location.pathname,
            "productcolor": productcolor,
            "inventory": inventory,
            "modal": `${activefantype}-Dark`,
            "extendedwarranty": true,
            "productquantity": productquantity,
            "productsweepsize": productsweepsize,
            "productremote": productremote
        }

        addToCart(newItem);
        window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
        props.opensidepanel()
    }
    const addToCart = (newItem) => {


        props.setcartdata((prevData) => {
            const existingItemIndex = prevData.findIndex(
                (item) => item.productid === newItem.productid
            );

            if (existingItemIndex !== -1) {
                const updatedData = prevData.map((item, index) => {
                    if (index === existingItemIndex) {
                        // Update the quantity of the existing item
                        const updatedQuantity = parseInt(item.productquantity) + parseInt(newItem.productquantity);
                        const updatedPrice = parseInt(item.productprice) + parseInt(newItem.productprice);
                        const updatedDiscountedPrice = parseInt(item.productdiscountedprice) + parseInt(newItem.productdiscountedprice);
                        return { ...item, productquantity: updatedQuantity, productprice: updatedPrice, productdiscountedprice: updatedDiscountedPrice };
                    }
                    return item;
                });

                localStorage.setItem('cartdata', JSON.stringify(updatedData));
                window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
                window.localStorage.setItem("addcart", true)
                return updatedData;
            }

            const updatedData = [...prevData, newItem];
            localStorage.setItem('cartdata', JSON.stringify(updatedData));
            window.localStorage.setItem("cartdate", moment().format("DD-MM-YYYY HH:mm:ss"))
            window.localStorage.setItem("addcart", true)
            return updatedData;
        });
    };
    const checkpincode = () => {
        setCity('');
        setState('');
        var value = pincode;

        if (value.length === 6) {
            setError('');

            axios
                .get(`https://api.postalpincode.in/pincode/${value}`)
                .then((res) => {
                    setState(res.data[0].PostOffice[0].State);
                    setCity(res.data[0].PostOffice[0].Block);


                })
                .then(() => {

                })
                .catch((err) => {

                    setError('Invalid PIN Code');
                });
        }

        if (value.length !== 6) {
            setCity('');

            setState('');
            setError('Pincode must be of 6 digits');
        }

    }

    const updatefantype = (type) => {
        const data = product.filter((item) => {
            return item.ProductCategory === type
        })
        setprice(data[0].ProductPrice)
        setdiscountedprice(data[0].ProductSelling)
        setfixedprice(data[0].ProductPrice)
        setfixeddiscountedprice(data[0].ProductSelling)
        setProduct_Name(data[0].ProductName)
        setProduct_Description(data[0].ProductDescription)
        setItemName(data[0].ItemNo)
        setInventory(data[0].ProductInventory)
        setimageselected(data[0].ProductImage)
        setimage(data[0].ProductImage)
        setfantype(data[0].ProductCategory)
        setquantity(1)
        setremote(true)
        var set = new Set();

        for (let i = 0; i < data.length; i++) {

            if (data[0].ProductCategory === data[0].ProductCategory) {
                set.add(data[i].ProductSize)
            }
        }
        var arr = Array.from(set)


        for (let i = 0; i < buttonlist.length; i++) {
            buttonlist[i].available = false;  // Set all buttons to false initially
        }

        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === "900mm") {
                buttonlist[0].available = true
            }
            if (arr[i] === "1200mm") {
                buttonlist[1].available = true
            }
            if (arr[i] === "1400mm") {
                buttonlist[2].available = true
            }
        }

        setbuttonlist(buttonlist)
        setbuttonactive(data[0].ProductSize)
        setactivefantype(data[0].ProductCategory)





        var imagearr = []
        imagearr.push(data[0].ProductImage)
        imagearr.push(data[0].ProductImage1)
        imagearr.push(data[0].ProductImage2)
        imagearr.push(data[0].ProductImage3)
        imagearr.push(data[0].ProductImage4)
        imagearr.push(data[0].ProductImage5)
        for (let i = 0; i < 6; i++) {
            imagearr.push(imagearr[i])
        }
        setimage(imagearr)
        setimageselected(data[0].ProductImage)

    }
    const [activefantype, setactivefantype] = useState("Helix")
    const textElementRef = useRef(null);

    useEffect(() => {
        setcurrentwidth(window.innerWidth)
        const textElement = textElementRef.current;

        // Stop the animation at 50% for 2 seconds
        const handleAnimationIteration = () => {
            textElement.style.animation = 'none';
            setTimeout(() => {
                textElement.style.animation = 'moveRight 16s linear infinite';
            }, 2000);
        };

        textElement?.addEventListener('animationiteration', handleAnimationIteration);

        return () => {
            textElement?.removeEventListener('animationiteration', handleAnimationIteration);
        };

    }, []);
    const [currentwidth, setcurrentwidth] = useState(window.innerWidth)
    const handleChangeQuantity = (newQuantity) => {
        if (newQuantity === "" || newQuantity === 0) {
            // toast.error("Please enter a valid Quantity"); // Display an error toast notification
            setquantity(newQuantity);

        } else {
            setquantity(newQuantity);


        }
    };

    const updatefansize = (size) => {
        var data = product.filter((item) => {
            return item.ProductSize === size && item.ProductCategory === fantype
        })

        setprice(data[0].ProductPrice)
        setdiscountedprice(data[0].ProductSelling)
        setfixedprice(data[0].ProductPrice)
        setfixeddiscountedprice(data[0].ProductSelling)
        setProduct_Name(data[0].ProductName)
        setProduct_Description(data[0].ProductDescription)
        setItemName(data[0].ItemNo)
        setInventory(data[0].ProductInventory)

        setimageselected(data[0].ProductImage)
        setimage(data[0].ProductImage)
        setfantype(data[0].ProductCategory)

        setquantity(1)
        setremote(true)
        setactivefantype(data[0].ProductCategory)
        setbuttonactive(size)

        var imagearr = []
        imagearr.push(data[0].ProductImage)
        imagearr.push(data[0].ProductImage1)
        imagearr.push(data[0].ProductImage2)
        imagearr.push(data[0].ProductImage3)
        imagearr.push(data[0].ProductImage4)
        imagearr.push(data[0].ProductImage5)
        for (let i = 0; i < 6; i++) {
            imagearr.push(imagearr[i])
        }
        setimage(imagearr)
        setimageselected(data[0].ProductImage)



    }

    const ShareInfo = () => {

        if (navigator.share) {
            navigator.share({
                title: "Color Bot",
                text: "ColorBot Dark Fan",
                url: window.location.href
            }).then(() => {
                console.log('Share successful');
            }).catch((error) => {
                console.error('Error sharing:', error);
            });
        } else {
            // Fallback for browsers that do not support Web Share API
            console.log('Web Share API not supported.');
            // Implement your own custom share functionality here
        }
    };


    var month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const convertdate = (date) => {
        var d = date.split("-")[0];
        var m = date.split("-")[1];

        var time = " " + d + "th" + " " + month[parseInt(m) - 1];

        return time;
    }
    const convertdatewithoutMonth = (date) => {
        var d = date.split("-")[0];
        var m = date.split("-")[1];

        var time = d + "th" + " ";

        return time;
    }







    return (

        showLoader ? <>
            <section className="mt-5 pt-4 loader-align">
                <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/loader-gif.gif"></img>
                <p>LOADING COLORBOT...</p>
            </section>
        </>
            :

            <section className="mt-5 pt-4">
                <DarkHead />
                <div className="animation-container  ">
                    <div className="moving-text shopnowdark" ref={textElementRef}>
                        FREE SHIPPING on all prepaid orders, <span><NavLink to="/bldc-ceiling-fan">SHOP NOW</NavLink></span>
                    </div>
                </div>

                <div className="black">
                    <div className="container pt-4 " >
                        <p className="breadcrumb blacktext"><a style={{ color: "#ffffff" }} href="/" > Home </a> &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <a style={{ color: "#ffffff" }} href="/bldc-ceiling-fan" > BLDC Ceiling Fans </a>   &nbsp;<i className="fa fa-angle-right"></i>&nbsp; <span >Dark</span></p>
                        {product.length > 0 ? <div className="row justify-content-center pt-2">

                            <div className="col-md-12 col-lg-6 pt-2 prod-img-pad">
                                <LazyLoadImage src={imageselected}
                                    width={"100%"}
                                    alt="helixproduct" className="img-fluid darkimage"
                                    effect="blur"
                                />

                                <Slider {...settings} className="helix  helixblack slick-sliderdark">
                                    {image.map((item, index) => {
                                        return (
                                            <div className="helixmultiple" key={index}>
                                                <LazyLoadImage src={item} onClick={() => {

                                                    setimageselected(item)
                                                }} className={`${imageselected}` === item ? "img-fluid heliximageactive" : "img-fluid"} alt="helixproduct"
                                                    effect="blur" width={"100%"}
                                                />

                                            </div>
                                        )
                                    })}

                                </Slider>
                            </div>

                            <div className="col-md-12 col-lg-6 pt-2">

                                <h1 className="helixproductname blacktext producttop">{product_Name}</h1>
                                <p className="helixsubproductname blacktext producttop">{product_Description}</p>
                                <span className="helixprice blacktext producttop">₹ {discountedprice} <span className="helixmainprice"> {price}</span> <span className="helixdiscount">-{(((price - discountedprice) / price) * 100).toFixed(0)}%</span></span>

                                <p className="line"></p>
                                <br />
                                <div className="helixremoteoption producttop">
                                    <span className="remotename blacktext" style={{ marginBottom: "0px" }}>Remote:</span>
                                    {/* <input type="checkbox" className="toggleblack" id="toggleblack" value={remote} onChange={(e) => {
                                        setremote(!remote)

                                    }} />
                                    <label htmlFor="toggleblack">
                                        <span className="on ">Yes</span>
                                        <span className="off ">No</span>
                                    </label> */}

                                    &nbsp;&nbsp;&nbsp;
                                    <button className={"helixbuttonlist helixbuttonactive helixselectblack helixselectactive helix-remote"} onClick={() => {
                                        setremote(true)
                                    }} >White</button>
                                    <button className={"helixbuttonlist helixbuttonactive helixselectblack  helix-remote diagonalCross2 remote-black diagonalCrossdark"} onClick={() => {
                                        setremote(true)
                                    }} >Black</button>
                                </div>
                                <div className="helixbuttonoption producttop">
                                    <span className="remotename blacktext">Sweep Size:</span>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className="buttonlist">
                                        {buttonlist.map((item, index) => {

                                            return !item.available ? (
                                                <button className={item.value === buttonactive ? "helixbuttonlist helixbuttonactive helixselectblack helixselectactive" : "helixbuttonlist helixselectblack diagonalCross2 diagonalCrossdark"} disabled={!item.available} key={index} onClick={() => {
                                                    setbuttonactive(item.value);
                                                    updatefansize(item.value)



                                                }}>{item.value}</button>
                                            ) : (
                                                <button className={item.value === buttonactive ? "helixbuttonlist helixbuttonactive helixselectblack helixselectactive" : "helixbuttonlist helixselectblack "} disabled={!item.available} key={index} onClick={() => {
                                                    setbuttonactive(item.value);
                                                    updatefansize(item.value)



                                                }}>{item.value}</button>
                                            )
                                        })}


                                    </div>

                                </div>
                                <div className="helixbuttonoption producttop product-opt">

                                    {producttype.map((item, index) => {
                                        return (
                                            <div className=" fantype" key={index} onClick={() => {
                                                updatefantype(item.ProductCategory);
                                                setactivefantype(item.ProductCategory);
                                            }}>
                                                <LazyLoadImage src={`${item.Productthumbnail}`} alt="fan" className={activefantype === item.ProductCategory ? "fantypeactive lazysupportimg" : " lazysupportimg"}
                                                    effect="blur" width={"100%"}
                                                />

                                                <p>{item.ProductCategory}</p>

                                            </div>
                                        )
                                    })}


                                </div>
                                <div className="helixquantity producttop">
                                    <span className="remotename blacktext">Quantity:</span>
                                    &nbsp;&nbsp;&nbsp;
                                    {/* <select className="helixselect helixselectblack" value={quantity} onChange={(e) => {

                                        setquantity(e.target.value);
                                        setprice(Number(e.target.value * 3000));
                                        setdiscountedprice(Number(e.target.value * 4000));

                                    }}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>


                                    </select> */}
                                    <input
                                        type="number"
                                        className="helixselect dark-quant helixselectblack"
                                        value={quantity}
                                        onChange={(e) => {
                                            const inputValue = parseInt(e.target.value, 10);

                                            if (inputValue < 0 || inputValue > 99) {
                                                // If the input is invalid, you can choose to handle it here.
                                                // For example, you might want to show an error message or prevent further processing.
                                                return;
                                            }
                                            handleChangeQuantity(e.target.value)


                                        }}
                                    />
                                    <span className="helixshare blacktext" onClick={() => {
                                        ShareInfo()
                                    }}>Share &nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/sharewhite.svg" alt="share" className="img-fluid" /> </span>

                                </div>

                                {inventory > 0 && parseInt(quantity) <= parseInt(inventory) ? <div className="helixbutton ">
                                    <button className={quantity > 0 ? "helixbuynow" : "helixbuynow disabled"} disabled={quantity > 0 ? false : true} onClick={() => {
                                        buyproduct()
                                    }}>Buy Now</button>
                                    <button className={quantity > 0 ? "helixaddtocart blackbutton" : "helixaddtocart blackbutton disabled"} disabled={quantity > 0 ? false : true} onClick={() => {
                                        addtocart()

                                    }}>Add to Cart</button>

                                </div> : <p className="text-danger text-center">Out of stock!</p>}
                                <div className="helixwarranty">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/2yearwarrantywhite.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="2yearwarranty"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/freeshippingwhite.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="freeshippingwhite"
                                        effect="blur"
                                    />
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/easyinstallationwhite.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="easyinstallationwhite"
                                        effect="blur"
                                    />

                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/5starwhite.svg"}
                                        width={"100%"}
                                        className=" img-fluid lazysupportimg" alt="5starwhite"
                                        effect="blur"
                                    />


                                </div>

                                <div className="amazon-flipkart producttop">
                                    <p><b>Also Available on:</b> &nbsp;&nbsp;   <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Amazon.svg" className="img-fluid" alt="amazon" /> &nbsp;<img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Flipkart.svg" className="img-fluid" alt="flipkart" /></p>

                                </div>
                                <div className="pincodechecker">
                                    <div>
                                        <input type="text" className="helixpincode form-control-sm blackinput" maxLength={6} placeholder="Pincode" value={pincode} onChange={(e) => {
                                            setPincode(e.target.value);
                                            setCity('');
                                            setState('');
                                            setError('')
                                        }} />
                                        <button className="helixcheck whitebutton" onClick={() => {
                                            checkpincode();
                                        }}>Check</button>
                                    </div>
                                    <NavLink to="https://colorbots3.s3.ap-south-1.amazonaws.com/Buyers+Guid/Buyers-Guid.pdf" target="blank" >  <span className="buyinguide blacktext">Fan Buying Guide &nbsp; <img src="https://colorbots3.s3.ap-south-1.amazonaws.com/pdf.svg" className="img-fluid" alt="pdf" /></span> </NavLink>
                                </div>
                                <div className="Address">
                                    {state !== "" ? <span className="subaddress" >{city} / {state}, {pincode} <br />Delivery by {convertdatewithoutMonth(moment().add(4, 'days').format("DD-MM-YYYY"))}-{convertdate(moment().add(7, 'days').format("DD-MM-YYYY"))}</span> : ""}
                                    <span className="error">{error}</span>
                                </div>
                                <br />
                            </div>
                        </div> : <p className="text-center text-dark m-4">No Products Available</p>}


                    </div>

                    <div style={{ position: "relative" }}>
                        <LazyLoadImage src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/darkimage1.svg" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-banner-phone.png"}
                            width={"100%"}
                            className=" img-fluid " alt=" an image of Helix Motor"
                            effect="blur"
                        />


                        <div className="banner-txt dark-txt">
                            <FadeInSection>
                                <h2> BREEZE THROUGH THE <span style={{ color: "#F20000" }}>DARK</span></h2>
                                <p>Introducing Colorbot Dark Edition</p>
                            </FadeInSection>


                        </div>


                    </div>

                    <div className="row darkblack">
                        <div className="col-md-7">
                            <LazyLoadImage src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-helix.png" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-helix-mob.png"}
                                width={"100%"}
                                className=" img-fluid " alt="Image of Colorbot Helix"
                                effect="blur"
                            />

                        </div>
                        <div className="col-md-5 aligndark">
                            <FadeInSection>
                                <div className="aligndarkmain">
                                    <h2>Helix <span style={{ color: "#F20000" }}>#Dark</span>  Series </h2>
                                    <p>Because black is elegant, black is efficient.</p>
                                </div>
                            </FadeInSection>
                        </div>
                    </div>

                    <div className="row darkblack knoxxconvert">
                        <div className="col-md-5 aligndarkleft dark-knoxx-padd">
                            <FadeInSection>
                                <div className="aligndarkleft aligndarkmain">
                                    <h2>Knoxx <span style={{ color: "#F20000" }}>#Dark</span>  Series </h2>
                                    <p>Make a classier statement.</p>
                                </div>
                            </FadeInSection>
                        </div>
                        <div className="col-md-7">
                            <LazyLoadImage src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-knoxx.png" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-knoxx-mob.png"}
                                width={"100%"}
                                className=" img-fluid " alt="Image of Colorbot Knoxx"
                                effect="blur"
                            />

                        </div>
                    </div>

                    <div className="row darkblack">
                        <div className="col-md-7">
                            <LazyLoadImage src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-stella.png" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-stella-mob.png"}
                                width={"100%"}
                                className=" img-fluid " alt="Image of Colorbot Stella"
                                effect="blur"
                            />

                        </div>
                        <div className="col-md-5 aligndark">
                            <FadeInSection>
                                <div className="aligndarkmain">
                                    <h2>Stella <span style={{ color: "#F20000" }}>#Dark</span>  Series </h2>
                                    <p>The most dominating Shade.</p>
                                </div>
                            </FadeInSection>
                        </div>
                    </div>

                    <div className="row darkblack knoxxconvert">
                        <div className="col-md-5 aligndarkleft dark-knoxx-padd">
                            <FadeInSection>
                                <div className="aligndarkleft aligndarkmain">
                                    <h2>Knoxx Quad <span style={{ color: "#F20000" }}>#Dark</span>  Series </h2>
                                    <p>The Darker, the Better.</p>
                                </div>
                            </FadeInSection>
                        </div>
                        <div className="col-md-7">
                            <LazyLoadImage src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-knoxx-quad.png" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-knoxx-quad-mob.png"}
                                width={"100%"}
                                className=" img-fluid " alt="Image of Colorbot Knoxx Quad"
                                effect="blur"
                            />

                        </div>
                    </div>

                    <div className="row darkblack">
                        <div className="col-md-7">
                            <LazyLoadImage src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-stella-quad.png" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-stella-quad-mob.png"}
                                width={"100%"}
                                className=" img-fluid " alt="Image of Colorbot Stella Quad"
                                effect="blur"
                            />

                        </div>
                        <div className="col-md-5 aligndark">
                            <FadeInSection>
                                <div className="aligndarkmain">
                                    <h2>Stella Quad <span style={{ color: "#F20000" }}>#Dark</span>  Series </h2>
                                    <p>Style redesigned, Efficiency redefined.</p>
                                </div>
                            </FadeInSection>
                        </div>
                    </div>

                    {/* <div className="row darkblack knoxxconvert">
                        <div className="col-md-5 aligndarkleft dark-knoxx-padd">
                            <FadeInSection>
                                <div className="aligndarkleft aligndarkmain">
                                    <h2>Prizma <span style={{ color: "#F20000" }}>#Dark</span>  Series </h2>
                                    <p>Make a classier statement.</p>
                                </div>
                            </FadeInSection>
                        </div>
                        <div className="col-md-7">
                            <LazyLoadImage src={currentwidth > 758 ? "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-prizma.png" : "https://colorbots3.s3.ap-south-1.amazonaws.com/Dark/Dark-prizma-mob.png"}
                                width={"100%"}
                                className=" img-fluid " alt="Image of Colorot Prizma"
                                effect="blur"
                            />

                        </div>
                    </div> */}



                </div>
            </section>

    )
}
export default Dark;