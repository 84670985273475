import "./Accessories.css";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import FadeInSection from "../FadeInup/FadeInup";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Helmet } from 'react-helmet';


const AccessoriesHead = () => (

    <Helmet>
        <title>Buy Colorbot  Fan Accessories Online | Remote, Blade - Colorbot</title>
        <meta name="description" content="Buy Colorbot BLDC fan accessories remote, blades, and canopy online here without any hassle. Blades and canopy are available in all colors for all models." />
        <link rel="canonical" href="https://colorbot.in/accessories" />

        <meta property="og:title" content="Buy Colorbot  Fan Accessories Online | Remote, Blade - Colorbot" />
        <meta property="og:site_name" content="Colorbot" />
        <meta property="og:url" content="https://colorbot.in/accessories" />
        <meta property="og:description" content="Buy Colorbot BLDC fan accessories remote, blades, and canopy online here without any hassle. Blades and canopy are available in all colors for all models." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="Imagelink" />
    </Helmet>
);

function Accessories() {
    useEffect(() => {
        window.scrollTo(0, 0);
        setvariant([{
            name: "Helix"
        }, {
            name: "Knoxx"
        }, {
            name: "Stella"
        }, {
            name: "Knoxx Quad"
        }, {
            name: "Stella Quad"
        }, {
            name: "Prizma"
        }])
        setSweep([{
            name: "900mm"
        }, {
            name: "1200mm"
        }, {
            name: "1400mm"
        }])
    }, []);

    useEffect(() => {
        document.title = "Accessories - Colorbot"; // Set the title when component mounts
        return () => {
            document.title = "Accessories - Colorbot"; // Reset the title when component unmounts
        };
    }, []);

    const [variant, setvariant] = useState([{
        name: "Pedestal"
    }, {
        name: "Renesa"
    }, {
        name: "Studio+"
    }, {
        name: "Efficio"
    }, {
        name: "Efficio+"
    }, {
        name: "Ozeo"
    }])
    const [sweep, setSweep] = useState([{
        name: "900mm"
    }, {
        name: "1200mm"
    }, {
        name: "1400mm"
    }])
    const [selectvariant, setselectvariant] = useState("Pedestal")
    const [selectsweep, setselectsweep] = useState("900mm")
    const [showMore, setShowMore] = useState(false)
    const [showMore1, setShowMore1] = useState(false)
    return (
        <section className="mt-5 pt-4">

            <AccessoriesHead />

            <div id="carouselExampleIndicators " className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">

                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <h1 className="banner-text">Accessories</h1>
                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/banners/Accessories-banner.jpg"}
                            width={"100vw"}
                            // width={"190vw"}
                            // Width={window.innerWidth < 780 ?"50vw":"10vw"}
                            // {window.innerWidth < 780 ?   width={"100vw"}: ""} 
                            className="d-block w-100 img-fluid acess-banner" alt="..."
                            effect="blur"
                        />

                    </div>

                </div>

            </div>
            <div className="container-fluid p-4">

                <div className="row justify-content-center">





                    <div className="col-md-6 accesswidth">
                        <div className="bg-w">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="pro-img">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Accessories/Accessories-remote.png"}

                                            className=" img-fluid lazyimg helix100 accessimg" alt="..."
                                            effect="blur"
                                        /></div>
                                </div>
                                <div className="col-lg-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                                    <div className="box-txt" style={{ paddingTop: "8px" }}>

                                        <h3>Remote Control</h3>

                                        <p className="mb-2">Remote Variant:</p>
                                        <div className="text-center">
                                            {variant.map((item, index) => {
                                                return (
                                                    <button className={item.name === selectvariant ? "sweep-btn sweep-btnactive" : "sweep-btn"} key={index} onClick={() => setselectvariant(item.name)}>
                                                        {item.name}
                                                    </button>
                                                )
                                            })
                                            }

                                        </div>

                                        <p className="mt-3 mb-2">Remote Type:</p>
                                        <div className="text-center">
                                            <button className="sweep-btn">
                                                Type B
                                            </button>
                                        </div>


                                        <div className="price-btn">
                                            <div className="price">
                                                <p><i className="fa fa-inr" aria-hidden="true"></i>300 <span>-25%</span></p>
                                                <p className="dis"><del><i className="fa fa-inr" aria-hidden="true"></i>400</del> </p>
                                            </div>
                                            <div className="buy-btn out-stock-btn"><button className="btn">Out of Stock</button></div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 accesswidth">
                        <div className="bg-w">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="pro-img">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Accessories/Accessories-canopy.png"}

                                            className=" img-fluid lazyimg helix100 accessimg" alt="..."
                                            effect="blur"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                                    <div className="box-txt" style={{ paddingTop: "8px" }}>

                                        <h3>Fan Canopy</h3>

                                        <div className="fan-clr">
                                            <div className="clr1" data-hover="lightgray"></div>
                                            <div className="clr1 clr2" data-hover="black"> </div>
                                            <div className="clr1 clr3 navactive" data-hover="gray"></div>
                                            <div className="clr1 clr4" data-hover="olive"></div>
                                            <div className="clr1 clr5" data-hover="brown"></div>

                                            <div className="clr1 clr6" data-hover="gray"></div>
                                            <div className="clr1 clr7" data-hover="olive"></div>
                                            <div className="clr1 clr8" data-hover="Lightbrown"></div>

                                        </div>
                                        <br />
                                        <p></p>
                                        <p className="mb-2 mt-2">Fan Variant:</p>
                                        <select className="form-select " aria-label="Default select example">
                                            <option >Select Fan Variant</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Helix</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Knoxx</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Stella</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Knoxx Quad</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Stella Quad</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Prizma</option>

                                        </select>
                                        <br />




                                        <div className="price-btn">
                                            <div className="price">
                                                <p><i className="fa fa-inr" aria-hidden="true"></i>200 <span>-50%</span></p>
                                                <p className="dis"><del><i className="fa fa-inr" aria-hidden="true"></i>400</del> </p>
                                            </div>
                                            <div className="buy-btn out-stock-btn"><button className="btn">Out of Stock</button></div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 accesswidth">
                        <div className="bg-w">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="pro-img">
                                        <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Accessories/Accessories-blade.png"}

                                            className=" img-fluid blade-img lazyimg helix100 accessimg" alt="..."
                                            effect="blur"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                                    <div className="box-txt" style={{ paddingTop: "8px" }}>

                                        <h3>Fan Blades</h3>
                                        <div className="fan-clr">
                                            <div className="clr1" data-hover="lightgray"></div>
                                            <div className="clr1 clr2" data-hover="black"> </div>
                                            <div className="clr1 clr3 navactive" data-hover="gray"></div>
                                            <div className="clr1 clr4" data-hover="olive"></div>
                                            <div className="clr1 clr5" data-hover="brown"></div>

                                            <div className="clr1 clr6" data-hover="gray"></div>
                                            <div className="clr1 clr7" data-hover="olive"></div>
                                            <div className="clr1 clr8" data-hover="Lightbrown"></div>

                                        </div>


                                        <p className="mt-2 mb-2">Sweep Size:</p>
                                        <div className="text-center">
                                            {sweep.map((item, index) => {
                                                return (
                                                    <button className={item.name === selectsweep ? "sweep-btn sweep-btnactive" : "sweep-btn"} key={index} onClick={() => setselectsweep(item.name)}>
                                                        {item.name}
                                                    </button>
                                                )
                                            })
                                            }

                                        </div>

                                        <p className="mt-2 mb-2">Fan Variant:</p>
                                        <select className="form-select" aria-label="Default select example">
                                            <option >Select Fan Variant</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Helix</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Knoxx</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Stella</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Knoxx Quad</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Stella Quad</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Prizma</option>

                                        </select>

                                        <div className="price-btn mt-1">
                                            <div className="price">
                                                <p><i className="fa fa-inr" aria-hidden="true"></i>400 <span>-50%</span></p>
                                                <p className="dis"><del><i className="fa fa-inr" aria-hidden="true"></i>800</del> </p>
                                            </div>
                                            <div className="buy-btn out-stock-btn"><button className="btn">Out of Stock</button></div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 accesswidth">
                    </div>

                    {/* <div className="col-md-6 accesswidth">
                        <div className="bg-w">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="pro-img">
                                    <LazyLoadImage src={"https://colorbots3.s3.ap-south-1.amazonaws.com/Accessories/Fan_Downrod.svg"}
                                    
                                    className=" img-fluid lazyimg helix100" alt="..."
                                    effect="blur"
                                />
                                        </div>
                                </div>
                                <div className="col-lg-6" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                                    <div className="box-txt" style={{ paddingTop: "8px" }}>

                                        <h3>Fan Downrod</h3>

                                        <div className="fan-clr">
                                            <div className="clr1" data-hover="lightgray"></div>
                                            <div className="clr1 clr2" data-hover="black"> </div>
                                            <div className="clr1 clr3 navactive" data-hover="gray"></div>
                                            <div className="clr1 clr4" data-hover="olive"></div>
                                            <div className="clr1 clr5" data-hover="brown"></div>

                                            <div className="clr1 clr6" data-hover="gray"></div>
                                            <div className="clr1 clr7" data-hover="olive"></div>
                                            <div className="clr1 clr8" data-hover="Lightbrown"></div>

                                        </div>
                                        <br />
                                        <p></p>
                                        <p className="mt-2 mb-2">Fan Variant:</p>
                                        <select className="form-select" aria-label="Default select example">
                                            <option >Select Fan Variant</option>
                                            <option value="Colorbot Smart+ Ceiling Fan" >Colorbot Smart+ Ceiling Fan</option>

                                        </select>

                                        <br />



                                        <div className="price-btn mt-2">
                                            <div className="price">
                                                <p><i className="fa fa-inr" aria-hidden="true"></i>200 <span>-50%</span></p>
                                                <p className="dis"><del><i className="fa fa-inr" aria-hidden="true"></i>400</del> </p>
                                            </div>
                                            <div className="buy-btn"><button className="btn">Buy Now</button></div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> */}
                </div>

            </div>
            <div className="pt-4 pb-4" style={{ backgroundColor: "#F5F7FA" }}>
                <FadeInSection>
                    <div className="container">
                        <div className="row mt-5 mb-5 ">
                            <div className="col-md-6">
                                <p >
                                    Shop Colorbot BLDC ceiling fan accessories online such as fan remote, fan canopy, and fan blades. If by any chance any of the accessories of your fan gets damaged or broken, you can purchase them conveniently from Colorbot's website.

                                    All the accessories are engineered with high-quality materials that are less prone to damage. The remote and canopy are made of high-grade plastic and the blades are made of ABS aluminium with a matte finish powder coated and dust-resistant quality.

                                    The remote is available in white and black color, compatible with all Colorbot fans. Colorbot BLDC Ceiling fan model can be operated by only Colorbot fan remote, even multiple fans can also be paired with a single remote.

                                    Blades and canopies are available for the respective fan model, you may choose blades according to your fan model and sweep sizes.

                                    Make your shopping hassle-free and buy Colorbot BLDC fan accessories at the best price.

                                </p>

                                {showMore ? <p>Blades and canopies are available for the respective fan model, you may choose blades according to your fan model and sweep sizes.

                                    Make your shopping hassle-free and buy Colorbot BLDC fan accessories at the best price.</p> : ""}

                                {/* {showMore?<p>  Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem </p>:""} */}
                                <p className="text-center mt-4 mb-4"><button className="btn view" onClick={() => {
                                    setShowMore(!showMore)
                                }}>{showMore ? "View Less" : "View More"}</button></p>
                            </div>
                            <div className="col-md-6">
                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                Is Knoxx BLDC fan a good choice for an office space?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">Absolutely yes. Knoxx BLDC fans are not only designed elegantly but also engineered for silent operation even when they run on the maximum speed or on inverters. The silent spins help for enhanced concentration during work and official meetings. Also, they provide better air circulation throughout the office space, making your work environment comfortable.</div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                Can I use BLDC fans in an outdoor area?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">Colorbot fans are recommended for indoor use. However, you can use the fan in an outdoor area such as lobby or balcony as they are very versatile and are suitable for a variety of living spaces. However, ensure that the place has proper power supply and is not exposed to extreme weather conditions.</div>
                                        </div>
                                    </div>
                                    {showMore1 ? <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                What is the average lifespan of a Colorbot BLDC fan?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">The average lifespan of BLDC fans is comparatively much more than traditional fans. Colorbot BLDC fans are known for their durability with high-quality copper winding that makes a fan stable and extends its lifespan.</div>
                                        </div>
                                    </div> : ""}
                                    {showMore1 ? <div className="accordion-item">
                                        <h2 className="accordion-header" id="flush-headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                                What’s Reverse mode in my Colorbot fan remote?
                                            </button>
                                        </h2>
                                        <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                            <div className="accordion-body">The Reverse mode in Colorbot fan states the bi-directional oscillation means colorbot fan has a switch to rotate a fan in clockwise direction, providing warm air which is beneficial in winters.</div>
                                        </div>
                                    </div> : ""}
                                </div>
                                <p className="text-center mt-4"><button className="btn view" onClick={() => {
                                    setShowMore1(!showMore1)
                                }}>{showMore1 ? "View Less" : "View More"}</button></p>
                                <br />
                            </div>
                        </div>
                    </div>
                </FadeInSection>
            </div>
        </section>

    );
}
export default Accessories;