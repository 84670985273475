import { NavLink } from "react-router-dom"
function Footer(props) {
    return (
        <section className="bg-blk">
            <div className="container">
                {window.location.pathname === "/product_buy_now_login" || window.location.pathname === "/product_buy_now_delivery" || window.location.pathname === "/product_buy_now_order_summary" || window.location.pathname === "/product_buy_now_payment" ?
                    <div className="row bot-bordr" style={{
                        padding: "20px"
                    }}>

                        <div className="col-lg-4 col-md-4 footeralignend">


                            <NavLink to="/return-policy" className={"footernavlinktext"}>Return Policy</NavLink>
                        </div>
                        <div className="col-lg-4 col-md-4 footeraligncenter">

                            <NavLink to="/services-policies" className={"footernavlinktext"}>Services and Policies</NavLink>
                        </div>
                        <div className="col-lg-4 col-md-4 footeralignstart">


                            <NavLink to="/privacy-policy" className={"footernavlinktext"}>Privacy Policy</NavLink>
                        </div>


                    </div> :
                    <div className="row bot-bordr">

                        <div className="col-lg-4 col-md-12">
                            <div className="logo-sec">
                                <div className="fot-logo"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/Home-Page/Colorbot-logo-tm-wh.webp" alt="colorbot-logo" width="230" height="30" loading="lazy" /></div>
                                <p>Colorbot integrates energy-efficient technologies, innovative designing, and eco-friendly processes to develop products that enhance daily lives as well as contribute to a greener and more sustainable future.</p>
                                <br />
                                <div className="socio">
                                    <NavLink to="https://www.instagram.com/colorbot_official/" target="_blank"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/insta.svg" alt="instagram" height="35" width="35" /></NavLink>
                                    <NavLink to="https://www.facebook.com/colorbot.in" target="_blank"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/fb.svg" alt="facebook" height="35" width="35" /></NavLink>
                                    <NavLink to="https://twitter.com/Colorbot_in" target="_blank"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/twi.svg" alt="twitter" height="35" width="35" /></NavLink>
                                    <NavLink to="https://www.linkedin.com/company/colorbot-pvt-ltd/mycompany/" target="_blank"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/linkedln.svg" alt="linkedin" height="35" width="35" /></NavLink>
                                    <NavLink to="https://www.youtube.com/@colorbot" target="_blank"><img src="https://colorbots3.s3.ap-south-1.amazonaws.com/yout.svg" alt="youtube" height="35" width="35" /></NavLink>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 col-6">

                            <div className="bot-nav-sec">

                                <h4>Navigation</h4>

                                <ul className="bot-nav">
                                    <li>
                                        <NavLink to="/">Home</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/bldc-ceiling-fan">Products</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/dark">Dark</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/accessories">Accessories</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/technology">Technology</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/about">About Us</NavLink>
                                    </li>

                                    <li>
                                        <NavLink to="/blog">Blog</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/sustainability">Sustainability</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/sitemap">Sitemap</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/terms-conditions">Terms & Conditions</NavLink>
                                    </li>
                                </ul>

                            </div>

                        </div>

                        <div className="col-lg-2 col-md-6 col-6">

                            <div className="bot-nav-sec">

                                <h4>Products</h4>

                                <ul className="bot-nav">
                                    <li>
                                        <NavLink to="/colorbot-helix">Colorbot Helix</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/colorbot-knoxx">Colorbot Knoxx</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/colorbot-stella">Colorbot Stella</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/colorbot-knoxx-quad">Colorbot Knoxx Quad</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/colorbot-stella-quad">Colorbot Stella Quad</NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink to="/colorbot-prizma">Colorbot Prizma</NavLink>
                                    </li> */}

                                </ul>

                            </div>

                        </div>

                        <div className="col-lg-2 col-md-6 col-6">

                            <div className="bot-nav-sec">

                                <h4>Support</h4>

                                <ul className="bot-nav">
                                    <li>
                                        <NavLink to="/contact">Contact Us</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/warranty-registration">Register Warranty</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/track-order">Track your order</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/return-policy">Return Policy</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/services-policies">Services and Policies</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/faq">FAQs</NavLink>
                                    </li>

                                    <li>
                                        <NavLink to="/services-policies">Become a Distributor</NavLink>
                                    </li>

                                </ul>

                            </div>

                        </div>

                        <div className="col-lg-2 col-md-6 col-6">

                            <div className="bot-nav-sec">

                                <h4>Contact Us</h4>

                                <ul className="bot-nav cont-us">
                                    <li>
                                        <NavLink to="tel:+91 95 9994 0972">+91 92 8930 5540</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="mailto:enquiry@colorbot.in" target="_blank"> enquiry@colorbot.in</NavLink>
                                    </li>

                                    <li className="cont-sec">For Customer support:</li>
                                    <li>
                                        <NavLink to="tel:+91 95 9994 0985"> +91 95 9994 0985</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="mailto:support@colorbot.in"> support@colorbot.in</NavLink>
                                    </li>

                                </ul>

                            </div>

                        </div>

                    </div>}

                <div className="row">
                    <div className="col-lg-12">
                        <div className="copyr-text">
                            Copyright © 2024 ColorBot. All rights reserved.
                        </div>
                    </div>
                </div>



            </div>
        </section >
    );

}
export default Footer;